import { fromJS } from "immutable";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";
import { notLoadedMap } from "app/utils/constants";
import {
  keyArrToClearHandler,
  keyArrToLoadDetailedHandler,
  keyArrToLoadSimpleHandler,
} from "app/utils/redux/reducerHandlers";

const initialState = fromJS({
  breeds: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
  items: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
  requirements: {
    detailed: notLoadedMap,
  },
});

const reducer = (state = initialState, action) => {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_SIMPLE_STOCK_ITEMS]: keyArrToLoadSimpleHandler(["items", "all"], "stockItem"),
    [actionTypes.CLEAR_SIMPLE_STOCK_ITEMS]: keyArrToClearHandler(["items", "all"]),
    [actionTypes.LOAD_DETAILED_STOCK_ITEM]: keyArrToLoadDetailedHandler(["items", "detailed"]),
    [actionTypes.CLEAR_DETAILED_STOCK_ITEM]: keyArrToClearHandler(["items", "detailed"]),

    [actionTypes.LOAD_DETAILED_STOCK_REQUIREMENT]: keyArrToLoadDetailedHandler(["requirements", "detailed"]),
    [actionTypes.CLEAR_DETAILED_STOCK_REQUIREMENT]: keyArrToClearHandler(["requirements", "detailed"]),

    [actionTypes.LOAD_SIMPLE_STOCK_BREEDS]: keyArrToLoadSimpleHandler(["breeds", "all"], "stockBreed"),
    [actionTypes.CLEAR_SIMPLE_STOCK_BREEDS]: keyArrToClearHandler(["breeds", "all"]),
    [actionTypes.LOAD_DETAILED_STOCK_BREED]: keyArrToLoadDetailedHandler(["breeds", "detailed"]),
    [actionTypes.CLEAR_DETAILED_STOCK_BREED]: keyArrToClearHandler(["breeds", "detailed"]),
    //
    // [actionTypes.LOAD_DETAILED_RESOURCE_STATE_RESET]: keyArrToLoadDetailedHandler(['resets', 'detailed']),
    // [actionTypes.CLEAR_DETAILED_RESOURCE_STATE_RESET]: keyArrToClearHandler(['resets', 'detailed']),
    //
    // [actionTypes.LOAD_DETAILED_RESOURCE_STATE_TRANSITION]: keyArrToLoadDetailedHandler(['transitions', 'detailed']),
    // [actionTypes.CLEAR_DETAILED_RESOURCE_STATE_TRANSITION]: keyArrToClearHandler(['transitions', 'detailed']),
  });
};

export default reducer;
