import { fromJS } from "immutable";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { arrayToPkMap } from "app/utils/immutableUtils";
import { actionTypes } from "../actions";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";

const initialState = fromJS({
  all: notLoadedMap,
  detailed: notLoadedMap,
});

function handleLoadWorkImportances(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("all", isLoadingMap);
  }
  if (action.status === "done") {
    const importancesJS = action.payload.body.results;
    const importancesMap = arrayToPkMap(importancesJS, "workImportance");
    state = state.set("all", importancesMap);
  }

  return state;
}

function handleLoadDetailedWorkImportance(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    const importanceJS = action.payload.body;
    state = state.set("detailed", fromJS(importanceJS));
  }
  return state;
}

function handleClearWorkImportances(prevState, action) {
  return prevState.set("all", notLoadedMap);
}

function workImportancesReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_WORK_IMPORTANCES]: handleLoadWorkImportances,
    [actionTypes.LOAD_DETAILED_WORK_IMPORTANCE]: handleLoadDetailedWorkImportance,
    [actionTypes.CLEAR_WORK_IMPORTANCES]: handleClearWorkImportances,
  });
}

export default workImportancesReducer;
