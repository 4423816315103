import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppActions } from "app/actions";
import useToggleState from "app/common/hooks/useToggleState";
import useLoadingState from "app/common/hooks/useLoadingState";
import useQueryParams from "app/common/hooks/useQueryParams";
import BusyFeedbackPage from "app/common/components/BusyFeedbackPage";
import history from "app/history";

import Login from "app/features/auth/components/Login";
import { useFirebaseAuthContext } from "app/features/auth/components/FirebaseAuthProvider";
import { getUserIsLoaded } from "app/features/users/selectors";

export const useRedirectIfLoggedIn = ({ tenantSchemaName = null, canRedirect = true }) => {
  const isLoggedIn = useSelector(getUserIsLoaded);

  const loginPath = tenantSchemaName ? `${tenantSchemaName}/login` : "login";
  const { redirectUrl: redirectUrlParam } = useQueryParams();
  const redirectUrl = redirectUrlParam && redirectUrlParam !== loginPath ? redirectUrlParam : "/";

  const shouldRedirect = canRedirect && isLoggedIn;

  useEffect(() => {
    if (shouldRedirect) {
      // NOTE replace(!) makes sure that onBack the user never navigates to the login page again! :-)
      history.replace(redirectUrl);
    }
  }, [shouldRedirect, redirectUrl]);

  return {
    isLoggedIn,
    redirectUrl,
  };
};

const useHandleLogin = () => {
  const actions = useAppActions();
  const { signOutFirebaseUser } = useFirebaseAuthContext();

  const { isLoading: isLoggingIn, loadUntilResolvedOrRejected: setLoggingInUntilResolvedOrRejected } =
    useLoadingState();

  const handleLogin = useCallback(
    (username, password) => {
      return setLoggingInUntilResolvedOrRejected(
        actions.users
          .logIn(username, password)
          .payload.then(() => {
            return signOutFirebaseUser();
          })
          .then(() => {
            return actions.users.getUser().payload;
          }),
      );
    },
    [actions, setLoggingInUntilResolvedOrRejected, signOutFirebaseUser],
  );

  return {
    handleLogin,
    isLoggingIn,
  };
};

const useInitialClearGetUser = () => {
  const actions = useAppActions();

  // If we detected a firebaseUser or session, we try getUser, else just show a Login-screen right away.
  // TODO commented out because buggy
  // const initialIsWaitingForUser = new InternalDal().hasFirebaseUserOrSession;

  const { value: isWaitingForUser, off: stopWaitingForUser } = useToggleState(true);

  useEffect(() => {
    // if (isWaitingForUser) { TODO buggy when switching
    actions.users.clearUser();
    actions.users.getUser().payload.finally(stopWaitingForUser);
    // }
  }, [
    actions,
    // isWaitingForUser,
    stopWaitingForUser,
  ]);

  return isWaitingForUser;
};

const LogInContainer = () => {
  const isLoggedIn = useSelector(getUserIsLoaded);

  const { handleLogin, isLoggingIn } = useHandleLogin();

  const isWaitingForUser = useInitialClearGetUser();

  // TODO
  //  We might want to display a link if we navigated to /login?redirectUrl=/tenantSchema/... if that tenant has
  //  a tenant-specific login-page
  const { redirectUrl } = useRedirectIfLoggedIn({ canRedirect: !isWaitingForUser });

  // isLoggedIn triggers a replace of the url, show loading until this has happened.
  const showBusyFeedback = isWaitingForUser || isLoggedIn;
  if (showBusyFeedback) return <BusyFeedbackPage />;

  return <Login isLoading={isLoggingIn} onSubmit={handleLogin} />;
};

export default LogInContainer;
