import { useAppActions } from "app/actions";
import { useMountEffect } from "app/utils/hooks";
import BusyFeedbackPage from "app/common/components/BusyFeedbackPage";
import { useAppTenantContext } from "app/core/providers/AppTenantDetectionProvider";
import { useFirebaseAuthContext } from "app/features/auth/components/FirebaseAuthProvider";
import { useNavToUrl } from "app/features/tenants/hooks";

const Logout = () => {
  const actions = useAppActions();
  const { clearDetectedTenant } = useAppTenantContext();
  const { signOutFirebaseUser } = useFirebaseAuthContext();
  const navToIndex = useNavToUrl("/");

  // TODO
  //  - Instead of immediately navigating to index we could present a successfully logged out page, that disappears
  //  after a while with:
  //   * link to index
  //   * a message that if a user had a firebase user before navigating to links in youplan might automatically sign
  //   them in again because they will not be signed out from the SSO-provider
  //  - If logging out fails, show a message that it failed and we will try again in a few seconds.

  useMountEffect(() => {
    // TODO
    //  The below was a work in progress, but not working well enough.
    //  - somehow the tenant is re-detected if we are logging out.
    //  - this functionality of logging out should live in AuthProvider
    //       clearDetectedTenant();
    //       if (isLoggingOut) return;
    //
    //       const dal = new InternalDal();
    //       const logout = async () => {
    //         // TODO what if these fail?
    //         if (dal.hasFirebaseUser) {
    //           await signOutFirebaseUser();
    //           await actions.users.clearUser().payload;
    //         }
    //         if (dal.hasSession) {
    //           await actions.users.logOut().payload;
    //         }
    //       };
    //
    //       logout()
    //         .then(navToIndex);

    signOutFirebaseUser().then(() => {
      clearDetectedTenant();
      // TODO what if this fails?
      actions.users.logOut().payload.then(navToIndex);
    });
  });

  return <BusyFeedbackPage />;
};

export default Logout;
