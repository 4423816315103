import { getEmptyPromise } from "app/utils/dal";
import StoragesDal from "app/features/storages/dal";

export const actionTypes = {
  LOAD_SIMPLE_STORAGES: "storages/LOAD_SIMPLE_STORAGES",
  CLEAR_SIMPLE_STORAGES: "storages/CLEAR_SIMPLE_STORAGES",
  LOAD_DETAILED_STORAGE: "storages/LOAD_DETAILED_STORAGE",
  CLEAR_DETAILED_STORAGE: "storages/CLEAR_DETAILED_STORAGE",
  CREATE_STORAGE: "storages/CREATE_STORAGE",
  UPDATE_STORAGE: "storages/UPDATE_STORAGE",
  DELETE_STORAGE: "storages/DELETE_STORAGE",

  LOAD_SIMPLE_STORAGE_BREEDS: "storages/LOAD_SIMPLE_STOCK_BREEDS",
  CLEAR_SIMPLE_STORAGE_BREEDS: "storages/CLEAR_SIMPLE_STOCK_BREEDS",
  LOAD_DETAILED_STORAGE_BREED: "storages/LOAD_DETAILED_STOCK_BREED",
  CLEAR_DETAILED_STORAGE_BREED: "storages/CLEAR_DETAILED_STOCK_BREED",
  CREATE_STORAGE_BREED: "storages/CREATE_STORAGE_BREED",
  UPDATE_STORAGE_BREED: "storages/UPDATE_STORAGE_BREED",
  DELETE_STORAGE_BREED: "storages/DELETE_STORAGE_BREED",

  CREATE_STORED_ITEM_INBOUND: "storages/CREATE_STORED_ITEM_INBOUND",
  DELETE_STORED_ITEM_INBOUND: "storages/DELETE_STORED_ITEM_INBOUND",
};

const actionCreators = {
  /* Storages */
  loadSimpleStorages() {
    const dal = new StoragesDal();
    const actionPromise = dal.getSimpleStorages();
    return {
      type: actionTypes.LOAD_SIMPLE_STORAGES,
      payload: actionPromise,
    };
  },

  clearSimpleStorages() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_SIMPLE_STORAGES,
      payload: actionPromise,
    };
  },

  loadDetailedStorage(storagePk) {
    const dal = new StoragesDal();
    const actionPromise = dal.getDetailedStorage(storagePk);
    return {
      type: actionTypes.LOAD_DETAILED_STORAGE,
      payload: actionPromise,
    };
  },

  clearDetailedStorage() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_STORAGE,
      payload: actionPromise,
    };
  },

  createStorage(storage) {
    const dal = new StoragesDal();
    const actionPromise = dal.createStorage(storage);
    return {
      type: actionTypes.CREATE_STORAGE,
      payload: actionPromise,
    };
  },

  updateStorage(storage) {
    const dal = new StoragesDal();
    const actionPromise = dal.putStorage(storage);
    return {
      type: actionTypes.UPDATE_STORAGE,
      payload: actionPromise,
    };
  },

  deleteStorage(storagePk) {
    const dal = new StoragesDal();
    const actionPromise = dal.deleteStorage(storagePk);
    return {
      type: actionTypes.DELETE_STORAGE,
      payload: actionPromise,
    };
  },

  /* StorageBreeds */
  loadSimpleStorageBreeds() {
    const dal = new StoragesDal();
    const actionPromise = dal.getSimpleStorageBreeds();
    return {
      type: actionTypes.LOAD_SIMPLE_STORAGE_BREEDS,
      payload: actionPromise,
    };
  },

  clearSimpleStorageBreeds() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_SIMPLE_STORAGE_BREEDS,
      payload: actionPromise,
    };
  },

  loadDetailedStorageBreed(storageBreedPk) {
    const dal = new StoragesDal();
    const actionPromise = dal.getDetailedStorageBreed(storageBreedPk);
    return {
      type: actionTypes.LOAD_DETAILED_STORAGE_BREED,
      payload: actionPromise,
    };
  },

  clearDetailedStorageBreed() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_STORAGE_BREED,
      payload: actionPromise,
    };
  },

  createStorageBreed(storageBreed) {
    const dal = new StoragesDal();
    const actionPromise = dal.createStorageBreed(storageBreed);
    return {
      type: actionTypes.CREATE_STORAGE_BREED,
      payload: actionPromise,
    };
  },

  updateStorageBreed(storageBreed) {
    const dal = new StoragesDal();
    const actionPromise = dal.putStorageBreed(storageBreed);
    return {
      type: actionTypes.UPDATE_STORAGE_BREED,
      payload: actionPromise,
    };
  },

  deleteStorageBreed(storageBreedPk) {
    const dal = new StoragesDal();
    const actionPromise = dal.deleteStorageBreed(storageBreedPk);
    return {
      type: actionTypes.DELETE_STORAGE_BREED,
      payload: actionPromise,
    };
  },

  /* StoredItems */
  createStoredItemInbound(storedItemInbound) {
    const dal = new StoragesDal();
    const actionPromise = dal.createStoredItemInbound(storedItemInbound);
    return {
      type: actionTypes.CREATE_STORED_ITEM_INBOUND,
      payload: actionPromise,
    };
  },

  deleteStoredItemInbound(storedItemInboundPk) {
    const dal = new StoragesDal();
    const actionPromise = dal.deleteStoredItemInbound(storedItemInboundPk);
    return {
      type: actionTypes.DELETE_STORED_ITEM_INBOUND,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
