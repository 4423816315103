import InternalDal from "app/utils/InternalDal";

class StockDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.breedsUrl = `${this.dal.baseTenantUrl}/stock-breeds`;
    this.itemsUrl = `${this.dal.baseTenantUrl}/stock-items`;
    this.levelsUrl = `${this.dal.baseTenantUrl}/stock-item-levels`;
    this.reqsUrl = `${this.dal.baseTenantUrl}/stock-requirements`;
  }

  /* Items */
  getSimpleStockItems() {
    const url = `${this.itemsUrl}/`;
    return this.dal.getter(url, "getSimpleStockItems");
  }

  getDetailedStockItem(stockItemPk) {
    const url = `${this.itemsUrl}/detailed/${stockItemPk}/`;
    return this.dal.getter(url, "getDetailedStockItem");
  }

  createStockItem(stockItem) {
    const url = `${this.itemsUrl}/detailed/`;
    return this.dal.poster(url, stockItem, "createStockItem");
  }

  putStockItem(stockItem) {
    const url = `${this.itemsUrl}/${stockItem.pk}/`;
    return this.dal.putter(url, stockItem, "putStockItem");
  }

  patchStockItemResources(stockItemPk, resourcePks) {
    const url = `${this.itemsUrl}/${stockItemPk}/`;
    const data = { resources: resourcePks };
    return this.dal.patcher(url, data, "patchStockItemResources");
  }

  deleteStockItem(stockItemPk) {
    const url = `${this.itemsUrl}/${stockItemPk}/`;
    return this.dal.deleter(url, "deleteStockItem");
  }

  /* Levels */
  getStockItemLevelsAtMoment(m) {
    const url = `${this.dal.baseTenantUrl}/stock/stock-item-levels-at`;
    const data = { dt: m };
    return this.dal.poster(url, data, "getStockItemLevelsAtMoment");
  }

  getStockBreedLevelsAtMoment(m) {
    const url = `${this.dal.baseTenantUrl}/stock/stock-breed-levels-at`;
    const data = { dt: m };
    return this.dal.poster(url, data, "getStockBreedLevelsAtMoment");
  }

  createStockItemLevel(level) {
    const url = `${this.levelsUrl}/`;
    return this.dal.poster(url, level, "createStockItemLevel");
  }

  putStockItemLevel(level) {
    const url = `${this.levelsUrl}/${level.pk}/`;
    return this.dal.putter(url, level, "putStockItemLevel");
  }

  deleteStockItemLevel(levelPk) {
    const url = `${this.levelsUrl}/${levelPk}/`;
    return this.dal.deleter(url, "deleteStockItemLevel");
  }

  /* Requirements */
  getDetailedStockRequirement(reqPk) {
    const url = `${this.reqsUrl}/${reqPk}/`;
    return this.dal.getter(url, "getDetailedStockRequirement");
  }

  createStockRequirement(requirement) {
    const url = `${this.reqsUrl}/`;
    return this.dal.poster(url, requirement, "createStockRequirement");
  }

  putStockRequirement(requirement) {
    const url = `${this.reqsUrl}/${requirement.pk}/`;
    return this.dal.putter(url, requirement, "putStockRequirement");
  }

  deleteStockRequirement(reqPk) {
    const url = `${this.reqsUrl}/${reqPk}/`;
    return this.dal.deleter(url, "deleteStockRequirement");
  }

  /* Breeds */
  getSimpleStockBreeds() {
    const url = `${this.breedsUrl}/`;
    return this.dal.getter(url, "getSimpleStockBreeds");
  }

  getDetailedStockBreed(stockBreedPk) {
    const url = `${this.breedsUrl}/${stockBreedPk}/`;
    return this.dal.getter(url, "getDetailedStockBreed");
  }

  createStockBreed(stockBreed) {
    const url = `${this.breedsUrl}/`;
    return this.dal.poster(url, stockBreed, "createStockBreed");
  }

  putStockBreed(stockBreed) {
    const url = `${this.breedsUrl}/${stockBreed.pk}/`;
    return this.dal.putter(url, stockBreed, "putStockBreed");
  }

  deleteStockBreed(stockBreedPk) {
    const url = `${this.breedsUrl}/${stockBreedPk}/`;
    return this.dal.deleter(url, "deleteStockBreed");
  }
}

export default StockDal;
