import { newCalculationUrl } from "app/features/calculations/routing";

export const manageSnapshotsUrl = "snapshots";

export const manageSnapshotScopesUrl = "snapshots/scopes";

export const newSnapshotScopeUrl = "snapshots/scopes/new";
export const importSnapshotUrl = "snapshots/import";
export const getSnapshotDetailsUrl = (snapshotId) => `snapshots/${snapshotId}/details`;
export const getSnapshotReviewUrl = (snapshotId) => `snapshots/${snapshotId}`;
export const getSnapshotCalculationUrl = (snapshotId) => newCalculationUrl + `?snapshot=${snapshotId}&active=0`;
