import { memo, useMemo } from "react";
import { Provider as ReduxProvider } from "react-redux";

import AppErrorBoundary from "app/core/components/AppErrorBoundary";
import AppIntlProvider from "app/core/providers/AppIntlProvider";
import AppLayout from "./AppLayout";

import AppNowProvider from "app/core/providers/AppNowProvider";
import AppRouterProvider from "app/core/providers/AppRouterProvider";
import AppScrollbarWidthProvider from "app/core/providers/AppScrollbarWidthProvider";
import AppTopNavProvider from "app/core/providers/AppTopNavProvider";

import AppTenantDetectionProvider from "app/core/providers/AppTenantDetectionProvider";
import AppTenantUserLinkProvider, { useAppTenantUserLinkContext } from "app/core/providers/AppTenantUserLinkProvider";
import AppUpdateProvider from "app/core/providers/AppUpdateProvider";
import AppUserTenantsProvider from "app/core/providers/AppUserTenantsProvider";
import AppUserProvider, { useAppUserContext } from "app/core/providers/AppUserProvider";

import FirebaseAuthProvider from "app/features/auth/components/FirebaseAuthProvider";
import SnapshotScopeProvider from "app/features/snapshots/components/SnapshotScopeProvider";
import SnapshotProvider from "app/features/snapshots/components/SnapshotProvider";
import WebSocketProvider from "app/features/webSockets/WebSocketProvider";
import { useIsDebugTenant } from "app/core/hooks";

const appProviders = [
  AppUpdateProvider,
  AppRouterProvider,
  AppIntlProvider,
  AppNowProvider,
  AppScrollbarWidthProvider,
  AppTopNavProvider,

  AppTenantDetectionProvider,
  AppUserProvider,
  AppUserTenantsProvider,
  AppTenantUserLinkProvider,

  FirebaseAuthProvider,
  WebSocketProvider,
];

const useAppTenantProviders = () => {
  const { userHasTenant } = useAppUserContext();
  const { isTenantUserPlanner } = useAppTenantUserLinkContext();
  const isDebugTenant = useIsDebugTenant();
  // TODO shouldn't this be the same conditions as in the AppTopNavbar.jsx?
  const provideSnapshots = !isDebugTenant && userHasTenant && isTenantUserPlanner;

  return useMemo(
    () => [provideSnapshots && SnapshotScopeProvider, provideSnapshots && SnapshotProvider].filter(Boolean),
    [provideSnapshots],
  );
};

const Providers = ({ providers, children }) => {
  // Reversed list of Providers so the first in the array becomes the first parent of all following Providers.
  // Wrap each provider with the next one.
  return [...providers].reverse().reduce((acc, Provider) => <Provider>{acc}</Provider>, children);
};

const AppProviders = ({ children }) => <Providers providers={appProviders}>{children}</Providers>;
const AppTenantProviders = ({ children }) => <Providers providers={useAppTenantProviders()}>{children}</Providers>;

//
// Root component that is rendered to the page
//
const App = ({ store }) => (
  <AppErrorBoundary store={store}>
    <ReduxProvider store={store}>
      <AppProviders>
        <AppTenantProviders>
          <AppLayout />
        </AppTenantProviders>
      </AppProviders>
    </ReduxProvider>
  </AppErrorBoundary>
);

export default memo(App);
