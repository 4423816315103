import Joi from "joi";
import moment from "app/utils/momentLocalized";

const momentExtension = {
  base: Joi.any(),
  name: "moment",
  language: {
    before: "needs to be before {{m}}", // Used below as 'moment.before'
    after: "needs to be after {{m}}", // Used below as 'moment.after'
  },
  rules: [
    {
      name: "valid",
      validate(params, value, state, options) {
        return moment(value).isValid();
      },
    },
    {
      name: "before",
      params: {
        m: Joi.alternatives([Joi.any().required(), Joi.func().ref()]),
      },
      validate(params, value, state, options) {
        const valueMoment = moment(value);

        const paramsKey = params.m.key;
        const paramsValue = state.parent[paramsKey];
        const paramsMoment = moment(paramsValue);

        const canValidate = value && valueMoment.isValid() && paramsValue && paramsMoment.isValid();
        const isInvalid = canValidate && !valueMoment.isBefore(paramsMoment);

        if (isInvalid) {
          // Generate an error, state and options need to be passed
          return this.createError(
            "moment.before",
            {
              v: value,
              m: paramsKey,
            },
            state,
            options,
          );
        }

        return value;
      },
    },
    {
      name: "after",
      params: {
        m: Joi.alternatives([Joi.any().required(), Joi.func().ref()]),
      },
      validate(params, value, state, options) {
        const valueMoment = moment(value);

        const paramsKey = params.m.key;
        const paramsValue = state.parent[paramsKey];
        const paramsMoment = moment(paramsValue);

        const canValidate = value && valueMoment.isValid() && paramsValue && paramsMoment.isValid();
        const isInvalid = canValidate && !valueMoment.isAfter(paramsMoment);

        if (isInvalid) {
          // Generate an error, state and options need to be passed
          return this.createError(
            "moment.after",
            {
              v: value,
              m: paramsKey,
            },
            state,
            options,
          );
        }

        return value;
      },
    },
  ], //TODO implement rules for min and max!
};

let CustomJoi = Joi.extend(momentExtension);

const durationExtension = {
  base: Joi.number().min(0),
  name: "duration",
  language: {
    spans:
      "has to be less than or equal to time between {{start}} and {{end}}, " +
      "maximum is {{maxHours}} hours and {{maxMinutes}} minutes", // Used below as 'duration.spans'
  },
  rules: [
    {
      name: "spans",
      params: {
        m1: Joi.alternatives([CustomJoi.moment().required(), Joi.func().ref()]),
        m2: Joi.alternatives([CustomJoi.moment().required(), Joi.func().ref()]),
      },
      validate(params, value, state, options) {
        const startKey = params.m1.key;
        const endKey = params.m2.key;

        const startMoment = moment(state.parent[startKey]);
        const endMoment = moment(state.parent[endKey]);

        const maxDuration = moment.duration(endMoment.diff(startMoment));
        const maxDurationAsMinutes = maxDuration.asMinutes();

        if (value <= maxDurationAsMinutes) {
          return value; // Everything is OK
        }
        const maxMinutes = maxDurationAsMinutes % 60;
        const maxHours = (maxDurationAsMinutes - maxMinutes) / 60;
        // Generate an error, state and options need to be passed
        return this.createError(
          "duration.spans",
          {
            v: value,
            start: startKey,
            end: endKey,
            maxHours,
            maxMinutes,
          },
          state,
          options,
        );
      },
    },
  ],
};

CustomJoi = CustomJoi.extend(durationExtension);

const stringExtension = {
  base: Joi.number().allow(null),
  name: "number",
  coerce(value, state, options) {
    if (value === "") {
      return null;
    }
    return value; // Keep the value as it was
  },
};

CustomJoi = CustomJoi.extend(stringExtension);

// TODO provide a better wrapper for these types
export const Pk = CustomJoi.number().integer().positive();

const pkArray = CustomJoi.array().items(Pk);

export const requiredPkArray = pkArray.required();

export const optionalString = CustomJoi.string().allow("").allow(null);
export const optionalIdExt = optionalString;
export const optionalUrl = CustomJoi.string().uri().allow("").allow(null);
export const optionalPk = Pk.allow(null);
export const optionalPkArray = pkArray.allow(null);
export const optionalTimes = CustomJoi.array().items(CustomJoi.string()).allow(null);

// TODO
//  should usually be optionalNonNegativeInteger ( add .min(0))
export const optionalInteger = CustomJoi.number().integer().allow(null);

export default CustomJoi;
