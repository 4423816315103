export const actionTypes = {
  DISMISS_MESSAGE: "DISMISS_MESSAGE",
  DISMISS_ALL_MESSAGES: "DISMISS_ALL_MESSAGES",
  ADD_ERROR_FROM_PROMISE: "ADD_ERROR_FROM_CAUGHT_PROMISE",
};

/***
 * Action creators for flash messages
 * @type {Object}
 */
const actionCreators = {
  dismissMessage(messageId) {
    return {
      type: actionTypes.DISMISS_MESSAGE,
      payload: messageId,
    };
  },

  dismissAllMessages() {
    return {
      type: actionTypes.DISMISS_ALL_MESSAGES,
    };
  },

  addErrorFromPromise(error) {
    return {
      type: actionTypes.ADD_ERROR_FROM_PROMISE,
      payload: error,
    };
  },
};

export default actionCreators;
