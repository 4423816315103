import SkillsDal from "./dal";
import { getEmptyPromise } from "app/utils/dal";

export const actionTypes = {
  LOAD_DETAILED_REQUIREMENT_ASYNC: "requirements/LOAD_DETAILED_REQUIREMENT_ASYNC",
  CLEAR_DETAILED_REQUIREMENT: "requirements/CLEAR_DETAILED_REQUIREMENT",
  CREATE_REQUIREMENT: "requirements/CREATE_REQUIREMENT",
  UPDATE_REQUIREMENT: "requirements/UPDATE_REQUIREMENT",
  DELETE_REQUIREMENT: "requirements/DELETE_REQUIREMENT",
};

/***
 * Action creators for skills
 * @type {Object}
 */
const actionCreators = {
  loadDetailedRequirement(requirementPk) {
    const dal = new SkillsDal();
    const actionPromise = dal.getDetailedRequirement(requirementPk);

    return {
      type: actionTypes.LOAD_DETAILED_REQUIREMENT_ASYNC,
      payload: actionPromise,
    };
  },

  clearDetailedRequirement() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_REQUIREMENT,
      payload: actionPromise,
    };
  },

  createRequirement(requirement) {
    const dal = new SkillsDal();
    const actionPromise = dal.createRequirement(requirement);
    return {
      type: actionTypes.CREATE_REQUIREMENT,
      payload: actionPromise,
    };
  },

  updateRequirement(requirement) {
    const dal = new SkillsDal();
    const actionPromise = dal.putRequirement(requirement);
    return {
      type: actionTypes.UPDATE_REQUIREMENT,
      payload: actionPromise,
    };
  },

  deleteRequirement(requirementPk) {
    const dal = new SkillsDal();
    const actionPromise = dal.deleteRequirement(requirementPk);
    return {
      type: actionTypes.DELETE_REQUIREMENT,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
