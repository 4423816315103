import InternalDal from "app/utils/InternalDal";
import { addDateQueryToUrl } from "app/utils/dal";

class CalendarsDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.calendarsUrl = `${this.dal.baseTenantUrl}/calendars`;
    this.eventsUrl = `${this.dal.baseTenantUrl}/calendar-events`;
    this.rulesUrl = `${this.dal.baseTenantUrl}/assignment-rules`;
  }

  getSimpleCalendars() {
    const url = `${this.calendarsUrl}/`;

    return this.dal.getter(url, "getSimpleCalendars");
  }

  getDetailedCalendar(calendarPk) {
    const url = `${this.calendarsUrl}/detailed/${calendarPk}/`;

    return this.dal.getter(url, "getDetailedCalendar");
  }

  getCalendarPreview(calendarPk, startDate, endDate) {
    const url = `${this.dal.baseTenantUrl}/preview-calendar/`;
    const data = {
      calendar: calendarPk,
      start: startDate,
      end: endDate,
    };

    return this.dal.poster(url, data, "getCalendarPreview");
  }

  getColorSlotsResourcePreview(resourcePk, startDate, endDate, previewAllCalendars) {
    const url = `${this.dal.baseTenantUrl}/preview-color-slots/`;
    const data = {
      resource: resourcePk,
      start: startDate,
      end: endDate,
      previewAllCalendars,
    };

    return this.dal.poster(url, data, "getColorSlotsResourcePreview");
  }

  getColorSlotsWorkPreview(workPk, startDate, endDate, previewAllCalendars) {
    const url = `${this.dal.baseTenantUrl}/preview-color-slots/`;
    const data = {
      work: workPk,
      start: startDate,
      end: endDate,
      previewAllCalendars,
    };

    return this.dal.poster(url, data, "getColorSlotsWorkPreview");
  }

  saveCalendarLinksResource(resourcePk, links, calendarType) {
    const url =
      calendarType === "rules"
        ? `${this.dal.baseTenantUrl}/save-calendar-links-rules-resources/`
        : `${this.dal.baseTenantUrl}/save-calendar-links-availability/`;

    const data = {
      resource: resourcePk,
      links,
    };

    return this.dal.poster(url, data, "saveCalendarLinksResource");
  }

  saveCalendarLinksStockItem(stockItemPk, links, calendarType) {
    const url =
      calendarType === "rules"
        ? `${this.dal.baseTenantUrl}/save-calendar-links-rules-stock-items/`
        : `${this.dal.baseTenantUrl}/save-calendar-links-availability/`;

    const data = {
      stock_item: stockItemPk,
      links,
    };

    return this.dal.poster(url, data, "saveCalendarLinksStockItem");
  }

  saveCalendarLinksWork(workPk, links, calendarType) {
    const url =
      calendarType === "rules"
        ? `${this.dal.baseTenantUrl}/save-calendar-links-rules-work/`
        : `${this.dal.baseTenantUrl}/save-calendar-links-availability/`;

    const data = {
      work: workPk,
      links,
    };

    return this.dal.poster(url, data, "saveCalendarLinksWork");
  }

  createCalendar(calendar) {
    const url = `${this.calendarsUrl}/`;

    return this.dal.poster(url, calendar, "createCalendar");
  }

  putCalendar(calendar) {
    const url = `${this.calendarsUrl}/${calendar.pk}/`;

    return this.dal.putter(url, calendar, "putCalendar");
  }

  deleteCalendar(calendarPk) {
    const url = `${this.calendarsUrl}/${calendarPk}/`;

    return this.dal.deleter(url, "deleteCalendar");
  }

  getDetailedEvent(eventPk) {
    const url = `${this.eventsUrl}/${eventPk}/`;

    return this.dal.getter(url, "getDetailedEvent");
  }

  createEvent(event) {
    const url = `${this.eventsUrl}/`;

    return this.dal.poster(url, event, "createEvent");
  }

  putEvent(event) {
    const url = `${this.eventsUrl}/${event.pk}/`;

    return this.dal.putter(url, event, "putEvent");
  }

  deleteEvent(eventPk) {
    const url = `${this.eventsUrl}/${eventPk}/`;

    return this.dal.deleter(url, "deleteEvent");
  }

  patchEvent(eventPk, data) {
    const url = `${this.eventsUrl}/${eventPk}/`;

    return this.dal.patcher(url, data, "patchEvent");
  }

  getExternalCalendarEvents(startDate, endDate) {
    let url = `${this.dal.baseTenantUrl}/external-calendar-events/`;
    url = addDateQueryToUrl(url, startDate, endDate);

    return this.dal.getter(url, "getExternalCalendarEvents");
  }

  createAssignmentRule(assignmentRule) {
    const url = `${this.rulesUrl}/`;

    return this.dal.poster(url, assignmentRule, "createAssignmentRule");
  }

  putAssignmentRule(assignmentRule) {
    const url = `${this.rulesUrl}/${assignmentRule.pk}/`;

    return this.dal.putter(url, assignmentRule, "putAssignmentRule");
  }

  getDetailedAssignmentRule(assignmentRulePk) {
    const url = `${this.rulesUrl}/${assignmentRulePk}/`;

    return this.dal.getter(url, "getDetailedAssignmentRule");
  }

  deleteAssignmentRule(assignmentRulePk) {
    const url = `${this.rulesUrl}/${assignmentRulePk}/`;

    return this.dal.deleter(url, "AssignmentRule");
  }
}

export default CalendarsDal;
