export const manageChangeoversUrl = "manage/changeovers";

export const manageResourceStatesUrl = "manage/resource-states";
export const getResourceStateDetailsUrl = (pk) => `${manageResourceStatesUrl}/${pk}`;
export const getResourceStateEditUrl = (pk) => `${manageResourceStatesUrl}/${pk}/edit`;
export const newResourceStateUrl = `${manageResourceStatesUrl}/new`;

export const manageResourceStateMatricesUrl = "manage/resource-state-matrices";
export const getResourceStateTransitionMatrixDetailsUrl = (pk) => `${manageResourceStateMatricesUrl}/${pk}`;
export const getResourceStateTransitionMatrixEditUrl = (pk) => `${manageResourceStateMatricesUrl}/${pk}/edit`;
export const newResourceStateMatrixUrl = `${manageResourceStateMatricesUrl}/new`;

// Matrix resets
export const getResourceStateTransitionMatrixDetailsResetsUrl = (pk) =>
  `${manageResourceStateMatricesUrl}/${pk}/resets`;
export const getResourceStateTransitionMatrixNewResetUrl = (pk) => `${manageResourceStateMatricesUrl}/${pk}/resets/new`;
export const getResourceStateTransitionMatrixEditResetUrl = (matrixPk, resetPk) =>
  `${manageResourceStateMatricesUrl}/${matrixPk}/resets/${resetPk}/edit`;

// Matrix transitions
export const getResourceStateTransitionMatrixDetailsTransitionsUrl = (pk) =>
  `${manageResourceStateMatricesUrl}/${pk}/transitions`;
export const getResourceStateTransitionMatrixNewTransitionUrl = (pk) =>
  `${manageResourceStateMatricesUrl}/${pk}/transitions/new`;
export const getResourceStateTransitionMatrixEditTransitionUrl = (matrixPk, transitionPk) =>
  `${manageResourceStateMatricesUrl}/${matrixPk}/transitions/${transitionPk}/edit`;

export const importResourceStatesUrl = "manage/resource-states/import";
export const importResourceStateTransitionsUrl = "manage/resource-states/import-transitions";
