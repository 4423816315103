import BusyFeedback from "app/common/components/BusyFeedback";
import Panel from "app/common/components/Panel";
import useToggleState from "app/common/hooks/useToggleState";

import SnapshotScopeSimpleTable from "app/features/snapshots/components/SnapshotScopeSimpleTable";
import {
  getScopeRecurrenceUrl,
  useSnapshotScopeContext,
} from "app/features/snapshots/components/SnapshotScopeProvider";

import { EuiSwitch } from "@elastic/eui";
import { useTenantNav, useTenantNavToUrl } from "app/features/tenants/hooks";
import { newSnapshotScopeUrl } from "app/features/snapshots/routing";

export const SnapshotScopeManager = ({ canEdit }) => {
  const {
    scopes,
    activeScopePk,

    handleActivateScope,
    handleDeleteScope,

    handleAddScopeRecurrence,
    handleDeleteScopeRecurrence,

    isLoadingScopes,
    isLoadingActiveScope,
  } = useSnapshotScopeContext();

  const { value: isCustomScopesOpen, toggle: toggleCustomScopesOpen } = useToggleState(activeScopePk !== null);

  const tenantNav = useTenantNav();
  const navToNewScope = useTenantNavToUrl(newSnapshotScopeUrl);
  const navToScopeRecurrence = ({ pk, recurrence }) => tenantNav(getScopeRecurrenceUrl(pk, recurrence?.pk));

  const scopesTable = (
    <SnapshotScopeSimpleTable
      scopes={scopes}
      activeScopePk={activeScopePk}
      onActivateScope={handleActivateScope}
      onAddScopeRecurrence={canEdit ? handleAddScopeRecurrence : null}
      onEditScopeRecurrence={canEdit ? navToScopeRecurrence : null}
      onDeleteScopeRecurrence={canEdit ? handleDeleteScopeRecurrence : null}
      onDeleteScope={canEdit ? handleDeleteScope : null}
      onNewScope={canEdit ? navToNewScope : null}
    />
  );

  const isLoading = isLoadingActiveScope || isLoadingScopes;

  return (
    <>
      <EuiSwitch
        id={"oldScope"}
        name={"oldScope"}
        checked={!isCustomScopesOpen}
        onChange={(e) => {
          const nextCustomScopesOpen = e.target.value;
          if (activeScopePk !== null && !nextCustomScopesOpen) {
            handleActivateScope(null);
          }
          toggleCustomScopesOpen();
        }}
        label="Gebruik traditionele scope (nu + 15 minuten)"
        compressed
      />

      {isCustomScopesOpen && (
        <>
          <br />
          <br />
          {isLoading ? <BusyFeedback /> : scopesTable}
        </>
      )}
    </>
  );
};

const SnapshotScopeManagerPage = () => {
  return (
    <Panel title="Snapshot-scopes">
      <SnapshotScopeManager canEdit={true} />
    </Panel>
  );
};

export default SnapshotScopeManagerPage;
