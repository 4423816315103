import { List } from "immutable";
import PropTypes from "prop-types";
import { ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { EuiPanel, EuiTitle } from "@elastic/eui";

import ElasticUIPanelActions from "app/common/components/ElasticUIPanelActions";
import Tabs from "app/common/components/Tabs";
import { emptyList } from "app/utils/constants";
import { PanelBackButton, PanelDeleteButton, PanelEditButton, PanelSaveButton } from "app/common/components/Panel";
import PanelWarnings from "app/common/components/PanelWarnings";

const PanelActionButtons = ({ actionButtons }) => (
  <div className="">
    <ButtonToolbar>
      <ButtonGroup bsSize="small">{actionButtons}</ButtonGroup>
    </ButtonToolbar>
  </div>
);

const panelStyle = {
  width: "100%",
};

const tableStyle = {
  border: 0,
  padding: 0,
  margin: -16,
  borderCollapse: "collapse",
};

const ElasticUIPanel = ({
  title,
  subtitle,
  header,
  footer,
  actionItems,
  tabs,
  warnings,
  onSave,
  onEdit,
  onBack,
  onDelete,
  table,
  style = {},
  children,
}) => {
  const enhancedStyle = { ...panelStyle, ...style };
  const titlePart = title && (
    <>
      <EuiTitle>
        <h2>{title}</h2>
      </EuiTitle>
      {subtitle ? <h4>{subtitle}</h4> : null}
    </>
  );
  const warningsPart = !!warnings.size && <PanelWarnings warnings={warnings} />;
  const headerPart = header && <div>{header}</div>;

  const actionButtons = [
    onBack && <PanelBackButton key="back" onBack={onBack} />,
    onSave && <PanelSaveButton key="save" onSave={onSave} />,
    onEdit && <PanelEditButton key="edit" onEdit={onEdit} />,
    onDelete && <PanelDeleteButton key="delete" onDelete={onDelete} />,
  ].filter(Boolean);
  const actionButtonsPart = actionButtons.length > 0 && <PanelActionButtons actionButtons={actionButtons} />;
  const actionItemsPart = actionItems && <ElasticUIPanelActions actionItems={actionItems} />;

  const tabsPart = tabs && (
    <div>
      <Tabs tabs={tabs} />
    </div>
  );
  const footerPart = footer && <div>{footer}</div>;
  const bodyPart = <div>{children}</div>;
  const tablePart = <div>{table}</div>;

  const addPadding = tablePart && (children || actionItems.length > 0);
  const contentStyle = addPadding ? { paddingBottom: 25 } : null;

  return (
    <EuiPanel borderRadius="none" hasShadow={false} style={enhancedStyle} className="">
      <div style={contentStyle}>
        {actionItemsPart}
        {titlePart}
        {warningsPart}
        {headerPart}
        {tabsPart}
        {bodyPart}
      </div>

      <div style={tableStyle}>{tablePart}</div>
      {actionButtonsPart}
      {footerPart}
    </EuiPanel>
  );
};

ElasticUIPanel.propTypes = {
  tabs: PropTypes.array,
  actionItems: PropTypes.array,
  warnings: PropTypes.instanceOf(List),
};

ElasticUIPanel.defaultProps = {
  warnings: emptyList,
};

export default ElasticUIPanel;
