import InternalDal from "app/utils/InternalDal";

class WorkResourcePreferenceDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    const u = this.dal.baseTenantUrl;
    this.workResourcePreferencesUrl = `${u}/work-resource-preferences`;
  }

  getDetailedWorkResourcePreference(workResourcePreferencePk) {
    const url = `${this.workResourcePreferencesUrl}/${workResourcePreferencePk}/`;

    return this.dal.getter(url, "getDetailedWorkResourcePreference");
  }

  createWorkResourcePreference(workResourcePreference) {
    const url = `${this.workResourcePreferencesUrl}/`;

    return this.dal.poster(url, workResourcePreference, "createWorkResourcePreference");
  }

  putWorkResourcePreference(workResourcePreference) {
    const url = `${this.workResourcePreferencesUrl}/${workResourcePreference.pk}/`;

    return this.dal.putter(url, workResourcePreference, "putWorkResourcePreference");
  }

  previewWorkResourcePreference(workResourcePreference) {
    const url = `${this.dal.baseTenantUrl}/preview-work-resource-preference/`;

    return this.dal.poster(url, workResourcePreference, "previewWorkResourcePreference");
  }
}

export default WorkResourcePreferenceDal;
