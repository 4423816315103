//
// Util functions for Immutable.js objects.
//
import moment from "app/utils/momentLocalized";
import { createSelector, createSelectorCreator, defaultMemoize } from "reselect";
import { is, fromJS, Map, Set } from "immutable";
import { emptyMap } from "app/utils/constants";

//
// Toggles item from Immutable list.
//
// Params:
//  list: Immutable.List() instance
//  item: possible item in given list
//
// Returns: another Immutable.List() with given item toggled
//
export function toggleItem(list, item) {
  let newList;
  const exists = list.includes(item);
  if (exists) {
    // Remove item from list
    newList = list.delete(list.indexOf(item));
  } else {
    // Add item to list
    newList = list.push(item).sort();
  }
  return newList;
}

export const getPk = (o) => (o && o.get ? o.get("pk") : null);

export const getPks = (l) => (l ? l.map(getPk) : null);

export const getPkSet = (l) => (l ? Set(getPks(l)) : null);

export const equalPk = (pk1, pk2) => `${pk1}` === `${pk2}`;

export const hasPk = (o, pk) => o && pk && equalPk(getPk(o), pk);

export function arrayToPkMap(arr, itemType = null) {
  const mapJS = {};
  if (itemType) {
    arr.forEach((x) => {
      mapJS[x.pk] = Object.assign(x, { type: itemType });
    });
  } else {
    arr.forEach((x) => {
      mapJS[x.pk] = x;
    });
  }

  return fromJS(mapJS);
}

export const listToMap = (list, key) => {
  const mapJS = {};
  list.forEach((x) => {
    mapJS[x.get(key)] = x;
  });
  return fromJS(mapJS);
};
export const listToPkMap = (list) => listToMap(list, "pk");
export const listToUuidMap = (list) => listToMap(list, "uuid");

export const immutableToKeyMap = (imm, key) => (imm ? Map(imm.toList().map((obj) => [obj.get(key), obj])) : emptyMap);

// TODO NOTE that this yields an {int: obj} map whereas the above yields a {str(int): obj} map...!!! This sucks!
export const immutableToPkMap = (imm) => immutableToKeyMap(imm, "pk");

export const immutableToSelectOptions = (m) =>
  m
    .toList()
    .sortBy((o) => o.get("name"))
    .toJS()
    .map(({ pk, name }) => ({
      value: pk,
      label: name,
    }));

export function filterBetween(iterable, startMoment, endMoment, startAccessor = "start", endAccessor = "end") {
  let filteredIterable = iterable;
  if (startMoment) {
    filteredIterable = filteredIterable.filter((obj) => moment(obj.get(endAccessor)).isAfter(startMoment));
  }
  if (endMoment) {
    filteredIterable = filteredIterable.filter((obj) => moment(obj.get(startAccessor)).isBefore(endMoment));
  }

  return filteredIterable;
}

export function isBetweenFast(obj, startMoment, endMoment, startAccessor = "startMoment", endAccessor = "endMoment") {
  return (
    (!startMoment || obj.get(endAccessor).isAfter(startMoment)) &&
    (!endMoment || obj.get(startAccessor).isBefore(endMoment))
  );
}

export function filterBetweenFast(
  iterable,
  startMoment,
  endMoment,
  startAccessor = "startMoment",
  endAccessor = "endMoment",
) {
  // TODO
  //  - we can do this much faster if we know that the iterable is sorted!
  //  - we can do this much faster if we don't use moment but an integer comparison (we could use the moment .unix()
  //  method to get the ticks of the moment object)

  let filteredIterable = iterable;
  if (startMoment) {
    filteredIterable = filteredIterable.filter((obj) => obj.get(endAccessor).isAfter(startMoment));
  }
  if (endMoment) {
    filteredIterable = filteredIterable.filter((obj) => obj.get(startAccessor).isBefore(endMoment));
  }

  return filteredIterable;
}

export const createImmutableSelector = createSelectorCreator(defaultMemoize, is);

export const createGetSelector = (selector, key) => createSelector(selector, (obj) => obj.get(key));

export const createGetInSelector = (selector, keys) => createSelector(selector, (obj) => obj.getIn(keys));
