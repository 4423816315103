import { fromJS, is } from "immutable";

import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import ActionStatus from "app/utils/redux/actionStatus";
import { actionTypes } from "./actions";
import { hasPk } from "app/utils/immutableUtils";

export const lastSnapshotKey = "last";
export const lastSnapshotIsLoadingKey = "lastIsLoading";
export const noSnapshotFoundKey = "noSnapshotFound";

const initialStateJS = {
  [lastSnapshotKey]: null,
  [lastSnapshotIsLoadingKey]: false,
  [noSnapshotFoundKey]: false,
};
const initialState = fromJS(initialStateJS);
const errorStateJS = {
  ...initialStateJS,
  [noSnapshotFoundKey]: true,
};

function handlePollLastCompletedSnapshot(prevState, action) {
  let state = prevState;

  if (action.status === ActionStatus.DONE) {
    const snapshotInfoJS = action.payload.body;
    const snapshotInfo = fromJS(snapshotInfoJS);
    const prevSnapshotInfo = prevState.getIn([lastSnapshotKey, "snapshotInfo"]);

    if (hasPk(prevSnapshotInfo, snapshotInfoJS.pk) && !is(snapshotInfo, prevSnapshotInfo)) {
      state = state.setIn([lastSnapshotKey, "snapshotInfo"], snapshotInfo);
    }

    state = state.set(noSnapshotFoundKey, false);
  }
  if (action.status === ActionStatus.ERROR) {
    state = state.merge(errorStateJS);
  }

  return state;
}

function handleLoadLastCompletedSnapshot(prevState, action) {
  let state = prevState;

  if (action.status === ActionStatus.PENDING) {
    state = state.set(lastSnapshotIsLoadingKey, true);
  }

  if (action.status === ActionStatus.DONE) {
    const snapshotJS = action.payload.body;
    const snapshot = fromJS(snapshotJS);
    const snapshotDto = snapshot.get("planning_dict");
    const snapshotInfo = snapshot.delete("planning_dict");

    const snapshotMap = fromJS({
      snapshotInfo,
      snapshotDto,
    });
    state = state.set(lastSnapshotIsLoadingKey, false);
    state = state.set(lastSnapshotKey, snapshotMap);
    state = state.set(noSnapshotFoundKey, false);
  }
  if (action.status === ActionStatus.ERROR) {
    state = state.merge(errorStateJS);
  }

  return state;
}

const handlers = {
  [actionTypes.LOAD_LAST_COMPLETED_SNAPSHOT]: handleLoadLastCompletedSnapshot,
  [actionTypes.POLL_LAST_COMPLETED_SNAPSHOT]: handlePollLastCompletedSnapshot,
};

const reducer = (state = initialState, action) => reduceWithHandlers(state, action, handlers);
export default reducer;
