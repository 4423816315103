import { getBlockDetailsUrl } from "app/features/blocks/routing";

export const manageCalendarsUrl = "manage/calendars";
export const newCalendarUrl = `manage/calendars/new`;
export const importCalendarEventsUrl = "manage/calendars/calendar-events/import";

export const getNewCalendarForResourceUrl = (pk) => `manage/calendars/new?resource=${pk}`;
export const getNewCalendarForStockItemUrl = (pk) => `manage/calendars/new?stockItem=${pk}`;
export const getNewCalendarForWorkUrl = (pk) => `manage/calendars/new?work=${pk}`;

export const getCalendarUrl = (pk, tab = null) => {
  let url = `${manageCalendarsUrl}/${pk}`;
  if (tab) url = `${url}/${tab}`;
  return url;
};

export const getCalendarEventUrl = (calendarPk, calendarEventPk) =>
  `${getCalendarUrl(calendarPk)}/events/${calendarEventPk}`;

export const getCalendarEventRecurrenceUrl = (calendarPk, calendarEventPk, recurrencePk) =>
  `${getCalendarEventUrl(calendarPk, calendarEventPk)}/recurrence/${recurrencePk}`;

export const getCalendarNewEventUrl = (calendarPk) => getCalendarEventUrl(calendarPk, "event");

// We have different URLs for block events currently
export const getBlockStartEventUrl = (blockPk, calendarEventPk) =>
  `${getBlockDetailsUrl(blockPk)}/events/start/${calendarEventPk}`;

export const getBlockEndEventUrl = (blockPk, calendarEventPk) =>
  `${getBlockDetailsUrl(blockPk)}/events/end/${calendarEventPk}`;

export const getBlockConditionalEventUrl = (blockPk, calendarEventPk) =>
  `${getBlockDetailsUrl(blockPk)}/events/conditional/${calendarEventPk}`;

export const getBlockStartEventRecurrenceUrl = (blockPk, calendarEventPk, recurrencePk) =>
  `${getBlockStartEventUrl(blockPk, calendarEventPk)}/recurrence/${recurrencePk}`;
export const getBlockEndEventRecurrenceUrl = (blockPk, calendarEventPk, recurrencePk) =>
  `${getBlockEndEventUrl(blockPk, calendarEventPk)}/recurrence/${recurrencePk}`;
export const getBlockConditionalEventRecurrenceUrl = (blockPk, calendarEventPk, recurrencePk) =>
  `${getBlockConditionalEventUrl(blockPk, calendarEventPk)}/recurrence/${recurrencePk}`;
