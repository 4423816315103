import { fromJS } from "immutable";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";
import { notLoadedMap } from "app/utils/constants";
import {
  keyArrToClearHandler,
  keyArrToLoadDetailedHandler,
  keyArrToLoadSimpleHandler,
} from "app/utils/redux/reducerHandlers";

const initialState = fromJS({
  blocks: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
});

const handlers = {
  [actionTypes.LOAD_SIMPLE_BLOCKS]: keyArrToLoadSimpleHandler(["blocks", "all"], "block"),
  [actionTypes.CLEAR_SIMPLE_BLOCKS]: keyArrToClearHandler(["blocks", "all"]),
  [actionTypes.LOAD_DETAILED_BLOCK]: keyArrToLoadDetailedHandler(["blocks", "detailed"]),
  [actionTypes.CLEAR_DETAILED_BLOCK]: keyArrToClearHandler(["blocks", "detailed"]),
};

const reducer = (state = initialState, action) => reduceWithHandlers(state, action, handlers);

export default reducer;
