import PropTypes from "prop-types";
import { Component } from "react";

//
// Label for forms and fields
//
class Label extends Component {
  render() {
    const { inputName, label } = this.props;

    return (
      <label className="control-label" htmlFor={inputName} id={`${inputName}_label`}>
        {label}
      </label>
    );
  }

  static propTypes = {
    label: PropTypes.node,
    inputName: PropTypes.string,
  };

  static defaultProps = {
    label: "",
    inputName: "",
  };
}

export default Label;
