import { useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { CustomIcon, emptyList, Icons } from "app/utils/constants";
import renderMoment from "app/utils/renderMoment";

import { getUserHasTenant } from "app/features/users/selectors";
import {
  getIsLoadingLastCompletedSnapshot,
  getLastCompletedSnapshotDto,
  getLastCompletedSnapshotIsMissingChanges,
  getLastCompletedSnapshotIsOutDated,
  getLastCompletedSnapshotPk,
  getLastCompletedSnapshotScopeEnd,
  getLastCompletedSnapshotScopePk,
  getLastCompletedSnapshotScopeStart,
  getNoSnapshotFound,
  getSnapshotNextRun,
} from "app/features/snapshots/selectors";

import { Spinner } from "app/common/components/BusyFeedback";
import { useAppTenantUserLinkContext } from "app/core/providers/AppTenantUserLinkProvider";
import { useNowMomentWithSecondsPrecision } from "app/core/providers/AppNowProvider";
import { useSnapshotContext } from "app/features/snapshots/components/SnapshotProvider";
import { useSnapshotScopeContext } from "app/features/snapshots/components/SnapshotScopeProvider";
import { getSnapshotDetailsUrl } from "app/features/snapshots/routing";

import SnapshotScopeModal from "app/features/snapshots/components/SnapshotScopeModal";
import TenantUrlLink from "app/features/tenants/components/TenantUrlLink";
import ScopeErrorsWarningsCount from "app/features/planTools/scope/components/ScopeErrorsWarningsCount";

require("./SnapshotStatusBar.less");

const divStyle = {
  paddingTop: "4px",
  paddingLeft: "10px",
  paddingRight: "10px",
  height: 30,
  minHeight: 30,
  width: "100%",
  color: "white",
};

const noSnapshotStyle = { color: "red" };
const snapshotWarningStyle = { color: "orange" };
const snapshotValidStyle = { color: "green" };
const nextRunStyle = { color: "#7d7d7d" };

const fadedStyle = { color: "#9d9d9d" };
const lightStyle = { color: "#DDD" };

const SnapshotStatusBar = () => {
  const userHasTenant = useSelector(getUserHasTenant);
  const { isTenantUserPlanner } = useAppTenantUserLinkContext();

  const snapshotPk = useSelector(getLastCompletedSnapshotPk);
  const snapshotScopeStart = useSelector(getLastCompletedSnapshotScopeStart);
  const snapshotScopeEnd = useSelector(getLastCompletedSnapshotScopeEnd);
  const snapshotScopePk = useSelector(getLastCompletedSnapshotScopePk);

  const isSnapshotMissingChanges = useSelector(getLastCompletedSnapshotIsMissingChanges);
  const isSnapshotOutDated = useSelector(getLastCompletedSnapshotIsOutDated);
  const nextRun = useSelector(getSnapshotNextRun);
  // TODO add a loader
  const isSnapshotLoading = useSelector(getIsLoadingLastCompletedSnapshot);
  const noSnapshotFound = useSelector(getNoSnapshotFound);

  const snapshotDto = useSelector(getLastCompletedSnapshotDto);
  const snapshotWarnings = snapshotDto?.get("warnings") || emptyList;
  const snapshotErrors = snapshotDto?.get("errors") || emptyList;

  const now = useNowMomentWithSecondsPrecision();

  const {
    activeScope,
    activeScopePk,

    isScopeModalOpen,
    toggleScopeModal,
    closeScopeModal,
  } = useSnapshotScopeContext();
  const activeScopeName = activeScope?.name;
  const { confirmLoadLastSnapshot, isSnapshotNotLast } = useSnapshotContext();

  // TODO
  //  - this should become the selected scope for the user/tenant if no scope has been selected we should prompt the
  //  user to determine one?
  const scope = useMemo(
    () => ({
      name: activeScopeName,
      start: snapshotScopeStart,
      end: snapshotScopeEnd,
    }),
    [activeScopeName, snapshotScopeStart, snapshotScopeEnd],
  );

  const noTenantOrNotPlanner = !userHasTenant || !isTenantUserPlanner;
  if (noTenantOrNotPlanner) {
    // Snapshot is not loaded, so no visuals
    return null;
  }

  const snapshotOutdatedMessages = [
    isSnapshotOutDated && <FormattedMessage id="Snapshot.warnings.isOutdated" />,
    isSnapshotMissingChanges && <FormattedMessage id="Snapshot.warnings.isMissingChanges" />,
  ].filter(Boolean);

  let content;
  let contentNotLastSnapshot;

  if (isSnapshotNotLast) {
    contentNotLastSnapshot = (
      <>
        &nbsp;-&nbsp;
        <a onClick={confirmLoadLastSnapshot}>
          <i className="fa fa-refresh" />
          &nbsp;
          <FormattedMessage id="Snapshot.status.newSnapshotAvailable" />
        </a>
      </>
    );
  }

  const loader = (
    <>
      <Spinner />
      &nbsp;
      <FormattedMessage id="Snapshot.status.loadingSnapshot" />
    </>
  );

  if (isSnapshotLoading) {
    content = loader;
  } else if (noSnapshotFound) {
    content = <SnapshotNotFound />;
  } else if (activeScopePk && activeScopePk !== snapshotScopePk) {
    // TODO is this the right way to detect a mismatch?
    content = loader;
  } else if (snapshotOutdatedMessages.length > 0) {
    content = (
      <SnapshotOutdated
        snapshotPk={snapshotPk}
        snapshotScope={scope}
        snapshotOutdatedMessages={snapshotOutdatedMessages}
        snapshotWarnings={snapshotWarnings}
        snapshotErrors={snapshotErrors}
      />
    );
  } else if (snapshotScopeStart) {
    content = (
      <SnapshotUpToDate
        snapshotPk={snapshotPk}
        snapshotScope={scope}
        snapshotWarnings={snapshotWarnings}
        snapshotErrors={snapshotErrors}
      />
    );
  } else if (!snapshotPk) {
    // We also show the loader in case the initial loading has not started yet.
    content = loader;
  }

  const scopeModal = <SnapshotScopeModal onCloseModal={closeScopeModal} />;
  const contentNextRun = nextRun ? <NextRunSnapshot now={now} nextRun={nextRun} /> : null;

  return (
    <div style={divStyle}>
      <span style={fadedStyle}>
        {content}
        {contentNotLastSnapshot}
        {contentNextRun}
      </span>
      <a onClick={toggleScopeModal}>
        <CustomIcon icon={Icons.settings} />
      </a>
      {isScopeModalOpen && scopeModal}
    </div>
  );
};

const snapshotIcon = <CustomIcon icon={Icons.snapshot} />;

const SnapshotNotFound = () => (
  <>
    <span style={noSnapshotStyle}>{snapshotIcon}</span>
    &nbsp;
    <FormattedMessage id="Snapshot.status.noSnapshotFound" />
  </>
);

const SnapshotScopeMsg = ({ snapshotScope }) => {
  const { name, start, end } = snapshotScope;

  const scopeRangeText = (
    <>
      vanaf &nbsp;
      <span style={lightStyle}>{renderMoment(start)}</span>
      {end && (
        <>
          &nbsp; tot &nbsp;
          <span style={lightStyle}>{renderMoment(end)}</span>
        </>
      )}
    </>
  );
  const scopeNameText = name ? (
    <>
      (<span style={lightStyle}>{name}</span>)
    </>
  ) : null;

  return (
    <>
      {/*<FormattedMessage id="Snapshot.status.scopeStartMessage"/>*/}
      Plannen &nbsp;
      {scopeRangeText}
      &nbsp; &nbsp;
      {scopeNameText}
    </>
  );
};

const SnapshotOutdated = ({
  snapshotPk,
  snapshotScope,
  snapshotOutdatedMessages,
  snapshotWarnings,
  snapshotErrors,
}) => (
  <>
    <span style={snapshotWarningStyle}>{snapshotIcon}</span>
    &nbsp;
    <SnapshotScopeMsg snapshotScope={snapshotScope} />
    &nbsp; (
    <span style={snapshotWarningStyle}>
      {snapshotOutdatedMessages
        .map((msg, idx) => (
          <span key={idx}>
            {idx < snapshotOutdatedMessages.length - 1 ? (
              <>
                {msg}
                <span style={lightStyle}>{", "}</span>
              </>
            ) : (
              msg
            )}
          </span>
        ))
        .flat(Infinity)}
    </span>
    )
    {(snapshotWarnings.size > 0 || snapshotErrors.size > 0) && (
      <>
        &nbsp;
        <SnapshotWarningsErrors
          snapshotPk={snapshotPk}
          snapshotWarnings={snapshotWarnings}
          snapshotErrors={snapshotErrors}
        />
      </>
    )}
  </>
);

const SnapshotUpToDate = ({ snapshotPk, snapshotScope, snapshotWarnings, snapshotErrors }) => (
  <>
    <span style={snapshotValidStyle}>{snapshotIcon}</span>
    &nbsp;
    <SnapshotScopeMsg snapshotScope={snapshotScope} />
    {(snapshotWarnings.size > 0 || snapshotErrors.size > 0) && (
      <>
        &nbsp;
        <SnapshotWarningsErrors
          snapshotPk={snapshotPk}
          snapshotWarnings={snapshotWarnings}
          snapshotErrors={snapshotErrors}
        />
      </>
    )}
  </>
);

const SnapshotWarningsErrors = ({ snapshotPk, snapshotWarnings, snapshotErrors }) => (
  <TenantUrlLink url={getSnapshotDetailsUrl(snapshotPk)} className="SnapshotStatusBarError">
    <ScopeErrorsWarningsCount errors={snapshotErrors} warnings={snapshotWarnings} countStyle={lightStyle} />
  </TenantUrlLink>
);

// TODO
//  - add options to toggle auto-updates, run it now, postpone by 5 minutes
const NextRunSnapshot = ({ nextRun, now }) => (
  <>
    &nbsp; &nbsp;
    <span style={nextRunStyle}>update {nextRun.from(now)}</span>
  </>
);

export default SnapshotStatusBar;
