import moment from "app/utils/momentLocalized";

export const defaultRenderDateFormat = "LL";
export const defaultRenderTimeFormat = "HH:mm";

export const maybeParseMoment = (m) => (moment.isMoment(m) ? m : moment(m));

export function getDefaultRenderMomentFormat() {
  // TODO get from moment
  const locale = moment.locale();
  if (locale === "nl") {
    return "DD MMM Y HH:mm";
  }

  return "MMM DD Y HH:mm";
}

function renderMoment(m, format = null) {
  if (!m) {
    return null;
  }

  m = maybeParseMoment(m);
  if (!m.isValid()) {
    return null;
  }

  format = format || getDefaultRenderMomentFormat();
  return m.format(format);
}

export default renderMoment;
