import WorkDal from "./dal";
import { getEmptyPromise } from "app/utils/dal";
import workResourcePreferenceActions from "app/features/workResourcePreference/actions";

export const actionTypes = {
  // PROJECTS
  LOAD_PROJECTS_ASYNC: "work/LOAD_PROJECTS_ASYNC",
  LOAD_DETAILED_PROJECT_ASYNC: "work/LOAD_DETAILED_PROJECT_ASYNC",
  CLEAR_DETAILED_PROJECT: "work/CLEAR_DETAILED_PROJECT",

  CLONE_PROJECT_ASYNC: "work/CLONE_PROJECT_ASYNC",
  CREATE_PROJECT: "work/CREATE_PROJECT",
  CREATE_PROJECT_FROM_TEMPLATE: "work/CREATE_PROJECT_FROM_TEMPLATE",
  CREATE_TEMPLATE_FROM_PROJECT: "work/CREATE_TEMPLATE_FROM_PROJECT",
  UPDATE_PROJECT: "work/UPDATE_PROJECT",
  DELETE_PROJECT: "work/DELETE_PROJECT",
  BULK_DELETE_PROJECTS: "work/BULK_DELETE_PROJECTS",
  BULK_MARK_AS_COMPLETED_PROJECTS: "work/BULK_MARK_AS_COMPLETED_PROJECTS",

  // WORKS
  LOAD_DETAILED_WORK_ASYNC: "work/LOAD_DETAILED_WORK_ASYNC",
  CLEAR_DETAILED_WORK: "work/CLEAR_DETAILED_WORK",

  CLONE_WORK_ASYNC: "work/CLONE_WORK_ASYNC",
  CREATE_WORK: "work/CREATE_WORK",
  CREATE_WORK_FROM_TEMPLATE: "work/CREATE_WORK_FROM_TEMPLATE",
  CREATE_TEMPLATE_FROM_WORK: "work/CREATE_TEMPLATE_FROM_WORK",
  UPDATE_WORK: "work/UPDATE_WORK",
  PATCH_WORK: "work/PATCH_WORK",
  DELETE_WORK: "work/DELETE_WORK",
  BULK_DELETE_WORKS: "work/BULK_DELETE_WORKS",
  BULK_MARK_AS_COMPLETED_WORKS: "work/BULK_MARK_AS_COMPLETED_WORKS",
  BULK_ON_HOLD_WORKS: "work/BULK_ON_HOLD_WORKS",
  BULK_ON_HOLD_REMOVE_WORKS: "work/BULK_ON_HOLD_REMOVE_WORKS",

  SET_WORK_FILTER: "work/SET_WORK_FILTER",

  // ASSIGNMENTS
  LOAD_DETAILED_ASSIGNMENT_ASYNC: "work/LOAD_DETAILED_ASSIGNMENT_ASYNC",
  CLEAR_DETAILED_ASSIGNMENT: "work/CLEAR_DETAILED_ASSIGNMENT",
  CREATE_ASSIGNMENT: "work/CREATE_ASSIGNMENT",
  UPDATE_ASSIGNMENT: "work/UPDATE_ASSIGNMENT",
  PATCH_ASSIGNMENT: "work/PATCH_ASSIGNMENT",
  DELETE_ASSIGNMENT: "work/DELETE_ASSIGNMENT",
  LOCK_ASSIGNMENT: "work/LOCK_ASSIGNMENT",
  UNLOCK_ASSIGNMENT: "work/UNLOCK_ASSIGNMENT",
  BULK_DELETE_ASSIGNMENTS: "work/BULK_DELETE_ASSIGNMENTS",
  BULK_LOCK_ASSIGNMENTS: "work/BULK_LOCK_ASSIGNMENTS",
  BULK_UNLOCK_ASSIGNMENTS: "work/BULK_UNLOCK_ASSIGNMENTS",
  BULK_MARK_AS_COMPLETED_ASSIGNMENTS: "work/BULK_MARK_AS_COMPLETED_ASSIGNMENTS",
  BULK_REALISED_AS_PLANNED_ASSIGNMENTS: "work/BULK_REALISED_AS_PLANNED_ASSIGNMENTS",

  UPDATE_ASSIGNMENT_EXCEPTION: "work/UPDATE_ASSIGNMENT_EXCEPTION",

  // ASSIGNMENT TEMPLATES
  LOAD_DETAILED_ASSIGNMENT_TEMPLATE: "work/LOAD_DETAILED_ASSIGNMENT_TEMPLATE",
  CREATE_ASSIGNMENT_TEMPLATE: "work/CREATE_ASSIGNMENT_TEMPLATE",
  PATCH_ASSIGNMENT_TEMPLATE: "work/PATCH_ASSIGNMENT_TEMPLATE",
  UPDATE_ASSIGNMENT_TEMPLATE: "work/UPDATE_ASSIGNMENT_TEMPLATE",
  DELETE_ASSIGNMENT_TEMPLATE: "work/DELETE_ASSIGNMENT_TEMPLATE",
  BULK_DELETE_ASSIGNMENT_TEMPLATES: "work/BULK_DELETE_ASSIGNMENT_TEMPLATES",
  BULK_LOCK_ASSIGNMENT_TEMPLATES: "work/BULK_LOCK_ASSIGNMENT_TEMPLATES",
  BULK_UNLOCK_ASSIGNMENT_TEMPLATES: "work/BULK_UNLOCK_ASSIGNMENT_TEMPLATES",

  // PRECEDENCES
  LOAD_DETAILED_PRECEDENCE_ASYNC: "work/LOAD_DETAILED_PRECEDENCE_ASYNC",
  CLEAR_DETAILED_PRECEDENCE: "work/CLEAR_DETAILED_PRECEDENCE",
  CREATE_PRECEDENCE: "work/CREATE_PRECEDENCE",
  UPDATE_PRECEDENCE: "work/UPDATE_PRECEDENCE",
  DELETE_PRECEDENCE: "work/DELETE_PRECEDENCE",

  // WORKS SAME RESOURCES
  LOAD_DETAILED_WORKS_SAME_RESOURCE: "work/LOAD_DETAILED_WORKS_SAME_RESOURCE",
  CLEAR_DETAILED_WORKS_SAME_RESOURCE: "work/CLEAR_DETAILED_WORKS_SAME_RESOURCE",
  CREATE_WORKS_SAME_RESOURCE: "work/CREATE_WORKS_SAME_RESOURCE",
  UPDATE_WORKS_SAME_RESOURCE: "work/UPDATE_WORKS_SAME_RESOURCE",
  DELETE_WORKS_SAME_RESOURCE: "work/DELETE_WORKS_SAME_RESOURCE",

  // WORK IMPORTANCES
  LOAD_WORK_IMPORTANCES: "work/LOAD_WORK_IMPORTANCES",
  CLEAR_WORK_IMPORTANCES: "work/CLEAR_WORK_IMPORTANCES",
  LOAD_DETAILED_WORK_IMPORTANCE: "work/LOAD_DETAILED_WORK_IMPORTANCE",
  CLEAR_DETAILED_WORK_IMPORTANCE: "work/CLEAR_DETAILED_WORK_IMPORTANCE",
  CREATE_WORK_IMPORTANCE: "work/CREATE_WORK_IMPORTANCE",
  UPDATE_WORK_IMPORTANCE: "work/UPDATE_WORK_IMPORTANCE",
  DELETE_WORK_IMPORTANCE: "work/DELETE_WORK_IMPORTANCE",

  // WORKS SPANS
  LOAD_DETAILED_WORKS_SPAN: "work/LOAD_DETAILED_WORKS_SPAN",
  CLEAR_DETAILED_WORKS_SPAN: "work/CLEAR_DETAILED_WORKS_SPAN",
  CREATE_WORKS_SPAN: "work/CREATE_WORKS_SPAN",
  UPDATE_WORKS_SPAN: "work/UPDATE_WORKS_SPAN",
  DELETE_WORKS_SPAN: "work/DELETE_WORKS_SPAN",

  // WORKS CONDITIONAL
  LOAD_DETAILED_WORKS_CONDITIONAL: "work/LOAD_DETAILED_WORKS_CONDITIONAL",
  CLEAR_DETAILED_WORKS_CONDITIONAL: "work/CLEAR_DETAILED_WORKS_CONDITIONAL",
  CREATE_WORKS_CONDITIONAL: "work/CREATE_WORKS_CONDITIONAL",
  UPDATE_WORKS_CONDITIONAL: "work/UPDATE_WORKS_CONDITIONAL",
  DELETE_WORKS_CONDITIONAL: "work/DELETE_WORKS_CONDITIONAL",

  // ASSIGNMENT RULES
  LOAD_ASSIGNMENT_RULES: "work/LOAD_ASSIGNMENT_RULES",
};

const workActionCreators = {
  loadDetailedProjectAsync(projectPk) {
    const dal = new WorkDal();
    const actionPromise = dal.getDetailedProject(projectPk);
    return {
      type: actionTypes.LOAD_DETAILED_PROJECT_ASYNC,
      payload: actionPromise,
    };
  },

  clearDetailedProject() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_PROJECT,
      payload: actionPromise,
    };
  },

  cloneProject(projectPk) {
    const dal = new WorkDal();
    const actionPromise = dal.cloneProject(projectPk);
    return {
      type: actionTypes.CLONE_PROJECT_ASYNC,
      payload: actionPromise,
    };
  },

  createProject(project) {
    const dal = new WorkDal();
    const actionPromise = dal.createProject(project);
    return {
      type: actionTypes.CREATE_PROJECT,
      payload: actionPromise,
    };
  },

  createProjectFromTemplate(project, projectTemplatePk) {
    const dal = new WorkDal();
    const actionPromise = dal.createProjectFromTemplate(project, projectTemplatePk);
    return {
      type: actionTypes.CREATE_PROJECT_FROM_TEMPLATE,
      payload: actionPromise,
    };
  },

  createTemplateFromProject(projectPk) {
    const dal = new WorkDal();
    const actionPromise = dal.createTemplateFromProject(projectPk);
    return {
      type: actionTypes.CREATE_TEMPLATE_FROM_PROJECT,
      payload: actionPromise,
    };
  },

  updateProject(project) {
    const dal = new WorkDal();
    const actionPromise = dal.putProject(project);
    return {
      type: actionTypes.UPDATE_PROJECT,
      payload: actionPromise,
    };
  },

  deleteProject(projectPk) {
    const dal = new WorkDal();
    const actionPromise = dal.deleteProject(projectPk);
    return {
      type: actionTypes.DELETE_PROJECT,
      payload: actionPromise,
    };
  },

  bulkMarkAsCompletedProjects(projectPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionProjects(projectPks, "completed");

    return {
      type: actionTypes.BULK_MARK_AS_COMPLETED_PROJECTS,
      payload: actionPromise,
    };
  },

  bulkDeleteProjects(projectPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionProjects(projectPks, "delete");

    return {
      type: actionTypes.BULK_DELETE_PROJECTS,
      payload: actionPromise,
    };
  },

  loadDetailedWorkAsync(workPk) {
    const dal = new WorkDal();
    return {
      type: actionTypes.LOAD_DETAILED_WORK_ASYNC,
      payload: dal.getDetailedWork(workPk),
    };
  },

  clearDetailedWork() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_WORK,
      payload: actionPromise,
    };
  },

  cloneWork(workPk) {
    const dal = new WorkDal();
    const actionPromise = dal.cloneWork(workPk);
    return {
      type: actionTypes.CLONE_WORK_ASYNC,
      payload: actionPromise,
    };
  },

  createWork(work) {
    const dal = new WorkDal();
    const actionPromise = dal.createWork(work);
    return {
      type: actionTypes.CREATE_WORK,
      payload: actionPromise,
    };
  },

  createWorkFromTemplate(work, workTemplatePk) {
    const dal = new WorkDal();
    const actionPromise = dal.createWorkFromTemplate(work, workTemplatePk);
    return {
      type: actionTypes.CREATE_WORK_FROM_TEMPLATE,
      payload: actionPromise,
    };
  },

  createTemplateFromWork(workPk) {
    const dal = new WorkDal();
    const actionPromise = dal.createTemplateFromWork(workPk);
    return {
      type: actionTypes.CREATE_TEMPLATE_FROM_WORK,
      payload: actionPromise,
    };
  },

  updateWork(work) {
    const dal = new WorkDal();
    const actionPromise = dal.putWork(work);
    return {
      type: actionTypes.UPDATE_WORK,
      payload: actionPromise,
    };
  },

  patchWork(work) {
    const dal = new WorkDal();
    const actionPromise = dal.patchWork(work);

    return {
      type: actionTypes.PATCH_WORK,
      payload: actionPromise,
    };
  },

  deleteWork(workPk) {
    const dal = new WorkDal();
    const actionPromise = dal.deleteWork(workPk);
    return {
      type: actionTypes.DELETE_WORK,
      payload: actionPromise,
    };
  },

  bulkDeleteWorks(workPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionWorks(workPks, "delete");

    return {
      type: actionTypes.BULK_DELETE_WORKS,
      payload: actionPromise,
    };
  },

  bulkMarkAsCompletedWorks(workPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionWorks(workPks, "completed");

    return {
      type: actionTypes.BULK_MARK_AS_COMPLETED_WORKS,
      payload: actionPromise,
    };
  },

  bulkOnHold(workPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionWorks(workPks, "on_hold");

    return {
      type: actionTypes.BULK_ON_HOLD_WORKS,
      payload: actionPromise,
    };
  },

  bulkOnHoldRemove(workPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionWorks(workPks, "on_hold_remove");

    return {
      type: actionTypes.BULK_ON_HOLD_REMOVE_WORKS,
      payload: actionPromise,
    };
  },

  setWorkFilter(filter) {
    return {
      type: actionTypes.SET_WORK_FILTER,
      payload: {
        filter,
      },
    };
  },

  //
  // ASSIGNMENTS
  //
  loadDetailedAssignmentAsync(assignmentPk) {
    const dal = new WorkDal();
    const actionPromise = dal.getDetailedAssignment(assignmentPk);
    return {
      type: actionTypes.LOAD_DETAILED_ASSIGNMENT_ASYNC,
      payload: actionPromise,
    };
  },

  clearDetailedAssignment() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_ASSIGNMENT,
      payload: actionPromise,
    };
  },

  createAssignment(assignment) {
    const dal = new WorkDal();
    const actionPromise = dal.createAssignment(assignment);

    return {
      type: actionTypes.CREATE_ASSIGNMENT,
      payload: actionPromise,
    };
  },

  updateAssignment(assignment) {
    const dal = new WorkDal();
    const actionPromise = dal.updateAssignment(assignment);

    return {
      type: actionTypes.UPDATE_ASSIGNMENT,
      payload: actionPromise,
    };
  },

  patchAssignment(assignment) {
    const dal = new WorkDal();
    const actionPromise = dal.patchAssignment(assignment);

    return {
      type: actionTypes.PATCH_ASSIGNMENT,
      payload: actionPromise,
    };
  },

  deleteAssignment(assignmentPk) {
    const dal = new WorkDal();
    const actionPromise = dal.deleteAssignment(assignmentPk);
    return {
      type: actionTypes.DELETE_ASSIGNMENT,
      payload: actionPromise,
    };
  },

  bulkDeleteAssignments(assignmentPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionAssignments(assignmentPks, "delete");

    return {
      type: actionTypes.BULK_DELETE_ASSIGNMENTS,
      payload: actionPromise,
    };
  },

  bulkLockAssignments(assignmentPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionAssignments(assignmentPks, "lock");

    return {
      type: actionTypes.BULK_LOCK_ASSIGNMENTS,
      payload: actionPromise,
    };
  },

  bulkUnlockAssignments(assignmentPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionAssignments(assignmentPks, "unlock");

    return {
      type: actionTypes.BULK_UNLOCK_ASSIGNMENTS,
      payload: actionPromise,
    };
  },

  bulkRealisedAsPlanned(assignmentPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionAssignments(assignmentPks, "realised_as_planned");

    return {
      type: actionTypes.BULK_REALISED_AS_PLANNED_ASSIGNMENTS,
      payload: actionPromise,
    };
  },

  bulkMarkAsCompletedAssignments(assignmentPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionAssignments(assignmentPks, "completed");

    return {
      type: actionTypes.BULK_MARK_AS_COMPLETED_ASSIGNMENTS,
      payload: actionPromise,
    };
  },

  lockAssignment(assignmentPk) {
    const dal = new WorkDal();
    const actionPromise = dal.patchAssignment({
      pk: assignmentPk,
      lock_all: true,
    });
    return {
      type: actionTypes.LOCK_ASSIGNMENT,
      payload: actionPromise,
    };
  },

  unlockAssignment(assignmentPk) {
    const dal = new WorkDal();
    const actionPromise = dal.patchAssignment({
      pk: assignmentPk,
      lock_all: false,
    });
    return {
      type: actionTypes.UNLOCK_ASSIGNMENT,
      payload: actionPromise,
    };
  },

  // ASSIGNMENT TEMPLATES
  loadDetailedAssignmentTemplate(assignmentTemplatePk) {
    const dal = new WorkDal();
    const actionPromise = dal.getDetailedAssignmentTemplate(assignmentTemplatePk);
    return {
      type: actionTypes.LOAD_DETAILED_ASSIGNMENT_TEMPLATE,
      payload: actionPromise,
    };
  },

  createAssignmentTemplate(assignmentTemplate) {
    const dal = new WorkDal();
    const actionPromise = dal.createAssignmentTemplate(assignmentTemplate);
    return {
      type: actionTypes.CREATE_ASSIGNMENT_TEMPLATE,
      payload: actionPromise,
    };
  },

  updateAssignmentTemplate(assignmentTemplate) {
    const dal = new WorkDal();
    const actionPromise = dal.updateAssignmentTemplate(assignmentTemplate);
    return {
      type: actionTypes.UPDATE_ASSIGNMENT_TEMPLATE,
      payload: actionPromise,
    };
  },

  patchAssignmentTemplate(assignmentTemplate) {
    const dal = new WorkDal();
    const actionPromise = dal.patchAssignmentTemplate(assignmentTemplate);

    return {
      type: actionTypes.PATCH_ASSIGNMENT_TEMPLATE,
      payload: actionPromise,
    };
  },

  deleteAssignmentTemplate(assignmentTemplatePk) {
    const dal = new WorkDal();
    const actionPromise = dal.deleteAssignmentTemplate(assignmentTemplatePk);
    return {
      type: actionTypes.DELETE_ASSIGNMENT_TEMPLATE,
      payload: actionPromise,
    };
  },

  bulkDeleteAssignmentTemplates(assignmentPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionAssignmentTemplates(assignmentPks, "delete");

    return {
      type: actionTypes.BULK_DELETE_ASSIGNMENT_TEMPLATES,
      payload: actionPromise,
    };
  },

  bulkLockAssignmentTemplates(assignmentPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionAssignmentTemplates(assignmentPks, "lock");

    return {
      type: actionTypes.BULK_LOCK_ASSIGNMENT_TEMPLATES,
      payload: actionPromise,
    };
  },

  bulkUnlockAssignmentTemplates(assignmentPks) {
    const dal = new WorkDal();
    const actionPromise = dal.bulkActionAssignmentTemplates(assignmentPks, "unlock");

    return {
      type: actionTypes.BULK_UNLOCK_ASSIGNMENT_TEMPLATES,
      payload: actionPromise,
    };
  },

  //
  // PRECEDENCES
  //
  loadDetailedPrecedenceAsync(precedencePk) {
    const dal = new WorkDal();
    return {
      type: actionTypes.LOAD_DETAILED_PRECEDENCE_ASYNC,
      payload: dal.getDetailedPrecedence(precedencePk),
    };
  },

  clearDetailedPrecedence() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_PRECEDENCE,
      payload: actionPromise,
    };
  },

  createPrecedence(precedence) {
    const dal = new WorkDal();
    const actionPromise = dal.createPrecedence(precedence);
    return {
      type: actionTypes.CREATE_PRECEDENCE,
      payload: actionPromise,
    };
  },

  updatePrecedence(precedence) {
    const dal = new WorkDal();
    const actionPromise = dal.putPrecedence(precedence);
    return {
      type: actionTypes.UPDATE_PRECEDENCE,
      payload: actionPromise,
    };
  },

  deletePrecedence(precedencePk) {
    const dal = new WorkDal();
    const actionPromise = dal.deletePrecedence(precedencePk);
    return {
      type: actionTypes.DELETE_PRECEDENCE,
      payload: actionPromise,
    };
  },

  //
  // WORK IMPORTANCES
  //
  loadWorkImportances() {
    const dal = new WorkDal();
    const actionPromise = dal.getWorkImportances();
    return {
      type: actionTypes.LOAD_WORK_IMPORTANCES,
      payload: actionPromise,
    };
  },

  clearWorkImportances() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_WORK_IMPORTANCES,
      payload: actionPromise,
    };
  },

  loadDetailedWorkImportance(workImportancePk) {
    const dal = new WorkDal();
    const actionPromise = dal.getDetailedWorkImportance(workImportancePk);
    return {
      type: actionTypes.LOAD_DETAILED_WORK_IMPORTANCE,
      payload: actionPromise,
    };
  },

  createWorkImportance(workImportance) {
    const dal = new WorkDal();
    const actionPromise = dal.createWorkImportance(workImportance);
    return {
      type: actionTypes.CREATE_WORK_IMPORTANCE,
      payload: actionPromise,
    };
  },

  updateWorkImportance(workImportance) {
    const dal = new WorkDal();
    const actionPromise = dal.putWorkImportance(workImportance);
    return {
      type: actionTypes.UPDATE_WORK_IMPORTANCE,
      payload: actionPromise,
    };
  },

  deleteWorkImportance(workImportancePk) {
    const dal = new WorkDal();
    const actionPromise = dal.deleteWorkImportance(workImportancePk);
    return {
      type: actionTypes.DELETE_WORK_IMPORTANCE,
      payload: actionPromise,
    };
  },

  //
  // WORKS SAME RESOURCES
  //
  clearDetailedWorksSameResources() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_WORKS_SAME_RESOURCE,
      payload: actionPromise,
    };
  },

  loadDetailedWorksSameResources(worksSameResourcePk) {
    const dal = new WorkDal();
    const actionPromise = dal.getDetailedWorksSameResource(worksSameResourcePk);
    return {
      type: actionTypes.LOAD_DETAILED_WORKS_SAME_RESOURCE,
      payload: actionPromise,
    };
  },

  createWorksSameResource(worksSameResource) {
    const dal = new WorkDal();
    const actionPromise = dal.createWorksSameResource(worksSameResource);
    return {
      type: actionTypes.CREATE_WORKS_SAME_RESOURCE,
      payload: actionPromise,
    };
  },

  updateWorksSameResource(worksSameResource) {
    const dal = new WorkDal();
    const actionPromise = dal.putWorksSameResource(worksSameResource);
    return {
      type: actionTypes.UPDATE_WORKS_SAME_RESOURCE,
      payload: actionPromise,
    };
  },

  deleteWorksSameResource(worksSameResourcePk) {
    const dal = new WorkDal();
    const actionPromise = dal.deleteWorksSameResource(worksSameResourcePk);
    return {
      type: actionTypes.DELETE_WORKS_SAME_RESOURCE,
      payload: actionPromise,
    };
  },

  //
  // WORKS SPAN
  //
  loadDetailedWorksSpan(worksSpanPk) {
    const dal = new WorkDal();
    const actionPromise = dal.getDetailedWorksSpan(worksSpanPk);
    return {
      type: actionTypes.LOAD_DETAILED_WORKS_SPAN,
      payload: actionPromise,
    };
  },

  createWorksSpan(worksSpan) {
    const dal = new WorkDal();
    const actionPromise = dal.createWorksSpan(worksSpan);
    return {
      type: actionTypes.CREATE_WORKS_SPAN,
      payload: actionPromise,
    };
  },

  updateWorksSpan(worksSpan) {
    const dal = new WorkDal();
    const actionPromise = dal.putWorksSpan(worksSpan);
    return {
      type: actionTypes.UPDATE_WORKS_SPAN,
      payload: actionPromise,
    };
  },

  deleteWorksSpan(worksSpanPk) {
    const dal = new WorkDal();
    const actionPromise = dal.deleteWorksSpan(worksSpanPk);
    return {
      type: actionTypes.DELETE_WORKS_SPAN,
      payload: actionPromise,
    };
  },

  // WORKS CONDITIONAL
  loadDetailedWorksConditional(worksConditionalPk) {
    const dal = new WorkDal();
    const actionPromise = dal.getDetailedWorksConditional(worksConditionalPk);
    return {
      type: actionTypes.LOAD_DETAILED_WORKS_CONDITIONAL,
      payload: actionPromise,
    };
  },

  createWorksConditional(worksConditional) {
    const dal = new WorkDal();
    const actionPromise = dal.createWorksConditional(worksConditional);
    return {
      type: actionTypes.CREATE_WORKS_CONDITIONAL,
      payload: actionPromise,
    };
  },

  updateWorksConditional(worksConditional) {
    const dal = new WorkDal();
    const actionPromise = dal.putWorksConditional(worksConditional);
    return {
      type: actionTypes.UPDATE_WORKS_CONDITIONAL,
      payload: actionPromise,
    };
  },

  deleteWorksConditional(worksConditionalPk) {
    const dal = new WorkDal();
    const actionPromise = dal.deleteWorksConditional(worksConditionalPk);
    return {
      type: actionTypes.DELETE_WORKS_CONDITIONAL,
      payload: actionPromise,
    };
  },

  ...workResourcePreferenceActions,
};

export default workActionCreators;
