import { useMemo } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import NotFound from "app/common/components/NotFound";
import { useAppTenantUserLinkContext } from "app/core/providers/AppTenantUserLinkProvider";

const removeDoubleSlashes = (s) =>
  s
    .replace(/\/+/g, "/") // replace consecutive slashes with a single slash
    .replace(/\/+$/, ""); // remove trailing slashes

export const nestedPath = (url, path) => removeDoubleSlashes(`${url}/${path}`);

const getSwitchRoute = ({
  userRoles = null,
  switchUrl,
  switchProps,
  switchNotFoundComponent = NotFound,
  component,
  exact,
  nested,
  redirect,
  path,
  roles,
  ...routeProps
}) => {
  const isNestedPath = nested !== false;
  const composedPath = isNestedPath ? nestedPath(switchUrl, path) : path;

  const userNotAllRoles = !allRolesMatched(roles, userRoles);
  if (userNotAllRoles) {
    // If user is not allowed to access this route, we return an exact route with the switchNotFoundComponent.
    return <Route exact={exact} path={composedPath} key={composedPath} component={switchNotFoundComponent} />;
  }

  if (redirect) {
    const redirectTo = isNestedPath ? nestedPath(switchUrl, redirect) : redirect;
    return <Redirect exact={exact} key={composedPath} path={composedPath} to={redirectTo} />;
  }

  const RenderComponent = component;
  const Renderer = () => <RenderComponent {...switchProps} {...routeProps} />;

  return <Route exact={exact} key={composedPath} path={composedPath} render={Renderer} />;
};

const RedirectTrailingSlashRenderer = ({ location }) => {
  const urlWithSlashWithoutQuery = location.pathname;
  const query = location.search;
  const urlWithoutTrailingSlash = urlWithSlashWithoutQuery.slice(0, -1) + query;
  return <Redirect to={`${urlWithoutTrailingSlash}`} />;
};
const redirectTrailingSlashRoute = <Route path="/*/" exact strict render={RedirectTrailingSlashRenderer} />;

const allRolesMatched = (rolesToMatch, userRoles) =>
  !rolesToMatch || !rolesToMatch.length || (userRoles && rolesToMatch.every((role) => userRoles.indexOf(role) !== -1));

export const useCurrentUserHasAllRoles = (roles) => {
  const { tenantUserRoles } = useAppTenantUserLinkContext();
  return useMemo(() => allRolesMatched(roles, tenantUserRoles), [roles, tenantUserRoles]);
};

export const CustomSwitch = ({
  routes = [],
  routeProps = {},
  routeRoles = null,
  indexRoute,
  notFoundComponent = NotFound,
}) => {
  const { url } = useRouteMatch();
  const { tenantUserRoles } = useAppTenantUserLinkContext();

  let switchRoutes;
  if (allRolesMatched(routeRoles, tenantUserRoles)) {
    switchRoutes = routes.filter(Boolean);
    if (indexRoute) {
      const switchIndexRoute = {
        exact: true,
        path: "",
        ...indexRoute,
      };
      switchRoutes.push(switchIndexRoute);
    }
    switchRoutes = switchRoutes.map((route) =>
      getSwitchRoute({
        userRoles: tenantUserRoles,
        switchProps: routeProps,
        switchUrl: url,
        switchNotFoundComponent: notFoundComponent,
        ...route,
      }),
    );
  }

  const notFoundRoute = notFoundComponent ? <Route path="*" key="notFound" component={notFoundComponent} /> : null;

  return (
    <Switch>
      {redirectTrailingSlashRoute}
      {switchRoutes}
      {notFoundRoute}
    </Switch>
  );
};
