import { useMemo } from "react";
import { EuiButton } from "@elastic/eui";
import { fromJS } from "immutable";

import { euiColorMap } from "app/common/pages/ElasticUIListPage";
import { CustomIcon, CustomIconWithLabel, Icons } from "app/utils/constants";
import { renderRecurrence } from "app/features/recurrences/components/RecurrenceRuleEditor";
import renderDuration from "app/utils/renderDuration";
import renderMoment from "app/utils/renderMoment";

const fadedStyle = { color: "#9d9d9d" };

const renderMomentOrUnknown = (m) => (m ? renderMoment(m) : "?");

const SnapshotScopeSimpleTable = ({
  scopes,
  activeScopePk,
  onNewScope,
  onActivateScope,
  onDeleteScope,

  onAddScopeRecurrence,
  onEditScopeRecurrence,
  onDeleteScopeRecurrence,
}) => {
  const scopesSortedJS = useMemo(
    () =>
      scopes
        ? fromJS(scopes)
            .sortBy((s) => [
              s.get("current_start") ?? s.get("next_start") ?? s.get("start"),
              s.get("current_end") ?? s.get("next_end") ?? s.get("end"),
              s.get("name"),
            ])
            .toJS()
        : [],
    [scopes],
  );

  const newScopeBtn = onNewScope && (
    <EuiButton size="s" onClick={onNewScope} color={euiColorMap.success} fill>
      <CustomIconWithLabel icon={Icons.add} label="Nieuwe scope" />
    </EuiButton>
  );

  return (
    <table className="table table-condensed">
      <thead>
        <tr>
          <th />
          <th>Naam</th>
          <th>Vanaf</th>
          <th>Tot</th>
          <th>{newScopeBtn}</th>
        </tr>
      </thead>
      <tbody>
        {scopesSortedJS.length > 0 ? (
          scopesSortedJS.map((scope) => {
            const {
              pk,
              name,
              start,
              end,
              recurrence,
              expires_minutes_after_start: minutesAfterStart,
              expires_minutes_before_end: minutesBeforeEnd,
              current_start,
              current_end,
              next_start,
              next_end,
            } = scope;
            const deleteScope = onDeleteScope ? () => onDeleteScope(pk) : null;
            const addRecurrence = onAddScopeRecurrence ? () => onAddScopeRecurrence(pk) : null;
            // TODO
            //  - add confirmDeleteScope
            //  - add link to edit scope in general and view more details

            const editRecurrence = recurrence && onEditScopeRecurrence ? () => onEditScopeRecurrence(scope) : null;
            // TODO add confirmDeleteRecurrence
            const deleteRecurrence =
              recurrence && onDeleteScopeRecurrence ? () => onDeleteScopeRecurrence(scope) : null;

            const editDeleteIcons = (
              <>
                {editRecurrence ? (
                  <a onClick={editRecurrence}>
                    <CustomIcon icon={Icons.edit} />
                  </a>
                ) : null}
                &nbsp;
                {deleteRecurrence ? (
                  <a onClick={deleteRecurrence}>
                    <CustomIcon icon={Icons.delete} />
                  </a>
                ) : null}
              </>
            );

            return (
              <tr key={pk}>
                <td>{pk === activeScopePk ? <b>active</b> : <a onClick={() => onActivateScope(pk)}>activate</a>}</td>
                <td>{name}</td>
                <td>
                  {recurrence ? (
                    <>
                      <span style={fadedStyle}>{renderMomentOrUnknown(start)} (eerste)</span>
                      <br />
                      {renderMomentOrUnknown(current_start)} (huidige)
                      <br />
                      <span style={fadedStyle}>{renderMomentOrUnknown(next_start)} (volgende)</span>
                      <br />
                    </>
                  ) : (
                    renderMoment(start)
                  )}
                </td>
                <td>
                  {recurrence ? (
                    <>
                      <span style={fadedStyle}>{renderMomentOrUnknown(end)} (eerste)</span>
                      <br />
                      {renderMomentOrUnknown(current_end)} (huidige)
                      <br />
                      <span style={fadedStyle}>{renderMomentOrUnknown(next_end)} (volgende)</span>
                      <br />
                    </>
                  ) : (
                    renderMoment(end)
                  )}
                </td>
                <td>{deleteScope ? <a onClick={deleteScope}>Delete</a> : null}</td>
                <td>
                  {recurrence ? (
                    <>
                      <u>Herhaling</u>&nbsp;&nbsp;{editDeleteIcons}
                      {renderRecurrence(recurrence)}
                      {minutesAfterStart ? (
                        <>
                          Verloopt {renderDuration(minutesAfterStart)} na de start
                          <br />
                        </>
                      ) : null}
                      {minutesBeforeEnd ? <>Verloopt {renderDuration(minutesBeforeEnd)} voor het einde</> : null}
                    </>
                  ) : addRecurrence ? (
                    <a onClick={addRecurrence}>Add recurrence</a>
                  ) : null}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={5}>
              <i>Nog geen scopes...</i>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default SnapshotScopeSimpleTable;
