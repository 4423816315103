import InternalDal from "app/utils/InternalDal";

class StoragesDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.storagesUrl = `${this.dal.baseTenantUrl}/storages`;
    this.storedItemsInboundUrl = `${this.dal.baseTenantUrl}/stored-items-inbound`;
    this.storedItemsOutboundUrl = `${this.dal.baseTenantUrl}/stored-items-outbound`;
    this.breedsUrl = `${this.dal.baseTenantUrl}/storage-breeds`;
  }

  /* Items */
  getSimpleStorages() {
    const url = `${this.storagesUrl}/`;
    return this.dal.getter(url, "getSimpleStorages");
  }

  getDetailedStorage(storagePk) {
    const url = `${this.storagesUrl}/${storagePk}/`;
    return this.dal.getter(url, "getDetailedStorage");
  }

  createStorage(storage) {
    const url = `${this.storagesUrl}/`;
    return this.dal.poster(url, storage, "createStorage");
  }

  putStorage(storage) {
    const url = `${this.storagesUrl}/${storage.pk}/`;
    return this.dal.putter(url, storage, "putStorage");
  }

  deleteStorage(storagePk) {
    const url = `${this.storagesUrl}/${storagePk}/`;
    return this.dal.deleter(url, "deleteStorage");
  }

  /* Breeds */
  getSimpleStorageBreeds() {
    const url = `${this.breedsUrl}/`;
    return this.dal.getter(url, "getSimpleStorageBreeds");
  }

  getDetailedStorageBreed(storageBreedPk) {
    const url = `${this.breedsUrl}/${storageBreedPk}/`;
    return this.dal.getter(url, "getDetailedStorageBreed");
  }

  createStorageBreed(storageBreed) {
    const url = `${this.breedsUrl}/`;
    return this.dal.poster(url, storageBreed, "createStorageBreed");
  }

  putStorageBreed(storageBreed) {
    const url = `${this.breedsUrl}/${storageBreed.pk}/`;
    return this.dal.putter(url, storageBreed, "putStorageBreed");
  }

  deleteStorageBreed(storageBreedPk) {
    const url = `${this.breedsUrl}/${storageBreedPk}/`;
    return this.dal.deleter(url, "deleteStorageBreed");
  }

  /* StoredItems */
  createStoredItemInbound(storedItemInbound) {
    const url = `${this.storedItemsInboundUrl}/`;
    return this.dal.poster(url, storedItemInbound, "createStoredItemInbound");
  }

  putStoredItemInbound(storedItemInbound) {
    const url = `${this.storedItemsInboundUrl}/${storedItemInbound.pk}/`;
    return this.dal.putter(url, storedItemInbound, "putStoredItemInbound");
  }

  deleteStoredItemInbound(storedItemInboundPk) {
    const url = `${this.storedItemsInboundUrl}/${storedItemInboundPk}/`;
    return this.dal.deleter(url, "deleteStoredItemInbound");
  }
}

export default StoragesDal;
