/***
 * Returns a moment-compatible time interval name corresponding to an app time interval.
 * Useful for moment operations based on the current app time interval
 *   e.g. moment(startDate).add(1, timeIntervalForMoment('workweek'))
 * @param planBoardIntervalType
 * @returns {*} Interval type name to use with moment.
 */
const mapping = {
  // Depending on locale, startOf('week') could be a Sunday or a Monday.
  // Let's keep it ISO, always Monday.
  isoweek: "isoweek",
  week: "isoweek",
  workweek: "isoweek",
};

const intervalTypeForMoment = (planBoardIntervalType) => mapping[planBoardIntervalType] || planBoardIntervalType;

export default intervalTypeForMoment;
