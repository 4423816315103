import ResourceStatesDal from "app/features/resourceStates/dal";
import { getEmptyPromise } from "app/utils/dal";

export const actionTypes = {
  CREATE_RESOURCE_STATE: "resourceStates/CREATE_RESOURCE_STATE",
  UPDATE_RESOURCE_STATE: "resourceStates/UPDATE_RESOURCE_STATE",
  DELETE_RESOURCE_STATE: "resourceStates/DELETE_RESOURCE_STATE",
  LOAD_DETAILED_RESOURCE_STATE: "resourceStates/LOAD_DETAILED_RESOURCE_STATE",
  CLEAR_DETAILED_RESOURCE_STATE: "resourceStates/CLEAR_DETAILED_RESOURCE_STATE",
  LOAD_SIMPLE_RESOURCE_STATES: "resourceStates/LOAD_SIMPLE_RESOURCE_STATES",
  CLEAR_SIMPLE_RESOURCE_STATES: "resourceStates/CLEAR_SIMPLE_RESOURCE_STATES",

  CREATE_RESOURCE_STATE_MATRIX: "resourceStates/CREATE_RESOURCE_STATE_MATRIX",
  UPDATE_RESOURCE_STATE_MATRIX: "resourceStates/UPDATE_RESOURCE_STATE_MATRIX",
  UPDATE_RESOURCE_STATE_MATRIX_RESOURCES: "resourceStates/UPDATE_RESOURCE_STATE_MATRIX_RESOURCES",
  UPDATE_RESOURCE_STATE_MATRIX_STATES: "resourceStates/UPDATE_RESOURCE_STATE_MATRIX_STATES",
  UPDATE_RESOURCE_STATE_MATRIX_FALLBACK_STATES: "resourceStates/UPDATE_RESOURCE_STATE_MATRIX_FALLBACK_STATES",
  DELETE_RESOURCE_STATE_MATRIX: "resourceStates/DELETE_RESOURCE_STATE_MATRIX",
  LOAD_DETAILED_RESOURCE_STATE_MATRIX: "resourceStates/LOAD_DETAILED_RESOURCE_STATE_MATRIX",
  CLEAR_DETAILED_RESOURCE_STATE_MATRIX: "resourceStates/CLEAR_DETAILED_RESOURCE_STATE_MATRIX",
  LOAD_SIMPLE_RESOURCE_STATE_MATRICES: "resourceStates/LOAD_SIMPLE_RESOURCE_STATE_MATRICES",
  CLEAR_SIMPLE_RESOURCE_STATE_MATRICES: "resourceStates/CLEAR_SIMPLE_RESOURCE_STATE_MATRICES",

  CREATE_RESOURCE_STATE_TRANSITION: "resourceStates/CREATE_RESOURCE_STATE_TRANSITION",
  UPDATE_RESOURCE_STATE_TRANSITION: "resourceStates/UPDATE_RESOURCE_STATE_TRANSITION",
  DELETE_RESOURCE_STATE_TRANSITION: "resourceStates/DELETE_RESOURCE_STATE_TRANSITION",
  LOAD_DETAILED_RESOURCE_STATE_TRANSITION: "resourceStates/LOAD_DETAILED_RESOURCE_STATE_TRANSITION",
  CLEAR_DETAILED_RESOURCE_STATE_TRANSITION: "resourceStates/CLEAR_DETAILED_RESOURCE_STATE_TRANSITION",

  CREATE_RESOURCE_STATE_RESET: "resourceStates/CREATE_RESOURCE_STATE_RESET",
  UPDATE_RESOURCE_STATE_RESET: "resourceStates/UPDATE_RESOURCE_STATE_RESET",
  DELETE_RESOURCE_STATE_RESET: "resourceStates/DELETE_RESOURCE_STATE_RESET",
  LOAD_DETAILED_RESOURCE_STATE_RESET: "resourceStates/LOAD_DETAILED_RESOURCE_STATE_RESET",
  CLEAR_DETAILED_RESOURCE_STATE_RESET: "resourceStates/CLEAR_DETAILED_RESOURCE_STATE_RESET",
};

const actionCreators = {
  /* States */
  loadSimpleResourceStates() {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.getSimpleResourceStates();
    return {
      type: actionTypes.LOAD_SIMPLE_RESOURCE_STATES,
      payload: actionPromise,
    };
  },

  clearSimpleResourceStates() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_SIMPLE_RESOURCE_STATES,
      payload: actionPromise,
    };
  },

  loadDetailedResourceState(resourceStatePk) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.getDetailedResourceState(resourceStatePk);
    return {
      type: actionTypes.LOAD_DETAILED_RESOURCE_STATE,
      payload: actionPromise,
    };
  },

  clearDetailedResourceState() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_RESOURCE_STATE,
      payload: actionPromise,
    };
  },

  createResourceState(resourceState) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.createResourceState(resourceState);
    return {
      type: actionTypes.CREATE_RESOURCE_STATE,
      payload: actionPromise,
    };
  },

  updateResourceState(resourceState) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.putResourceState(resourceState);
    return {
      type: actionTypes.UPDATE_RESOURCE_STATE,
      payload: actionPromise,
    };
  },

  deleteResourceState(resourceStatePk) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.deleteResourceState(resourceStatePk);
    return {
      type: actionTypes.DELETE_RESOURCE_STATE,
      payload: actionPromise,
    };
  },

  /* Matrices */
  loadSimpleResourceStateMatrices() {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.getSimpleResourceStateMatrices();
    return {
      type: actionTypes.LOAD_SIMPLE_RESOURCE_STATE_MATRICES,
      payload: actionPromise,
    };
  },

  clearSimpleResourceStateMatrices() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_SIMPLE_RESOURCE_STATE_MATRICES,
      payload: actionPromise,
    };
  },

  loadDetailedResourceStateMatrix(resourceStateMatrixPk) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.getDetailedResourceStateMatrix(resourceStateMatrixPk);
    return {
      type: actionTypes.LOAD_DETAILED_RESOURCE_STATE_MATRIX,
      payload: actionPromise,
    };
  },

  clearDetailedResourceStateMatrix() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_RESOURCE_STATE_MATRIX,
      payload: actionPromise,
    };
  },

  createResourceStateMatrix(resourceStateMatrix) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.createResourceStateMatrix(resourceStateMatrix);
    return {
      type: actionTypes.CREATE_RESOURCE_STATE_MATRIX,
      payload: actionPromise,
    };
  },

  updateResourceStateMatrix(resourceStateMatrix) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.patchResourceStateMatrix(resourceStateMatrix);
    return {
      type: actionTypes.UPDATE_RESOURCE_STATE_MATRIX,
      payload: actionPromise,
    };
  },

  updateResourceStateMatrixResources(resourceStateMatrixPk, resourcePks) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.patchResourceStateMatrixResources(resourceStateMatrixPk, resourcePks);
    return {
      type: actionTypes.UPDATE_RESOURCE_STATE_MATRIX_RESOURCES,
      payload: actionPromise,
    };
  },

  updateResourceStateMatrixStates(resourceStateMatrixPk, statePks) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.patchResourceStateMatrixStates(resourceStateMatrixPk, statePks);
    return {
      type: actionTypes.UPDATE_RESOURCE_STATE_MATRIX_STATES,
      payload: actionPromise,
    };
  },

  updateResourceStateMatrixFallbackStates(resourceStateMatrixPk, statePks) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.patchResourceStateMatrixFallbackStates(resourceStateMatrixPk, statePks);
    return {
      type: actionTypes.UPDATE_RESOURCE_STATE_MATRIX_FALLBACK_STATES,
      payload: actionPromise,
    };
  },

  deleteResourceStateMatrix(resourceStateMatrixPk) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.deleteResourceStateMatrix(resourceStateMatrixPk);
    return {
      type: actionTypes.DELETE_RESOURCE_STATE_MATRIX,
      payload: actionPromise,
    };
  },

  /* Transitions */
  loadDetailedResourceStateTransition(transitionPk) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.getDetailedResourceStateTransition(transitionPk);
    return {
      type: actionTypes.LOAD_DETAILED_RESOURCE_STATE_TRANSITION,
      payload: actionPromise,
    };
  },

  clearDetailedResourceStateTransition() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_RESOURCE_STATE_TRANSITION,
      payload: actionPromise,
    };
  },

  createResourceStateTransition(transition) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.createResourceStateTransition(transition);
    return {
      type: actionTypes.CREATE_RESOURCE_STATE_TRANSITION,
      payload: actionPromise,
    };
  },

  updateResourceStateTransition(transition) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.putResourceStateTransition(transition);
    return {
      type: actionTypes.UPDATE_RESOURCE_STATE_TRANSITION,
      payload: actionPromise,
    };
  },

  deleteResourceStateTransition(transitionPk) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.deleteResourceStateTransition(transitionPk);
    return {
      type: actionTypes.DELETE_RESOURCE_STATE_TRANSITION,
      payload: actionPromise,
    };
  },

  /* Resets */
  loadDetailedResourceStateReset(resetPk) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.getDetailedResourceStateReset(resetPk);
    return {
      type: actionTypes.LOAD_DETAILED_RESOURCE_STATE_RESET,
      payload: actionPromise,
    };
  },

  clearDetailedResourceStateReset() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_RESOURCE_STATE_RESET,
      payload: actionPromise,
    };
  },

  createResourceStateReset(reset) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.createResourceStateReset(reset);
    return {
      type: actionTypes.CREATE_RESOURCE_STATE_RESET,
      payload: actionPromise,
    };
  },

  updateResourceStateReset(reset) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.putResourceStateReset(reset);
    return {
      type: actionTypes.UPDATE_RESOURCE_STATE_RESET,
      payload: actionPromise,
    };
  },

  deleteResourceStateReset(resetPk) {
    const dal = new ResourceStatesDal();
    const actionPromise = dal.deleteResourceStateReset(resetPk);
    return {
      type: actionTypes.DELETE_RESOURCE_STATE_RESET,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
