import { isLoadingMap, notLoadedMap } from "app/utils/constants";
import { fromJS } from "immutable";
import { arrayToPkMap } from "app/utils/immutableUtils";

const handleLoadDetailed = (state, action, keyArr) => {
  if (action.status === "pending") {
    return state.setIn(keyArr, isLoadingMap);
  } else if (action.status === "done") {
    return state.setIn(keyArr, fromJS(action.payload.body));
  } else {
    return state;
  }
};

const handleClearDetailed = (state, action, keyArr) =>
  action.status === "done" ? state.setIn(keyArr, notLoadedMap) : state;

const handleLoadSimple = (state, action, keyArr, itemType = null) => {
  if (action.status === "pending") {
    return state.setIn(keyArr, isLoadingMap);
  } else if (action.status === "done") {
    const dataJS = action.payload.body.results;
    const dataMap = arrayToPkMap(dataJS, itemType);
    return state.setIn(keyArr, dataMap);
  } else {
    return state;
  }
};

export const keyArrToLoadSimpleHandler =
  (keyArr, itemType = null) =>
  (state, action) =>
    handleLoadSimple(state, action, keyArr, itemType);
export const keyArrToLoadDetailedHandler = (keyArr) => (state, action) => handleLoadDetailed(state, action, keyArr);
export const keyArrToClearHandler = (keyArr) => (state, action) => handleClearDetailed(state, action, keyArr);
