import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import moment from "app/utils/momentLocalized";
import { RRule } from "rrule";
import { FormattedMessage } from "react-intl";

import Panel from "app/common/components/Panel";
import RecurrenceRuleForm from "./RecurrenceRuleForm";
import { useAppActions } from "app/actions";
import { useFormRef } from "app/common/forms/hooks";

const constructRRuleObj = ({
  freq,
  date_from: dtstart,
  date_until: until,
  interval,
  count,
  bysetpos,
  byweekday,
  bymonth,
  byweekno,
  bymonthday,
}) =>
  new RRule({
    freq,
    dtstart: dtstart ? moment(dtstart).toDate() : null,
    until: until ? moment(until).toDate() : null,
    interval,
    count,
    bysetpos: bysetpos || null,
    byweekday: byweekday || null,
    bymonth: bymonth || null,
    byweekno: byweekno || null,
    bymonthday: bymonthday || null,
    byhour: 0,
    byminute: 0,
    bysecond: 0,
    wkst: RRule.MO,
  });

const rRuleWeekdays = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU];

const momentPreviewFormat = "YYYY-MM-DD (dddd)";
const flexRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
};
const flexColumnStyle = { width: "45%" };

const fixRecurrenceRuleBeforeSubmit = (recurrenceRule) => {
  const fixedRRule = {
    ...recurrenceRule,
    date_from: recurrenceRule.date_from || null,
    date_until: recurrenceRule.date_until || null,
    count: recurrenceRule.count !== "" ? recurrenceRule.count : null,
  };
  return fixedRRule;
};

const constructRRuleObjWithBugFix = (recurrenceRule) => {
  // TODO write proper check for rrule
  // This is a workaround for a bug that causes the weekday of toText to not correspond with the rrule (is 1 day off!!)
  const byWeekday = (recurrenceRule.byweekday || []).map((wd) => rRuleWeekdays[wd]);
  const updatedRecurrenceRule = {
    ...recurrenceRule,
    byweekday: byWeekday,
  };
  return constructRRuleObj(updatedRecurrenceRule);
};

export const renderRecurrence = (recurrence) => {
  if (!recurrence) return null;
  const { recurrence_rules: recurrenceRules } = recurrence;
  return recurrenceRules ? (
    <ul>
      {recurrenceRules.map((r) => (
        <li key={r.pk}>{renderRecurrenceRule(r)}</li>
      ))}
    </ul>
  ) : null;
};

export const renderRecurrenceRule = (recurrenceRule) => {
  const rule = constructRRuleObjWithBugFix(recurrenceRule);
  const ruleText = rule.toText();
  const dateFrom = recurrenceRule.date_from;
  const dateFromText = dateFrom ? `from ${moment(dateFrom).format("LL")} ` : "";

  return (
    <span>
      {dateFromText}
      {ruleText}
    </span>
  );
};

// TODO
//   - add examples
//   - allow to duplicate / save recurrences/recurrence rules?
const showExamples = false;
const exampleWeekDays = {
  freq: RRule.DAILY,
  interval: 1,
  byweekday: [0, 1, 2, 3, 4],
};
const exampleWeekendDays = {
  freq: RRule.DAILY,
  interval: 1,
  byweekday: [5, 6],
};

const RecurrenceRuleExamples = ({ onExample }) => (
  <div>
    <h4>
      <FormattedMessage id="RecurrenceRuleEditor.examples" />
    </h4>
    <ul>
      <li>
        <a onClick={() => onExample(exampleWeekDays)}>
          <FormattedMessage id="RecurrenceRuleEditor.weekDays" />
        </a>
      </li>
      <li>
        <a onClick={() => onExample(exampleWeekendDays)}>
          <FormattedMessage id="RecurrenceRuleEditor.weekendDays" />
        </a>
      </li>
    </ul>
  </div>
);

const RecurrenceRuleEditor = ({
  title,
  breadcrumbs,
  defaultStart,
  recurrencePk,
  recurrenceRule,
  onSubmit,
  onCancel,
}) => {
  const actions = useAppActions();
  const [recurrenceRuleJS, setRecurrenceRuleJS] = useState(recurrenceRule.toJS());
  const [calculatedMoments, setCalculatedMoments] = useState(null);

  const handlePreview = useCallback(() => {
    // TODO
    //   allow to change the start and end of the preview
    const startMoment = recurrenceRuleJS.date_from || moment(defaultStart);
    const endMoment = recurrenceRuleJS.date_until || moment(startMoment).add(1, "years");
    const start = moment(startMoment).toDate();
    const end = moment(endMoment).toDate();

    const fixedRecurrenceRule = fixRecurrenceRuleBeforeSubmit(recurrenceRuleJS);

    actions.recurrences.previewRecurrenceRule(fixedRecurrenceRule, start, end).payload.then((response) => {
      const calculatedDates = response.body.results;
      const calculatedMoments = calculatedDates.map((d) => moment(d));

      setCalculatedMoments(calculatedMoments);
    });
  }, [actions, defaultStart, recurrenceRuleJS]);

  const handleSubmit = useCallback(
    ({ cleanedData: recurrenceRule }) => {
      const fixedRecurrenceRule = fixRecurrenceRuleBeforeSubmit(recurrenceRule);
      onSubmit(fixedRecurrenceRule);
    },
    [onSubmit],
  );
  const handleChange = useCallback(({ cleanedData: recurrenceRuleJS }) => {
    if (!recurrenceRuleJS) return;
    setRecurrenceRuleJS(recurrenceRuleJS);
  }, []);
  const handleExample = useCallback(
    (exampleRecurrenceRuleJS) => {
      handleChange({
        cleanedData: {
          ...exampleRecurrenceRuleJS,
          recurrence: recurrencePk,
        },
      });
    },
    [recurrencePk, handleChange],
  );

  const [formRef, submitForm] = useFormRef();

  useEffect(() => {
    handlePreview();
  }, [handlePreview]);

  return (
    <Panel title={title} breadcrumbs={breadcrumbs} onSave={submitForm} onBack={onCancel}>
      {showExamples && <RecurrenceRuleExamples onExample={handleExample} />}
      <div style={flexRowStyle}>
        <div style={flexColumnStyle}>
          <h5>
            <FormattedMessage id="RecurrenceRuleEditor.fields" />
          </h5>
          <hr />
          <RecurrenceRuleForm
            ref={formRef}
            defaultStart={defaultStart}
            onSubmit={handleSubmit}
            onChange={handleChange}
            values={recurrenceRuleJS}
          />
        </div>

        <div style={flexColumnStyle}>
          <h5>{<FormattedMessage id="RecurrenceRuleEditor.preview" />}</h5>
          <hr />
          <pre>{renderRecurrenceRule(recurrenceRuleJS)}</pre>
          <i>{<FormattedMessage id="RecurrenceRuleEditor.descriptionWarning" />}</i>
          <ul>
            {calculatedMoments?.map((m) => (
              <li key={m}>{m.format(momentPreviewFormat)}</li>
            ))}
          </ul>
        </div>
      </div>
    </Panel>
  );
};

RecurrenceRuleEditor.propTypes = {
  title: PropTypes.node.isRequired,
  recurrenceRule: PropTypes.instanceOf(Map).isRequired,

  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default RecurrenceRuleEditor;
