import { Link } from "react-router-dom";
import { EuiButton } from "@elastic/eui";

import { LoginPanel } from "app/features/auth/components/Login";
import FirebaseUIDiv from "app/features/auth/components/FirebaseUIDiv";

const FirebaseAuthFlowWrongDomain = ({ tenantName, redirectUrl, correctDomainUrl, correctDomain, wrongDomain }) => {
  return (
    <div style={{ margin: "auto" }}>
      <LoginPanel
        content={
          <div style={{ width: "100%" }}>
            <FirebaseUIDiv isHidden={true} />
            <div
              style={{
                paddingTop: 40,
                textAlign: "center",
              }}
            >
              Tenant specific login is currently unavailable on this domain.
              <br />
              <span style={{ textDecoration: "line-through" }}>
                <b>{wrongDomain}</b>
              </span>
              <br />
              Use the following domain and update your bookmarks.
              <br />
              <b>{correctDomain}</b>
            </div>

            <div
              style={{
                paddingTop: 40,
                textAlign: "center",
              }}
            >
              <EuiButton fill size="m" href={correctDomainUrl}>
                Go to correct domain
              </EuiButton>
            </div>
          </div>
        }
        footer={
          <div>
            This is the specific login flow for tenant <b>{tenantName}</b>.<br />
            Log in with regular YouPlan <Link to={`/login?redirectUrl=${redirectUrl}`}>username and password</Link>{" "}
            instead.
          </div>
        }
      />
    </div>
  );
};

export default FirebaseAuthFlowWrongDomain;
