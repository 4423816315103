import WorkDal from "./dal";
import { getEmptyPromise } from "app/utils/dal";

export const actionTypes = {
  LOAD_WORK_RESOURCE_PREFERENCES: "work/LOAD_WORK_RESOURCE_PREFERENCES",
  LOAD_DETAILED_WORK_RESOURCE_PREFERENCE: "work/LOAD_DETAILED_WORK_RESOURCE_PREFERENCE",
  CLEAR_DETAILED_WORK_RESOURCE_PREFERENCE: "work/CLEAR_DETAILED_WORK_RESOURCE_PREFERENCE",
  CREATE_WORK_RESOURCE_PREFERENCE: "work/CREATE_WORK_RESOURCE_PREFERENCE",
  UPDATE_WORK_RESOURCE_PREFERENCE: "work/UPDATE_WORK_RESOURCE_PREFERENCE",
  DELETE_WORK_RESOURCE_PREFERENCE: "work/DELETE_WORK_RESOURCE_PREFERENCE",
};

const workActionCreators = {
  loadDetailedWorkResourcePreference(workResourcePreferencePk) {
    const dal = new WorkDal();
    const actionPromise = dal.getDetailedWorkResourcePreference(workResourcePreferencePk);
    return {
      type: actionTypes.LOAD_DETAILED_WORK_RESOURCE_PREFERENCE,
      payload: actionPromise,
    };
  },

  clearDetailedWorkResourcePreference() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_WORK_RESOURCE_PREFERENCE,
      payload: actionPromise,
    };
  },

  createWorkResourcePreference(workResourcePreference) {
    const dal = new WorkDal();
    const actionPromise = dal.createWorkResourcePreference(workResourcePreference);
    return {
      type: actionTypes.CREATE_WORK_RESOURCE_PREFERENCE,
      payload: actionPromise,
    };
  },

  updateWorkResourcePreference(workResourcePreference) {
    const dal = new WorkDal();
    const actionPromise = dal.putWorkResourcePreference(workResourcePreference);
    return {
      type: actionTypes.UPDATE_WORK_RESOURCE_PREFERENCE,
      payload: actionPromise,
    };
  },
};

export default workActionCreators;
