import BusyFeedback from "./BusyFeedback";

const BusyFeedbackPage = ({ label }) => (
  <div className="BusyFeedbackPage content-full">
    <div className="panel panel-widget">
      <div className="panel-body">
        <BusyFeedback label={label} />
      </div>
    </div>
  </div>
);

export default BusyFeedbackPage;
