import InternalDal from "app/utils/InternalDal";

//
// Data access layer for work planning tools
//
class PlanToolsDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.annealingUrl = `${this.dal.baseTenantUrl}/annealing`;
    this.planningUrl = `${this.dal.baseTenantUrl}/planning`;
    this.calculationsUrl = `${this.dal.baseTenantUrl}/calculations`;
  }

  loadCalculations() {
    const url = `${this.calculationsUrl}/?type=planning`;

    return this.dal.getter(url, "loadCalculations");
  }

  loadLastCompletedCalculation() {
    const url = `${this.calculationsUrl}/last/`;

    return this.dal.getter(url, "loadLastCompletedCalculation");
  }

  runCalculation(snapshotId, scope, settings) {
    const url = `${this.annealingUrl}/run/`;
    const data = {
      snapshot: snapshotId,
      scope,
      settings,
    };

    return this.dal.poster(url, data, "runCalculation");
  }

  rerunCalculation(calculationId, calculationSettings) {
    const url = `${this.annealingUrl}/rerun/`;
    const { handler, handler_opts, ...settings } = calculationSettings;
    const data = {
      calculation: calculationId,
      handler,
      handler_opts,
      settings,
    };

    return this.dal.poster(url, data, "rerunCalculation");
  }

  pauseCalculation(calcPk) {
    const url = `${this.annealingUrl}/pause/`;
    const data = { calcPk };

    return this.dal.poster(url, data, "pauseCalculation");
  }

  cancelCalculation(calcPk) {
    const url = `${this.annealingUrl}/cancel/`;
    const data = { calcPk };

    return this.dal.poster(url, data, "cancelCalculation");
  }

  continueCalculation(calcPk) {
    const url = `${this.annealingUrl}/continue/`;
    const data = { calculation: calcPk };

    return this.dal.poster(url, data, "continueCalculation");
  }

  archiveCalculation(calcPk) {
    const url = `${this.annealingUrl}/archive/`;
    const data = { calculation: calcPk };

    return this.dal.poster(url, data, "archiveCalculation");
  }

  unarchiveCalculation(calcPk) {
    const url = `${this.annealingUrl}/unarchive/`;
    const data = { calculation: calcPk };

    return this.dal.poster(url, data, "unarchiveCalculation");
  }

  updateCalculationDisplayName(calcPk, name) {
    const url = `${this.calculationsUrl}/${calcPk}/`;

    return this.dal.patcher(url, { display_name: name }, "updateCalculationName");
  }

  previewCalculation(snapshotName, scope) {
    const url = `${this.annealingUrl}/preview/`;
    const data = {
      snapshot: snapshotName,
      scope,
    };

    return this.dal.poster(url, data, "previewCalculation");
  }

  reviewCalculation(calculation) {
    const url = `${this.annealingUrl}/review/`;
    const data = { calculation };

    return this.dal.poster(url, data, "reviewCalculation");
  }

  reviewCalculationActions(calculation) {
    const url = `${this.annealingUrl}/review/actions`;
    const data = { calculation };

    return this.dal.poster(url, data, "reviewCalculationActions");
  }

  reviewCalculationIterations(calculation) {
    const url = `${this.annealingUrl}/review/iterations`;
    const data = { calculation };

    return this.dal.poster(url, data, "reviewCalculationIterations");
  }

  reviewCalculationOut(calculation) {
    const url = `${this.annealingUrl}/review/calc-out`;
    const data = { calculation };

    return this.dal.poster(url, data, "reviewCalculationOut");
  }

  reviewCalculationStats(calculation) {
    const url = `${this.annealingUrl}/review/stats`;
    const data = { calculation };

    return this.dal.poster(url, data, "reviewCalculationStats");
  }

  getDownloadCalculationResultsUrl(calculation) {
    return `${this.annealingUrl}/download?calculation=${calculation}`;
  }

  removeStateCalculation(calculation, stateUuid) {
    const url = `${this.annealingUrl}/remove-state/`;
    const data = {
      calculation,
      state_uuid: stateUuid,
    };

    return this.dal.poster(url, data, "removeStateCalculation");
  }

  acceptPlanningChanges({ assignmentsToCreate, assignmentsToEdit, assignmentPksToDelete }, actualStateSnapshotId) {
    const url = `${this.planningUrl}/changes/`;
    const data = {
      assignments_to_create: assignmentsToCreate,
      assignments_to_edit: assignmentsToEdit,
      assignment_pks_to_delete: assignmentPksToDelete,
      actual_snapshot: actualStateSnapshotId,
    };

    return this.dal.poster(url, data, "acceptPlanningChanges");
  }

  acceptCalculationResults(calculationPk) {
    const url = `${this.annealingUrl}/accept/`;
    const data = { calculation: calculationPk };

    return this.dal.poster(url, data, "acceptCalculationResults");
  }

  rejectCalculationResults(calculationPk) {
    const url = `${this.annealingUrl}/reject/`;
    const data = { calculation: calculationPk };

    return this.dal.poster(url, data, "rejectCalculationResults");
  }

  loadIteration(calculation, iterationNr) {
    const url = `${this.annealingUrl}/iteration/`;
    const data = {
      calculation,
      iteration_nr: iterationNr,
    };

    return this.dal.poster(url, data, "loadIteration");
  }

  replayIteration(calculation, iterationNr) {
    const url = `${this.annealingUrl}/iteration/replay/`;
    const data = {
      calculation,
      iter_start: iterationNr,
      iter_stop: iterationNr,
    };

    return this.dal.poster(url, data, "replayIteration");
  }

  exportToXlsx({ calculation, stateUuid, assignments, startDate, endDate }) {
    const url = `${this.annealingUrl}/export/`;
    const data = {
      calculation,
      assignments,
      state_uuid: stateUuid,
      start: startDate,
      end: endDate,
    };

    return this.dal.poster(url, data, "exportToXlsx", true);
  }

  runFunction({ calculation, stateUuid, assignments, functionName, functionArgs }) {
    const url = `${this.annealingUrl}/functions/`;
    const data = {
      calculation,
      assignments,
      state_uuid: stateUuid,
      function_name: functionName,
      function_args: functionArgs,
    };

    return this.dal.poster(url, data, "runFunction");
  }

  isCapableTeam(assignmentPk, workPk, resourcePks) {
    const url = `${this.dal.baseTenantUrl}/is-capable-team/`;
    const data = {
      work: workPk,
      resources: resourcePks,
    };

    if (assignmentPk) {
      data.assignment = assignmentPk;
    }

    return this.dal.poster(url, data, "isCapableTeam");
  }

  determineFragments(smallestFragment, largestFragment, moduloFragment, duration) {
    const url = `${this.dal.baseTenantUrl}/determine-fragments/`;
    const data = {
      smallest: smallestFragment,
      largest: largestFragment,
      modulo: moduloFragment,
      duration,
    };

    return this.dal.poster(url, data, "determineFragments");
  }
}

export default PlanToolsDal;
