import { combineReducers, compose, legacy_createStore as reduxCreateStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import promiseMiddleware from "./reduxPromiseMiddleware";
import { configureStore } from "@reduxjs/toolkit";

//
// Custom createStore for this project.
// Wraps redux createStore in order to use middleware.
//

function customCreateStore(appReducer) {
  // combine main app reducer under `app` with utility reducers
  // that need to be separate and not Immutable
  // e.g. routing, forms
  const reducer = combineReducers({
    app: appReducer,
  });

  const middlewares = [thunkMiddleware, promiseMiddleware];

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = reduxCreateStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));

  return store;
}

// TODO use this as default export for @reduxjs/toolkit
function customConfigureStore(appReducer) {
  return configureStore({
    reducer: { app: appReducer },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(promiseMiddleware),
  });
}

export default customCreateStore;
