import InternalDal from "app/utils/InternalDal";

class dal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.recurrenceRuleUrl = `${this.dal.baseTenantUrl}/recurrence-rules`;
    this.recurrenceUrl = `${this.dal.baseTenantUrl}/recurrences`;
  }

  createRecurrence(recurrence) {
    const url = `${this.recurrenceUrl}/`;

    return this.dal.poster(url, recurrence, "createRecurrence");
  }

  deleteRecurrence(recurrencePk) {
    const url = `${this.recurrenceUrl}/${recurrencePk}/`;

    return this.dal.deleter(url, "deleteRecurrence");
  }

  getDetailedRecurrenceRule(recurrenceRulePk) {
    const url = `${this.recurrenceRuleUrl}/${recurrenceRulePk}/`;

    return this.dal.getter(url, "loadDetailedRecurrenceRule");
  }

  createRecurrenceRule(recurrenceRule) {
    const url = `${this.recurrenceRuleUrl}/`;

    return this.dal.poster(url, recurrenceRule, "createRecurrenceRule");
  }

  putRecurrenceRule(recurrenceRule) {
    const url = `${this.recurrenceRuleUrl}/${recurrenceRule.pk}/`;

    return this.dal.putter(url, recurrenceRule, "putRecurrenceRule");
  }

  deleteRecurrenceRule(recurrenceRulePk) {
    const url = `${this.recurrenceRuleUrl}/${recurrenceRulePk}/`;

    return this.dal.deleter(url, "deleteRecurrenceRule");
  }

  previewRecurrenceRule(recurrenceRule, start, end) {
    const url = `${this.dal.baseTenantUrl}/preview-recurrence-rule/`;
    const data = {
      start,
      end,
      recurrence_rules: [recurrenceRule],
    };

    return this.dal.poster(url, data, "previewRecurrenceRule");
  }
}

export default dal;
