import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const panelBodyStyle = { textAlign: "center" };

const NotFound = ({ actionLabel, actionLink, message }) => {
  const actionPart = actionLink ? (
    <Link to={actionLink} className="btn">
      <span className="icon fa fa-arrow-left" />
      {actionLabel}
    </Link>
  ) : null;

  return (
    <div className="content-full">
      <div className="panel panel-widget not-found">
        <div className="panel-body" style={panelBodyStyle}>
          <div>
            <span className="icon fa fa-cog fa-2x" />
          </div>

          {message}
          {actionPart}
        </div>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  actionLabel: PropTypes.node,
  message: PropTypes.node,
};
NotFound.defaultProps = {
  message: <FormattedMessage id="NotFound.message" defaultMessage="Not found" />,
  actionLabel: <FormattedMessage id="NotFound.actionLabel" defaultMessage="Go back" />,
};

export default NotFound;
