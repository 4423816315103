export const manageStockUrl = "manage/stock";
export const manageStockItemsUrl = "manage/stock/items";
export const manageStockBreedsUrl = "manage/stock/breeds";

export const getStockItemDetailsUrl = (pk) => `${manageStockItemsUrl}/${pk}`;
export const getStockItemCreateLevelUrl = (pk) => `${manageStockItemsUrl}/${pk}/levels/new`;
export const getStockItemEditUrl = (pk) => `${manageStockItemsUrl}/${pk}/edit`;
export const getStockBreedDetailsUrl = (pk) => `${manageStockBreedsUrl}/${pk}`;
export const getStockBreedEditUrl = (pk) => `${manageStockBreedsUrl}/${pk}/edit`;

export const importStockItemLevelsUrl = `${manageStockItemsUrl}/import`;
export const newStockItemUrl = `${manageStockItemsUrl}/new`;
export const newStockBreedUrl = `${manageStockBreedsUrl}/new`;
