import { useCallback, useEffect, useRef, useState } from "react";

const oneSecondInMs = 1000;
const defaultCountdownSeconds = 5;

const useCountdown = ({ seconds = defaultCountdownSeconds, shouldAutoStart = false } = {}) => {
  const [countdownSeconds, setCountdownSeconds] = useState(-1);
  const countdownIntervalRef = useRef(null);

  const startCountdown = useCallback(
    (s = seconds) => {
      setCountdownSeconds(s);
      if (seconds > 0) {
        const decreaseCountdownSeconds = () => {
          setCountdownSeconds((c) => Math.max(0, c - 1));
        };
        countdownIntervalRef.current = setInterval(decreaseCountdownSeconds, oneSecondInMs);
      }
    },
    [seconds],
  );

  const stopCountdown = useCallback(() => {
    clearInterval(countdownIntervalRef.current);
    setCountdownSeconds(-1);
  }, []);

  useEffect(() => {
    return () => clearInterval(countdownIntervalRef.current);
  }, []);

  useEffect(() => {
    if (shouldAutoStart) {
      startCountdown();
    }
  }, [shouldAutoStart, startCountdown]);

  return {
    countdownSeconds,
    startCountdown,
    stopCountdown,
    isFinished: countdownSeconds === 0,
    isStopped: countdownSeconds === -1,
  };
};

export default useCountdown;
