import { fromJS } from "immutable";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";
import { emptyMap } from "app/utils/constants";

const initialState = fromJS({
  all: {},
  detailed: {
    recurrence: {},
    recurrenceRule: {},
  },
});

//
// Handler for LOAD_DETAILED_RECURRENCERULE_ASYNC action
//
function handleLoadDetailedRecurrenceRuleAsync(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    const resultJS = action.payload.body;
    const result = fromJS(resultJS);
    state = state.setIn(["detailed", "recurrenceRule"], result);
  }
  return state;
}

//
// Handler for CLEAR_DETAILED_RECURRENCERULE action
//
function handleClearDetailedRecurrenceRule(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state.setIn(["detailed", "recurrenceRule"], emptyMap);
  }

  return state;
}

function reducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_DETAILED_RECURRENCERULE_ASYNC]: handleLoadDetailedRecurrenceRuleAsync,
    [actionTypes.CLEAR_DETAILED_RECURRENCERULE]: handleClearDetailedRecurrenceRule,
  });
}

export default reducer;
