import PropTypes from "prop-types";
import { Component } from "react";
import RedBox from "redbox-react";

import history from "app/history";
import Sentry from "app/core/sentry";
import { getUser, getUserIsInDebugMode, getUserTenant } from "app/features/users/selectors";
import AppErrorFallBack from "./AppErrorFallBack";
import { isNotProduction } from "app/process";

class AppErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidMount() {
    // NOTE this adds a listener to make sure we reset the error immediately on location changes,
    // enabling the user to browse back and forward in the App.
    history.listen((location, action) => {
      if (this.state.error || this.state.errorInfo) {
        this.setState({
          error: null,
          errorInfo: null,
        });
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    this.setState(
      {
        error,
        errorInfo,
      },
      () => this.captureExceptionWithSentry(error, errorInfo),
    );
  }

  captureExceptionWithSentry = (error, errorInfo) => {
    const storeState = this.props.store.getState();
    const user = getUser(storeState);
    const userIsInDebugMode = getUserIsInDebugMode(storeState);
    const tenant = getUserTenant(storeState);
    const userInfo = {
      userIsInDebugMode,
      username: user.get("username"),
      email: user.get("email"),
      tenantName: tenant.get("name"),
      tenantSchema: tenant.get("schema_name"),
    };

    Sentry.withScope((scope) => {
      // Map all errorInfo to Sentry scope.
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      // Set gathered user info to Sentry scope.
      scope.setUser(userInfo);
      Sentry.captureException(error);
    });
  };

  isUserInDebugMode = () => {
    // NOTE that we can't access the AppScrollbarWidthContext or connect the component to redux state,
    // so we use the store directly, which has been passed in via props.
    const storeState = this.props.store.getState();
    return getUserIsInDebugMode(storeState);
  };

  render() {
    const { error, errorInfo } = this.state;
    if (!error) {
      // No error just render the children
      return this.props.children;
    }
    if (isNotProduction) {
      // In development, we show the error with RedBox
      return <RedBox error={error} />;
    }

    // In production, we show a user-friendly error message
    return <AppErrorFallBack error={error} errorInfo={errorInfo} debugMode={this.isUserInDebugMode()} />;
  }
}

AppErrorBoundary.propTypes = {
  store: PropTypes.object.isRequired,
};

export default AppErrorBoundary;
