import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { EuiConfirmModal } from "@elastic/eui";

import history from "app/history";
import useToggleState from "app/common/hooks/useToggleState";
import { emptyFunc, getIconClassName } from "app/utils/constants";

// interface ActionItem {
//     label: string;
//     handler: any;
//     // (optional) icon class. "fa fa-" is prepended automatically
//     icon?: string;
// }
//
// interface ActionItemSeparator {
//     isSeparator: boolean;
// }

const isActionItem = ({ label, handler, href }) => !!label && !!(handler || href);

const PanelActions = ({ actionItems }) => {
  const { value: isDestroyModalVisible, on: openDestroyModal, off: closeDestroyModal } = useToggleState();

  const noActionItems = !actionItems || actionItems.length === 0;
  if (noActionItems) return null;

  return (
    <ListGroup>
      {actionItems.map((actionItem, index) => {
        const {
          isSeparator,
          disabled: isDisabled,
          bsStyle,
          confirm,
          handler,
          href,
          icon,
          iconClassName,
          label,
        } = actionItem;

        // Dividers
        if (isSeparator) {
          return <ListGroupItem key={index} bsClass="list-group-item separator" />;
        }

        // Allowing for custom components in the actionItems list
        if (!isActionItem(actionItem)) {
          return (
            <ListGroupItem key={index} disabled={isDisabled}>
              {actionItem}
            </ListGroupItem>
          );
        }

        let onClick;
        if (isDisabled) {
          onClick = emptyFunc;
        } else if (confirm) {
          onClick = openDestroyModal;
        } else if (href) {
          onClick = (e) => {
            e.preventDefault();
            history.push(href);
          };
        } else {
          onClick = handler;
        }

        const itemHasDestroyModal = isDestroyModalVisible && confirm;
        const destroyWithConfirm = (...callbackArgs) => {
          handler(...callbackArgs);
          closeDestroyModal();
        };
        const destroyModal = itemHasDestroyModal ? (
          <EuiConfirmModal
            key={label}
            title={label}
            onCancel={closeDestroyModal}
            onConfirm={destroyWithConfirm}
            cancelButtonText={<FormattedMessage id={"actions.Cancel"} />}
            confirmButtonText={<FormattedMessage id={"actions.Delete"} />}
            buttonColor="danger"
            defaultFocusedButton="confirm"
          >
            {confirm}
          </EuiConfirmModal>
        ) : null;

        const itemIconClassName = icon ? getIconClassName(icon) : iconClassName;
        const itemContent = (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: 28,
                textAlign: "center",
              }}
            >
              <i className={itemIconClassName} />
            </div>
            <div>{label}</div>
          </div>
        );

        return (
          <Fragment key={index}>
            <ListGroupItem key={index} href={href} bsStyle={bsStyle} onClick={onClick} disabled={isDisabled}>
              {itemContent}
            </ListGroupItem>
            {destroyModal}
          </Fragment>
        );
      })}
    </ListGroup>
  );
};

export default PanelActions;
