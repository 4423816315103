import { emptyMap } from "app/utils/constants";

//
// Combines immutable reducers
//
// Equivalent of redux's combineReducers, but working with reducers that take and return
// Immutable objects.
//
// Args:
//  reducers: { key: reducer } object: associates a reducer function to keys in state
//             each reducer will receive that subtree and be responsible for regenerating it
//  initialState: (optional) immutable object containing initial state
//
function combineImmutableReducers(reducers, initialState = emptyMap) {
  function combinedReducer(state, action) {
    let newState = state || initialState;
    const keys = Object.keys(reducers).sort();
    // Call every reducer in reducers, alphabetically
    keys.forEach((key) => {
      const reducer = reducers[key];
      const oldReducerState = newState.get(key);
      const newReducerState = reducer(oldReducerState, action);
      newState = newState.set(key, newReducerState);
    });
    return newState;
  }

  return combinedReducer;
}

export default combineImmutableReducers;
