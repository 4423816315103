import { createContext, useCallback, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { onIdTokenChanged } from "firebase/auth";

import { useAppActions } from "app/actions";
import useToggleState from "app/common/hooks/useToggleState";
import { customFirebase } from "app/features/auth/firebase";
import { getUserFirebaseUser } from "app/features/users/selectors";
import { useAppTenantContext } from "app/core/providers/AppTenantDetectionProvider";
import { getEmptyPromise } from "app/utils/dal";

const useFirebaseUser = () => {
  const actions = useAppActions();
  const firebaseUser = useSelector(getUserFirebaseUser);
  const setFirebaseUser = actions.users.setFirebaseUser;
  const clearFirebaseUser = actions.users.clearFirebaseUser;
  const signOutFirebaseUser = useCallback(() => {
    clearFirebaseUser();

    if (!firebaseUser) {
      return getEmptyPromise();
    }

    const firebaseAuth = customFirebase.getAuth();
    if (!firebaseAuth) {
      return getEmptyPromise();
    }

    return firebaseAuth.signOut();
  }, [firebaseUser, clearFirebaseUser]);

  return {
    firebaseUser,
    clearFirebaseUser,
    setFirebaseUser,
    signOutFirebaseUser,
  };
};

const FirebaseAuthContext = createContext({});

const FirebaseAuthProvider = ({ children }) => {
  const {
    tenantIdentityProvider,

    isTenantInitialized,
    isTenantWithFirebase,
    isUnknownTenant,
  } = useAppTenantContext();

  const actions = useAppActions();
  const { firebaseUser, clearFirebaseUser, setFirebaseUser, signOutFirebaseUser } = useFirebaseUser();

  // TODO this is buggy still when switching tenants somehow
  // const isLoggingOut = useSelector(getUserIsLoggingOut);
  // const shouldWaitForInitialSessionUser = new InternalDal().hasSession && !isLoggingOut;

  const { value: isWaitingForInitialSessionUser, off: stopWaitForInitialSessionUser } = useToggleState(true);
  const { value: hasFirebaseInitialized, on: startHasFirebaseInitialized } = useToggleState(false);

  const isReadyForInitialSessionUser = isTenantInitialized && isWaitingForInitialSessionUser;
  const isReadyForInitialReloadUserWithToken = isTenantInitialized && hasFirebaseInitialized;
  const isReadyForFirebaseInitialization = isTenantInitialized && !isWaitingForInitialSessionUser;
  const isReadyForFirebaseAuthFlow = isReadyForFirebaseInitialization && hasFirebaseInitialized;

  const initializeFirebase = useCallback(
    (tenantIdentityProvider) => {
      const firebaseAuth = customFirebase.getAuth();
      if (!firebaseAuth) return; // TODO raise?

      const { gcipId: tenantId, tenantSchemaName: schemaName } = tenantIdentityProvider;

      firebaseAuth.tenantId = tenantId;

      const handleIdTokenChange = (user) => {
        if (isTenantWithFirebase) {
          // We check this because we might have an initialized/cached user with the wrong tenantId/token, which is
          // automatically tried.
          const isUserWithCorrectTenantId = user?.tenantId === tenantId;
          if (isUserWithCorrectTenantId) {
            const { uid: userId, accessToken } = user;

            const firebaseUser = {
              accessToken,
              tenantId,
              schemaName,
              user,
              userId,
            };

            setFirebaseUser(firebaseUser);
          } else {
            clearFirebaseUser();
          }
        }

        startHasFirebaseInitialized();
      };

      // TODO
      //  - how to properly handle multiple tenants here? Do we need to have multiple firebaseAuth instances OR how do
      //  we deal with multiple tenants?

      // We use onIdTokenChanged instead of onAuthStateChanged to handle expiring tokens as well.
      // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#onidtokenchanged
      onIdTokenChanged(firebaseAuth, handleIdTokenChange);
    },
    [isTenantWithFirebase, startHasFirebaseInitialized, clearFirebaseUser, setFirebaseUser],
  );

  useEffect(() => {
    if (isReadyForInitialSessionUser) {
      actions.users.getUser(tenantIdentityProvider.tenantSchemaName).payload.finally(stopWaitForInitialSessionUser);
    }
  }, [actions, tenantIdentityProvider, stopWaitForInitialSessionUser, isReadyForInitialSessionUser]);

  useEffect(() => {
    if (isReadyForFirebaseInitialization) {
      initializeFirebase(tenantIdentityProvider);
    }
  }, [tenantIdentityProvider, isReadyForFirebaseInitialization, initializeFirebase]);

  const contextValue = {
    tenantIdentityProvider,
    isTenantWithFirebase,
    isTenantInitialized,
    isUnknownTenant,

    isReadyForFirebaseInitialization,
    isReadyForFirebaseAuthFlow,
    isReadyForInitialSessionUser,
    isReadyForInitialReloadUserWithToken,

    firebaseUser,
    signOutFirebaseUser,
  };

  return <FirebaseAuthContext.Provider value={contextValue}>{children}</FirebaseAuthContext.Provider>;
};

// TODO wrap with context checks
export const useFirebaseAuthContext = () => useContext(FirebaseAuthContext);

export default FirebaseAuthProvider;
