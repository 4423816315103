import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserIsLoaded } from "app/features/users/selectors";
import { useRedirectIfLoggedIn } from "app/features/auth/containers/LogInContainer";
import BusyFeedbackPage from "app/common/components/BusyFeedbackPage";
import FirebaseAuthFlow from "app/features/auth/components/FirebaseAuthFlow";

const FirebaseAuthFlowContainer = () => {
  const { tenantId: tenantSchemaName } = useParams();

  const isLoggedIn = useSelector(getUserIsLoaded);
  const { redirectUrl } = useRedirectIfLoggedIn({
    tenantSchemaName,
    canRedirect: true,
  });

  // If isLoggedIn then a replace of the url is triggered, show BusyFeedbackPage until this has happened.
  // The regular Login also waits for the initial session user here, the FirebaseAuthFlow component handles that case.
  // TODO we might want to rewrite the logic such that it is handled similar in both cases.
  if (isLoggedIn) return <BusyFeedbackPage />;

  return <FirebaseAuthFlow redirectUrl={redirectUrl} />;
};

export default FirebaseAuthFlowContainer;
