import SnapshotDal from "./dal";

export const actionTypes = {
  LOAD_LAST_COMPLETED_SNAPSHOT: "snapshots/LOAD_LAST_COMPLETED_SNAPSHOT",
  POLL_LAST_COMPLETED_SNAPSHOT: "snapshots/POLL_LAST_COMPLETED_SNAPSHOT",
};

const actionCreators = {
  pollLastCompletedSnapshot(snapshotScopePk) {
    const dal = new SnapshotDal();
    const actionPromise = dal.pollLastCompletedSnapshot(snapshotScopePk);
    return {
      type: actionTypes.POLL_LAST_COMPLETED_SNAPSHOT,
      payload: actionPromise,
    };
  },

  loadLastCompletedSnapshot(snapshotScopePk) {
    const dal = new SnapshotDal();
    const actionPromise = dal.loadLastCompletedSnapshot(snapshotScopePk);
    return {
      type: actionTypes.LOAD_LAST_COMPLETED_SNAPSHOT,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
