import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { useTenantAwareWebSocket } from "app/features/webSockets/hooks";

const WebSocketContext = createContext({});

const WebSocketProvider = ({ children }) => {
  const {
    lastMessageReceived,
    lastMessageSent,
    lastConnected,
    messagesReceived,
    messagesSent,
    clearMessages,
    sendMessage,
    wsState,
    connectToWs,
    isConnected,
    enableWs,
    disableWs,
    isDisabled,
  } = useTenantAwareWebSocket();

  const contextValue = useMemo(
    () => ({
      lastMessageReceived,
      lastMessageSent,
      lastConnected,
      messagesReceived,
      messagesSent,
      clearMessages,
      sendMessage,
      wsState,
      connectToWs,
      isConnected,
      enableWs,
      disableWs,
      isDisabled,
    }),
    [
      lastMessageReceived,
      lastMessageSent,
      lastConnected,
      messagesReceived,
      messagesSent,
      clearMessages,
      sendMessage,
      wsState,
      connectToWs,
      isConnected,
      enableWs,
      disableWs,
      isDisabled,
    ],
  );

  return <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>;
};

export const useHandleLastReceivedMessage = ({
  onReceivedMessage,
  onConnect,
  onReconnect,
  messageFilter,
  isDisabled = false,
}) => {
  const { lastMessageReceived, lastConnected } = useWebSocketContext();

  const lastMessageReceivedUuidRef = useRef(null);
  const lastConnectedRef = useRef(null);
  if (isDisabled) {
    lastConnectedRef.current = null;
  }

  useEffect(() => {
    if (isDisabled) return;

    const prevLastConnected = lastConnectedRef.current;
    lastConnectedRef.current = lastConnected;

    if (!prevLastConnected) {
      onConnect?.();
    } else if (lastConnected !== prevLastConnected) {
      onReconnect?.();
    } else if (
      lastMessageReceived &&
      lastMessageReceived.uuid !== lastMessageReceivedUuidRef.current &&
      messageFilter(lastMessageReceived)
    ) {
      lastMessageReceivedUuidRef.current = lastMessageReceived.uuid;
      onReceivedMessage?.(lastMessageReceived);
    }
  }, [isDisabled, lastConnected, lastMessageReceived, onConnect, onReconnect, onReceivedMessage, messageFilter]);
};

// TODO check for undefined like other contexts
export const useWebSocketContext = () => useContext(WebSocketContext);

export default WebSocketProvider;
