import moment from "moment";

require("moment/locale/nl");

const { locale } = window;

if (locale) {
  moment.locale(locale);
}

export const dateFormat = "YYYY-MM-DD";
export const timeFormat = "HH:mm";
export const datetimeFormat = `${dateFormat} ${timeFormat}`;

export const momentOrNull = (m) => (m ? moment(m) : null);

export const getMidnightAtOrBefore = (m) => moment(m).startOf("day");
export const getMidnightAtOrAfter = (m) => {
  // Correction because .endOfDay("day") results in a 23:59:59.999 time
  const end = moment(m);
  let midnight = moment(m).startOf("day");
  if (midnight.isBefore(end)) {
    midnight = midnight.add(1, "day");
  }
  return midnight;
};

export const setTimeFromMoment = (m1, m2) =>
  moment(m1).set({
    hour: m2.hour(),
    minute: m2.minute(),
    second: 0,
  });
export const setDateFromMoment = (m1, m2) =>
  moment(m1).set({
    year: m2.year(),
    month: m2.month(),
    date: m2.date(),
  });

// DRF output
const dtRegEx1 =
  /\d\d\d\d-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01]) (00|0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-5][0-9])\+(00|0[0-9]|1[0-9]|2[0-3]):(00|[0-9]|[0-5][0-9])/g;
// Python format
const dtRegEx2 =
  /\d\d\d\d-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])T(00|0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-5][0-9])Z/g;

// TODO use momentCache here
export const replaceDatetimesWithMomentsFormatted = (s, f = "LLL") =>
  s ? s.replace(dtRegEx1, (m) => moment(m).format(f)).replace(dtRegEx2, (m) => moment(m).format(f)) : s;

export const duration5Minutes = moment.duration(5, "minutes");

export const roundMoment = (m, duration = duration5Minutes, method = "round") =>
  moment(Math[method](+m / +duration) * +duration);

export default moment;
