const workPrefix = "manage/work";

export const manageAssignmentsUrl = `${workPrefix}/assignments`;
export const manageProjectsUrl = `${workPrefix}/projects`;
export const manageWorksUrl = `${workPrefix}/works`;
export const manageImportancesUrl = `${workPrefix}/importances`;

export const newAssignmentUrl = `${manageAssignmentsUrl}/new`;
export const newWorkUrl = `${manageWorksUrl}/new`;
export const newWorkTemplateUrl = `${newWorkUrl}?state=template`;
export const newProjectUrl = `${manageProjectsUrl}/new`;
export const newProjectTemplateUrl = `${newProjectUrl}?state=template`;

export const manageAssignmentTemplatesUrl = `${manageAssignmentsUrl}/templates`;
export const manageWorkTemplatesUrl = `${manageWorksUrl}/templates`;
export const manageProjectTemplatesUrl = `${manageProjectsUrl}/templates`;

export const importWorkUrl = `${workPrefix}/import`;

// TODO
//  - factor this out for other item-types too!
//  - third arg should be something like opts: { tab: null, edit: false } etc.
const getItemUrl = (manageItemsUrl, pk, tab = null) => {
  let url = `${manageItemsUrl}/${pk}`;
  if (tab) url = `${url}/${tab}`;
  return url;
};

export const WORK_TABS = {
  AVAILABILITY: "availability",
  ASSIGNMENTS: "assignments",
  CLAIMS: "resources-claims",
  CONDITIONALS: "conditionals",
  NETWORK: "network",
  REQUIREMENTS: "requirements",
  RULES: "rules",
  SAME_RESOURCES: "same-resources",
  SPANS: "spans",
  STOCK_REQUIREMENTS: "stock-requirements",
};

export const getAssignmentTemplateUrl = (pk, tab = null) => getItemUrl(manageAssignmentTemplatesUrl, pk, tab);
export const getAssignmentUrl = (pk, tab = null) => getItemUrl(manageAssignmentsUrl, pk, tab);

export const getProjectUrl = (pk, tab = null) => getItemUrl(manageProjectsUrl, pk, tab);
export const getWorkUrl = (pk, tab = null) => getItemUrl(manageWorksUrl, pk, tab);

export const getWorkEditUrl = (pk) => getItemUrl(manageWorksUrl, pk, "edit");
