import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { fromJS } from "immutable";
import { arrayToPkMap } from "app/utils/immutableUtils";
import { AssignmentDetailedRecord, ProjectSimpleRecord, WorkSimpleRecord } from "app/utils/records";
import { isLoadingMap, ItemTypes } from "app/utils/constants";
import { actionTypes } from "./actions";

const initialState = fromJS({
  worksRealised: {},
  projectsRealised: {},
  assignmentsRealised: {},
  assignmentsNotRealised: {},
});

function handleLoadWorksRealised(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("worksRealised", isLoadingMap);
  }
  if (action.status === "done") {
    const worksMap = arrayToPkMap(action.payload.body.results, ItemTypes.work).map(WorkSimpleRecord);
    state = state.set("worksRealised", worksMap);
  }
  return state;
}

function handleLoadProjectsRealised(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("projectsRealised", isLoadingMap);
  }
  if (action.status === "done") {
    const worksMap = arrayToPkMap(action.payload.body.results, ItemTypes.project).map(ProjectSimpleRecord);
    state = state.set("projectsRealised", worksMap);
  }
  return state;
}

function handleLoadAssignmentsRealised(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("assignmentsRealised", isLoadingMap);
  }
  if (action.status === "done") {
    const worksMap = arrayToPkMap(action.payload.body.results, ItemTypes.assignment).map(AssignmentDetailedRecord);
    state = state.set("assignmentsRealised", worksMap);
  }
  return state;
}

function handleLoadAssignmentsNotRealised(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("assignmentsNotRealised", isLoadingMap);
  }
  if (action.status === "done") {
    const worksMap = arrayToPkMap(action.payload.body.results, ItemTypes.assignment).map(AssignmentDetailedRecord);
    state = state.set("assignmentsNotRealised", worksMap);
  }
  return state;
}

//
// Reducer
//

function dashboardReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_WORKS_REALISED]: handleLoadWorksRealised,
    [actionTypes.LOAD_PROJECTS_REALISED]: handleLoadProjectsRealised,
    [actionTypes.LOAD_ASSIGNMENTS_REALISED]: handleLoadAssignmentsRealised,
    [actionTypes.LOAD_ASSIGNMENTS_NOT_REALISED]: handleLoadAssignmentsNotRealised,
  });
}

export default dashboardReducer;
