import { fromJS } from "immutable";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";

const initialState = fromJS({
  all: notLoadedMap,
  detailed: notLoadedMap,
});

function handleLoadDetailedRequirementAsync(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    const reqJS = action.payload.body;
    state = state.set("detailed", fromJS(reqJS));
  }
  return state;
}

function handleClearDetailedRequirement(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.setIn("detailed", notLoadedMap);
  }
  return state;
}

function requirementsReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_DETAILED_REQUIREMENT_ASYNC]: handleLoadDetailedRequirementAsync,
    [actionTypes.CLEAR_DETAILED_REQUIREMENT]: handleClearDetailedRequirement,
  });
}

export default requirementsReducer;
