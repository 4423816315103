import { useCallback, useState } from "react";

// TODO rename to useToggle
export default (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const on = useCallback(() => setValue(true), []);
  const toggle = useCallback(() => setValue((s) => !s), []);
  const off = useCallback(() => setValue(false), []);

  return {
    value,
    on,
    toggle,
    off,
  };
};
