import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fromJS } from "immutable";

import TenantDal from "app/features/tenants/dal";
import { getUserIsLoaded } from "app/features/users/selectors";
import { isMapNotReady, notLoadedMap } from "app/utils/constants";
import moment from "app/utils/momentLocalized";

const AppUserTenantsContext = createContext({});

const AppUserTenantsProvider = ({ children }) => {
  const [tenants, setTenants] = useState(notLoadedMap);
  const [tenantsLoadedAt, setTenantsLoadedAt] = useState(null);

  const userIsLoaded = useSelector(getUserIsLoaded);
  const isNotReady = isMapNotReady(tenants);
  const isReady = !isNotReady;

  const loadTenants = useCallback(() => {
    new TenantDal().getTenants().then((response) => {
      setTenants(fromJS(response.body.results));
      setTenantsLoadedAt(moment());
    });
  }, []);

  useEffect(() => {
    if (userIsLoaded) {
      loadTenants();
    } else {
      setTenants(notLoadedMap);
    }
  }, [userIsLoaded, loadTenants]);

  const contextValue = {
    tenants,
    tenantsLoadedAt,
    isNotReady,
    hasNoTenants: isReady && tenants.size === 0,
    hasPublicTenant: isReady && tenants.find((t) => t.get("schema_name") === "public"),
    hasMultipleTenants: isReady && tenants.size > 1,
    loadTenants,
  };

  return <AppUserTenantsContext.Provider value={contextValue}>{children}</AppUserTenantsContext.Provider>;
};
export const useAppUserTenantsContext = () => useContext(AppUserTenantsContext);

export default AppUserTenantsProvider;
