import { Record } from "immutable";
import { intervalTypeValues } from "app/features/planBoard/constants";
import moment from "app/utils/momentLocalized";
import intervalTypeForMoment from "app/utils/intervalTypeForMoment";

const isSameMoment = (m1, m2) => {
  if (!m1) return !m2;
  if (!m2) return !m1;
  return m1.isSame(m2);
};

export const isSameTimelineView = (v1, v2) => {
  if (!v1) return !v2;
  if (!v2) return !v1;

  return (
    v1.intervalType === v2.intervalType &&
    isSameMoment(v1.startDate, v2.startDate) &&
    isSameMoment(v1.endDate, v2.endDate) &&
    isSameMoment(v1.currentDate, v2.currentDate)
  );
};

// TODO we might want to do more validation checks here
export const isSettableTimelineView = (v) => v && v.intervalType && v.currentDate;

export const timelineViewFromIntervalTypeCurrentDateString = (intervalType, currentDateString) => {
  if (intervalType && intervalTypeValues.indexOf(intervalType) === -1) {
    console.log(`Invalid interval type (${intervalType}), redirecting to default...`);
    intervalType = null;
  }

  let currentDate;
  let startDate;
  let endDate;
  if (currentDateString) {
    if (intervalType === "custom") {
      const [startDateString, endDateString] = currentDateString.split(",");
      startDate = moment(startDateString);
      endDate = moment(endDateString);
      currentDate = moment(startDate);

      if (!startDate.isValid() || !endDate.isValid()) {
        console.log(`Invalid custom range (${currentDateString}), redirecting to default view...`);
        currentDate = null;
      }
    } else {
      currentDate = moment(currentDateString);
      if (!currentDate.isValid()) {
        console.log(`Invalid current date (${currentDateString}), redirecting to default date...`);
        currentDate = null;
      }
    }
  }

  if (!currentDate || !intervalType) {
    // Not all information is present but we return the params anyway.
    return TimelineViewRecord({
      currentDate,
      intervalType,
    });
  }

  // Now we know the view will be valid. If we have a non-custom interval we still need to set startDate and endDate.
  if (intervalType !== "custom") {
    const momentIntervalType = intervalTypeForMoment(intervalType);
    startDate = moment(currentDate).startOf(momentIntervalType);

    // Moment doesn't know about workweeks, so we set the endDate to Friday manually
    if (intervalType === "workweek") {
      endDate = moment(startDate).isoWeekday(6).startOf("day");
    } else {
      endDate = moment(startDate).add(1, momentIntervalType).startOf("day");
    }
  }

  return TimelineViewRecord({
    currentDate,
    startDate,
    endDate,
    intervalType,
  });
};

export const isValidTimelineView = (planBoardView) => {
  if (!planBoardView) return false;

  const { currentDate, startDate, endDate, intervalType } = planBoardView;
  return currentDate && startDate && endDate && intervalType;
};

export const TimelineViewRecord = Record({
  currentDate: null,
  startDate: null,
  endDate: null,
  intervalType: null,
});
