import { fromJS } from "immutable";

import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";

import { actionTypes } from "./actions";
import { emptyMap, isLoadingMap, notLoadedMap } from "app/utils/constants";

const initialCalculationsState = fromJS({
  // TODO restructure review: {results, iterationNumber etc.}
  review: notLoadedMap,
  calc_out: notLoadedMap,
  iterations: notLoadedMap,
  stats: notLoadedMap,
});

const initialState = fromJS({
  calculation: initialCalculationsState,
  plannedIsCapableTeam: notLoadedMap,
  realisedIsCapableTeam: notLoadedMap,
  fragments: notLoadedMap,
});

function handleReviewCalculation(prevState, action) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.setIn(["calculation", "review"], isLoadingMap);
  }

  if (action.status === "done") {
    const reviewJS = action.payload.body.results;
    state = state.setIn(["calculation", "review"], fromJS(reviewJS));
  }

  return state;
}

function handleReviewIterations(prevState, action) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.setIn(["calculation", "iterations"], isLoadingMap);
  }

  if (action.status === "done") {
    const iterationsJS = action.payload.body.results;
    state = state.setIn(["calculation", "iterations"], fromJS(iterationsJS));
  }

  return state;
}

function handleReviewOut(prevState, action) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.setIn(["calculation", "calc_out"], isLoadingMap);
  }

  if (action.status === "done") {
    const statsJS = action.payload.body.results;
    state = state.setIn(["calculation", "calc_out"], fromJS(statsJS));
  }

  return state;
}

function handleReviewStats(prevState, action) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.setIn(["calculation", "stats"], isLoadingMap);
  }

  if (action.status === "done") {
    const statsJS = action.payload.body.results;
    state = state.setIn(["calculation", "stats"], fromJS(statsJS));
  }

  return state;
}

function handleClearCalculationPreview(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state.setIn(["calculation", "preview"], emptyMap);
  }

  return state;
}

function handleClearCalculationResults(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state
      .setIn(["calculation", "review"], notLoadedMap)
      .setIn(["calculation", "iterations"], notLoadedMap)
      .setIn(["calculation", "stats"], notLoadedMap)
      .setIn(["calculation", "maxIterationNumber"], 1);
  }

  return state;
}

function handleIsCapableTeam(prevState, action, base) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.set(base, isLoadingMap);
  }

  if (action.status === "done") {
    const resultsJS = action.payload.body.results;
    state = state.set(base, fromJS(resultsJS));
  }

  return state;
}

function handlePlannedIsCapableTeam(prevState, action) {
  return handleIsCapableTeam(prevState, action, "plannedIsCapableTeam");
}

function handleRealisedIsCapableTeam(prevState, action) {
  return handleIsCapableTeam(prevState, action, "realisedIsCapableTeam");
}

function handleDetermineFragments(prevState, action) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.set("fragments", isLoadingMap);
  }

  if (action.status === "done") {
    const resultsJS = action.payload.body.results;
    state = state.set("fragments", fromJS(resultsJS));
  }

  return state;
}

function handleClearFragments(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state.set("fragments", emptyMap);
  }

  return state;
}

//
// Plan tools reducer
//
function planToolsReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.REVIEW_CALCULATION]: handleReviewCalculation,
    [actionTypes.REVIEW_CALCULATION_ITERATIONS]: handleReviewIterations,
    [actionTypes.REVIEW_CALCULATION_OUT]: handleReviewOut,
    [actionTypes.REVIEW_CALCULATION_STATS]: handleReviewStats,

    [actionTypes.CLEAR_CALCULATION_REVIEW]: handleClearCalculationResults,
    [actionTypes.CLEAR_CALCULATION_PREVIEW]: handleClearCalculationPreview,

    [actionTypes.PLANNED_IS_CAPABLE_TEAM]: handlePlannedIsCapableTeam,
    [actionTypes.REALISED_IS_CAPABLE_TEAM]: handleRealisedIsCapableTeam,
    [actionTypes.DETERMINE_FRAGMENTS]: handleDetermineFragments,
    [actionTypes.CLEAR_FRAGMENTS]: handleClearFragments,
  });
}

export default planToolsReducer;
