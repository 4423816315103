import { fromJS } from "immutable";
import { arrayToPkMap } from "app/utils/immutableUtils";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";

const initialState = fromJS({
  all: notLoadedMap,
  detailed: notLoadedMap,
});

function handleLoadSimpleLabels(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("all", isLoadingMap);
  }
  if (action.status === "done") {
    const labelsJS = action.payload.body.results;
    const labelsMap = arrayToPkMap(labelsJS, "label");
    state = state.set("all", labelsMap);
  }
  return state;
}

function handleClearSimpleLabels(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("all", notLoadedMap);
  }
  return state;
}

function handleLoadDetailedLabel(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    const labelJS = action.payload.body;
    const labelMap = fromJS(labelJS);
    state = state.set("detailed", labelMap);
  }
  return state;
}

function handleClearDetailedLabel(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("detailed", notLoadedMap);
  }
  return state;
}

function labelsReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_SIMPLE_LABELS]: handleLoadSimpleLabels,
    [actionTypes.CLEAR_SIMPLE_LABELS]: handleClearSimpleLabels,
    [actionTypes.LOAD_DETAILED_LABEL]: handleLoadDetailedLabel,
    [actionTypes.CLEAR_DETAILED_LABEL]: handleClearDetailedLabel,
  });
}

export default labelsReducer;
