import InternalDal from "app/utils/InternalDal";

class SkillsDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.skillsUrl = `${this.dal.baseTenantUrl}/skills`;
  }

  getSimpleSkills() {
    const url = `${this.skillsUrl}/`;

    return this.dal.getter(url, "getSimpleSkills");
  }

  getDetailedSkill(skillPk) {
    const url = `${this.skillsUrl}/detailed/${skillPk}/`;

    return this.dal.getter(url, "getDetailedSkill");
  }

  getSkillWorkTemplates(skillPk) {
    const url = `${this.skillsUrl}/${skillPk}/work_templates`;

    return this.dal.getter(url, "getSkillWorkTemplates");
  }

  createSkill(skill) {
    const url = `${this.skillsUrl}/`;

    return this.dal.poster(url, skill, "createSkill");
  }

  putSkill(skill) {
    const url = `${this.skillsUrl}/${skill.pk}/`;

    return this.dal.putter(url, skill, "putSkill");
  }

  deleteSkill(skillPk) {
    const url = `${this.skillsUrl}/${skillPk}/`;

    return this.dal.deleter(url, "deleteSkill");
  }

  patchSkillResources(skillPk, resourcePks) {
    const url = `${this.skillsUrl}/detailed/${skillPk}/`;
    const data = { resources: resourcePks };

    return this.dal.patcher(url, data, "patchSkillResources");
  }
}

export default SkillsDal;
