import { initializeApp } from "firebase/app";
import { EmailAuthProvider, getAuth } from "firebase/auth";
import * as firebaseUI from "firebaseui";

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
};
const firebaseApp = initializeApp(firebaseConfig);
const getFirebaseAuth = () => getAuth(firebaseApp);
const isConfiguredProperly = firebaseConfig.apiKey && firebaseConfig.authDomain;

export const customFirebase = {
  getAuth: () => {
    throw "Firebase not configured.";
  },
  getAuthUI: () => {
    throw "Firebase not configured.";
  },
  configAuthUI: {},
};

export const firebaseUIDivId = "firebaseui-auth-container";
export const firebaseInitialRedirectSeconds = 5;
export const firebaseForceAuthDomain = process.env.FIREBASE_FORCE_AUTH_DOMAIN === "1";

export const initialiseFirebaseAuth = () => {
  if (isConfiguredProperly) {
    customFirebase.getAuth = getFirebaseAuth;
    customFirebase.getAuthUI = () => {
      const authUI = firebaseUI.auth.AuthUI;
      return authUI.getInstance() || new firebaseUI.auth.AuthUI(getFirebaseAuth());
    };

    // TODO
    //  - determine how to fill these settings
    //  - probably best to just create this flow ourselves! (we shouldn't need the firebaseui package)
    customFirebase.configAuthUI = {
      adminRestrictedOperation: true,
      signInOptions: [
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          disableSignUp: { status: true },
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          // This callback is necessary or we should provide a signInSuccessUrl explicitly.
          return false;
        },
      },

      // 'credentialHelper': 'none',
      // 'signInFlow': 'popup',
      //
      // // TODO fill these or remove these?
      // // tosUrl and privacyPolicyUrl accept either url string or a callback
      // // function.
      // // Terms of service url/callback.
      // tosUrl: nullFunc,
      // // Privacy policy url/callback.
      // privacyPolicyUrl: nullFunc,
    };
  }
};
