export const manageBlocksUrl = "manage/blocks";
export const getBlockDetailsUrl = (pk) => `${manageBlocksUrl}/${pk}`;
export const getBlockEditUrl = (pk) => `${manageBlocksUrl}/${pk}/edit`;
export const getBlockStartEventNewUrl = (pk) => `${getBlockDetailsUrl(pk)}/events/start/new`;
export const getBlockStartEventDetailsUrl = (pk, eventPk) => `${getBlockDetailsUrl(pk)}/events/start/${eventPk}`;

export const getBlockConditionalEventNewUrl = (pk) => `${getBlockDetailsUrl(pk)}/events/conditional/new`;
export const getBlockConditionalEventDetailsUrl = (pk, eventPk) =>
  `${getBlockDetailsUrl(pk)}/events/conditional/${eventPk}`;

export const getBlockEndEventNewUrl = (pk) => `${getBlockDetailsUrl(pk)}/events/end/new`;

export const newBlockUrl = `${manageBlocksUrl}/new`;
