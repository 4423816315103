import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import superagent from "superagent";

import store from "app/store";
import { requestToPromise } from "app/utils/dal";
import { getUserFirebaseUser, getUserTenantSchemaName } from "app/features/users/selectors";

const defaultApiUrl = "/api";
const isNotTenantSchemaName = (schemaName) => !schemaName || schemaName === "" || schemaName === "public";

export const makeUrlTenantAware = (url, schemaName, apiUrl = defaultApiUrl) => {
  const baseUrl = isNotTenantSchemaName(schemaName) ? `${apiUrl}/internal` : `${apiUrl}/internal/${schemaName}`;
  return url === "" ? baseUrl : `${baseUrl}/${url}`;
};

export const useTenantAwareInternalApiUrl = (url, apiUrl = defaultApiUrl) => {
  const schemaName = useSelector(getUserTenantSchemaName);
  return makeUrlTenantAware(url, schemaName, apiUrl);
};

class InternalDal {
  constructor(apiUrl = defaultApiUrl) {
    const reduxState = store.getState();

    // Urls
    this.apiUrl = apiUrl;
    this.tenantSchemaName = getUserTenantSchemaName(reduxState);
    this.basePublicUrl = apiUrl;
    this.baseTenantUrl = this.makeTenantAwareApiUrl("");

    // Auth
    const firebaseUser = getUserFirebaseUser(reduxState);
    if (firebaseUser) {
      const { accessToken, schemaName } = firebaseUser;
      this.authHeader = `Bearer ${accessToken}`;
      this.authSchemaName = schemaName;
    }
    this.hasFirebaseUser = !!firebaseUser;
    this.hasFirebaseUserOrSession = this.hasFirebaseUser || this.hasSession;
    this.hasSession = Cookies.get("sessionisactive") === "1";

    // CSRF protection
    this.csrfToken = Cookies.get("csrftoken");
  }

  makeTenantAwareApiUrl(url, schemaName = this.tenantSchemaName) {
    return makeUrlTenantAware(url, schemaName, this.apiUrl);
  }

  makePublicUrl(url, schemaName = this.authSchemaName) {
    // If we are using authTokens through firebase then the user needs to be authenticated based on the schemaName.
    // Currently we handle this by having exposed auth endpoints under /{tenant}/... as well.
    // TODO
    //  - we should be able to handle this more elegantly
    //  - it's ugly that basePublicUrl does not have an apiVersion ('internal', 'v1', ...)
    return isNotTenantSchemaName(schemaName)
      ? `${this.basePublicUrl}/${url}/`
      : makeUrlTenantAware(url, schemaName, this.apiUrl);
  }

  setHeaders(request, setAuthHeader = true) {
    request = request.set("X-CSRFToken", this.csrfToken);
    if (setAuthHeader && this.authHeader) {
      request = request.set("Authorization", this.authHeader);
    }
    return request;
  }

  getter(url, reqName) {
    let request = superagent.get(url);
    request = this.setHeaders(request);
    return requestToPromise(request, reqName);
  }

  putter(url, data, reqName) {
    let request = superagent.put(url);
    request = this.setHeaders(request);
    request = request.send(data);
    return requestToPromise(request, reqName);
  }

  poster(url, data, reqName, blob = false, setAuthHeader = true) {
    // TODO blob, setAuthHeader should be part of some "opts" object
    let request = superagent.post(url);
    request = this.setHeaders(request, setAuthHeader);
    request = request.send(data);
    if (blob) {
      request = request.responseType("blob");
    }
    return requestToPromise(request, reqName);
  }

  deleter(url, reqName) {
    let request = superagent.del(url);
    request = this.setHeaders(request);
    return requestToPromise(request, reqName);
  }

  patcher(url, data, reqName) {
    let request = superagent.patch(url);
    request = this.setHeaders(request);
    request = request.send(data);
    return requestToPromise(request, reqName);
  }
}

export default InternalDal;
