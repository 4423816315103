import { Component } from "react";

class HelpBlock extends Component {
  render() {
    const { text } = this.props;
    if (!text || text.length === 0) return null;
    return (
      <span className="help-block">
        <i className="fa fa-info-circle" />
        {text}
      </span>
    );
  }
}

export default HelpBlock;
