import { fromJS } from "immutable";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "../actions";
import { emptyMap, isLoadingMap } from "app/utils/constants";

const initialState = fromJS({
  detailed: {},
});

function handleLoadDetailedWorksSameResource(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    const worksSameResourceJS = action.payload.body;
    state = state.set("detailed", fromJS(worksSameResourceJS));
  }
  return state;
}

function handleClearDetailedWorksSameResource(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state.set("detailed", emptyMap);
  }

  return state;
}

function worksSameResourcesReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_DETAILED_WORKS_SAME_RESOURCE]: handleLoadDetailedWorksSameResource,
    [actionTypes.CLEAR_DETAILED_WORKS_SAME_RESOURCE]: handleClearDetailedWorksSameResource,
  });
}

export default worksSameResourcesReducer;
