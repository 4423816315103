import { List } from "immutable";
import PropTypes from "prop-types";
import { Component } from "react";
import { emptyList } from "app/utils/constants";

//
// Error list for forms and fields
//
class ErrorList extends Component {
  render() {
    const { errors } = this.props;
    if (!errors || !errors.count()) return null;

    return (
      <div className="error-list">
        {errors.map((e) => (
          <span key={e} className="help-block">
            {e}
          </span>
        ))}
      </div>
    );
  }
}

ErrorList.propTypes = {
  errors: PropTypes.instanceOf(List),
};

ErrorList.defaultProps = {
  errors: emptyList,
};

export default ErrorList;
