import { useEffect, useState } from "react";

// Only runs on mount because we pass an empty array of inputs that trigger another call.
export const useMountEffect = (fun) => useEffect(fun, []);

export const useMountTimeOut = (timeout = 10) => {
  const [mounted, setMounted] = useState(false);
  useMountEffect(() => {
    setTimeout(() => setMounted(true), timeout);
  });

  return mounted;
};
