import moment from "app/utils/momentLocalized";
import { useEffect, useRef } from "react";
import { is } from "immutable";

// TODO make sure there's only one clear way of getting a pk from an item
export const getAssignmentWorkPk = (a) => a.get("work_pk") ?? a.getIn(["work", "pk"]) ?? a.get("work");

export const getResourceBreedPk = (r) => r.get("breed_pk") ?? r.getIn(["breed", "pk"]) ?? r.get("breed");

export const getDateRangeKey = (start, end) => `${start}-${end}`;

export const momentWithCache = (d, cache) => {
  // d is either a string / Date etc.
  let m = cache[d];
  if (!m) {
    m = moment(d);
    cache[d] = m;
  }
  return m;
};

export const setItemStartEndMoments = (item, momentCache) => {
  item = item.toMap();

  const setStart = !item.get("startMoment");
  const setEnd = !item.get("endMoment");
  if (!(setStart || setEnd)) return item;

  if (setStart) {
    const start = item.get("start");
    const startMoment = momentCache ? momentWithCache(start, momentCache) : moment(start);
    item = item.set("startMoment", startMoment);
  }
  if (setEnd) {
    const end = item.get("end");
    const endMoment = momentCache ? momentWithCache(end, momentCache) : moment(end);
    item = item.set("endMoment", endMoment);
  }

  return item;
};

export const useImmutableCached = (immutable) => {
  const ref = useRef(immutable);
  const prevImmutable = ref.current;
  const changed = !is(prevImmutable, immutable);

  useEffect(() => {
    if (changed) {
      ref.current = immutable;
    }
  }, [immutable, changed]);

  return changed ? immutable : prevImmutable;
};

export const useMomentCached = (m) => {
  const ref = useRef(null);
  const prevMoment = ref.current;
  const nextMoment = moment(m);
  const changed = !nextMoment.isSame(prevMoment);

  useEffect(() => {
    // Only update refs on actual changes!
    if (changed) {
      ref.current = nextMoment;
    }
  }, [changed, nextMoment]);

  return changed ? nextMoment : prevMoment;
};
