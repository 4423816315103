import { memo } from "react";

import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from "@elastic/eui";

import { SnapshotScopeManager } from "app/features/snapshots/components/SnapshotScopeManager";
import { CustomIconWithLabel, Icons } from "app/utils/constants";
import HelpIconTip from "app/common/components/HelpIconTip";
import TenantUrlLink from "app/features/tenants/components/TenantUrlLink";

const ManageSnapshotScopesLink = () => (
  <TenantUrlLink url={"snapshots/scopes"}>
    <CustomIconWithLabel icon={Icons.manage} label="Beheer scopes" />
  </TenantUrlLink>
);

// TODO add translations, provide a nicer interface
const SnapshotScopeHelpText = () => (
  <>
    Hier kan een scope ingesteld worden om een uitsnede van de planning te maken. Een kleinere scope leidt tot snellere
    berekeningen en validatie op het planboard. Wijzigingen op het planboard worden per scope bijgehouden, hierdoor
    kunnen meerdere scopes aangemaakt worden met afzonderlijke openstaande wijzigingen.
  </>
);

const SnapshotScopeModal = ({
  onCloseModal,
  // minDate,
  // maxDate,
  // startDate,
  // endDate,
}) => {
  return (
    <EuiOverlayMask>
      <EuiModal onClose={onCloseModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <>
              Scope&nbsp;wijzigen&nbsp;
              <HelpIconTip content={<SnapshotScopeHelpText />} />
            </>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <ManageSnapshotScopesLink />
          <br />
          <br />
          <SnapshotScopeManager canEdit={false} />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={onCloseModal}>Close</EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  );
};

export default memo(SnapshotScopeModal);
