import { useState } from "react";

import { getIconClassName } from "app/utils/constants";
import history from "app/history";
import { EuiButton, EuiConfirmModal, EuiSwitch } from "@elastic/eui";
import { euiColorMap } from "app/common/pages/ElasticUIListPage";
import { FormattedMessage } from "react-intl";

const buttonStyle = {};

const switchStyle = {
  marginBottom: 10,
  marginLeft: "auto",
};

const panelActionStyle = {
  display: "flex",
};

const ElasticUIPanelActions = ({ actionItems }) => {
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);
  const closeDestroyModal = () => setIsDestroyModalVisible(false);
  const showDestroyModal = () => {
    setIsDestroyModalVisible(true);
  };

  let destroyModal;

  if (!actionItems || actionItems.length === 0) return null;

  return (
    <div style={panelActionStyle}>
      {actionItems.map((item, index) => {
        if (item.isSeparator) {
          return null;
        }

        const disabled = item.disabled;
        let handler = item.confirm ? showDestroyModal : item.handler;

        if (isDestroyModalVisible) {
          destroyModal = (
            <EuiConfirmModal
              title={item.label}
              onCancel={closeDestroyModal}
              onConfirm={(...callbackargs) => {
                item.handler(...callbackargs);
                closeDestroyModal();
              }}
              cancelButtonText={<FormattedMessage id={"actions.Cancel"} />}
              confirmButtonText={<FormattedMessage id={"actions.Delete"} />}
              buttonColor="danger"
              defaultFocusedButton="confirm"
            >
              {item.confirm}
            </EuiConfirmModal>
          );
        }

        const iconClassName = item.icon ? getIconClassName(item.icon) : item.iconClassName;
        const icon = <i className={iconClassName} />;

        let itemContent = (
          <>
            {icon}
            {item.label ? <>&nbsp;{item.label}</> : null}
          </>
        );
        if (item.href) {
          handler = (e) => {
            e.preventDefault();
            history.push(item.href);
          };
        }

        if (item.isSwitch) {
          return (
            <div style={switchStyle}>
              <EuiSwitch label={item.label} key={index} onChange={handler} checked={item.isChecked} />
            </div>
          );
        }

        return (
          <div key={index}>
            <EuiButton
              size={"s"}
              style={buttonStyle}
              key={index}
              href={item.href}
              onClick={handler}
              isDisabled={disabled}
              color={euiColorMap[item.bsStyle] || item.bsStyle}
            >
              {itemContent}
            </EuiButton>
            {item.confirm ? destroyModal : null}
            {item.extra}
          </div>
        );
      })}
    </div>
  );
};

export default ElasticUIPanelActions;
