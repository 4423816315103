// TODO Split this back into multiple components
// TODO setPos is very slow now.. find out why so we can use it too!

import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import moment from "app/utils/momentLocalized";
import Joi from "app/utils/CustomJoi";
import formWrapper from "app/common/forms/formWrapper";
import Field from "app/common/forms/Field";
import DateTimeDualField from "app/common/forms/DateTimeDualField";
import SelectField from "app/common/forms/SelectField";
import MultiButtonSelectField from "app/common/forms/MultiButtonSelectField";

import recurrenceOptions from "app/features/recurrences/constants";
import useToggleState from "app/common/hooks/useToggleState";

// TODO these could also live in constants file
const weekDayOptions = recurrenceOptions.weekDays.map((weekDay, weekDayNumber) => ({
  label: weekDay,
  value: weekDayNumber,
}));
const weekNumberOptions = recurrenceOptions.weekNumbers.map((weekNumber) => ({
  label: weekNumber,
  value: weekNumber,
}));

const monthOptions = recurrenceOptions.monthNames.map((monthName, monthNumber) => ({
  label: monthName,
  value: monthNumber + 1,
}));
const monthDayOptions = recurrenceOptions.monthDays.map((monthDay) => ({
  label: monthDay,
  value: monthDay,
}));

const RecurrenceRuleForm = ({ getFieldProps, values, defaultStart }) => {
  // const { value: showInterval, toggle: toggleShowInterval } = useToggleState(false);
  const initialShowWeeks = values.get("freq") === 0;
  const initialShowAdvancedFields =
    values.get("date_from") || values.get("date_until") || values.get("count") || values.get("bysetpos");

  const { value: showAdvancedFields, toggle: toggleAdvancedFields } = useToggleState(initialShowAdvancedFields);

  const [showWeeks, setShowWeeks] = useState(initialShowWeeks);
  const showSetPos = false;

  const isValidFromDate = useCallback(
    (d) => {
      const eventStart = moment(defaultStart);
      return moment(d).isSameOrAfter(eventStart, "day");
    },
    [defaultStart],
  );

  const isValidUntilDate = useCallback(
    (d) => {
      const eventStart = moment(defaultStart);
      return moment(d).isSameOrAfter(eventStart, "day");
    },
    [defaultStart],
  );

  const { formatMessage } = useIntl();
  const frequencyOptions = recurrenceOptions.frequencyChoices(formatMessage);

  const onFrequencyChange = useCallback((freq) => {
    setShowWeeks(freq === 0);
  }, []);

  return (
    <form>
      {/* Frequency selection */}
      <SelectField
        label={<FormattedMessage id="RecurrenceRuleForm.field.freq" defaultMessage="Frequency" />}
        help={
          <FormattedMessage
            id="RecurrenceRuleForm.field.freq.help"
            defaultMessage="How often this needs to recur. (required)"
          />
        }
        clearable={false}
        options={frequencyOptions}
        onFieldChange={onFrequencyChange}
        {...getFieldProps("freq")}
      />

      {/* Week days part */}
      <MultiButtonSelectField
        label={<FormattedMessage id="RecurrenceRuleForm.field.byweekday" defaultMessage="Only on certain weekdays" />}
        help={
          <FormattedMessage
            id="RecurrenceRuleForm.field.byweekday.help"
            defaultMessage="The days of the week on which this rule is valid. (optional)"
          />
        }
        options={weekDayOptions}
        nullWhenEmpty
        {...getFieldProps("byweekday")}
      />

      {/* Month names part */}
      <MultiButtonSelectField
        label={<FormattedMessage id="RecurrenceRuleForm.field.bymonth" defaultMessage="Only in certain months" />}
        help={
          <FormattedMessage
            id="RecurrenceRuleForm.field.bymonth.help"
            defaultMessage="The months in which this rule is valid. (optional)"
          />
        }
        options={monthOptions}
        nullWhenEmpty
        {...getFieldProps("bymonth")}
      />

      {/* Week numbers part */}
      {showWeeks && (
        <MultiButtonSelectField
          label={<FormattedMessage id="RecurrenceRuleForm.field.byweekno" defaultMessage="Only in certain weeks" />}
          help={
            <FormattedMessage
              id="RecurrenceRuleForm.field.byweekno.help"
              defaultMessage="The week numbers in which this rule is valid. (optional)"
            />
          }
          options={weekNumberOptions}
          nullWhenEmpty
          {...getFieldProps("byweekno")}
        />
      )}

      {/* Month days part */}
      <MultiButtonSelectField
        label={
          <FormattedMessage id="RecurrenceRuleForm.field.bymonthday" defaultMessage="Only on certain month days" />
        }
        help={
          <FormattedMessage
            id="RecurrenceRuleForm.field.bymonthday.help"
            defaultMessage="The days of the month on which this rule is valid. (optional)"
          />
        }
        options={monthDayOptions}
        nullWhenEmpty
        {...getFieldProps("bymonthday")}
      />

      <br />
      <br />
      <h5>
        Advanced <a onClick={toggleAdvancedFields}>(toggle)</a>
      </h5>
      <hr />
      {showAdvancedFields && (
        /* From/to date */
        <>
          <DateTimeDualField
            label={<FormattedMessage id="RecurrenceRuleForm.field.date_from" defaultMessage="Valid from" />}
            help={
              <FormattedMessage
                id="RecurrenceRuleForm.field.date_from.help"
                defaultMessage="First possible date of recurrence. (optional)"
              />
            }
            type="date"
            isValidDate={isValidFromDate}
            {...getFieldProps("date_from")}
          />
          <DateTimeDualField
            label={<FormattedMessage id="RecurrenceRuleForm.field.date_until" defaultMessage="Valid until" />}
            help={
              <FormattedMessage
                id="RecurrenceRuleForm.field.date_until.help"
                defaultMessage={`Last possible date of recurrence. (conflicts with "Number of occurrences") (optional)`}
              />
            }
            type="date"
            disabled={getFieldProps("count").value}
            isValidDate={isValidUntilDate}
            {...getFieldProps("date_until")}
          />

          {/* Interval */}
          <Field
            label={<FormattedMessage id="RecurrenceRuleForm.field.interval" defaultMessage="Interval" />}
            help={
              <FormattedMessage
                id="RecurrenceRuleForm.field.interval.help"
                defaultMessage={`The step size of the occurrences (1=every occurrence, 2=every other occurrence, etc.)`}
              />
            }
            {...getFieldProps("interval")}
          />

          {/* Count */}
          <Field
            label={<FormattedMessage id="RecurrenceRuleForm.field.count" defaultMessage="Number of occurrences" />}
            help={
              <FormattedMessage
                id="RecurrenceRuleForm.field.count.help"
                defaultMessage={`Number of times this needs to recur. (conflicts with "Valid until") (optional)`}
              />
            }
            {...getFieldProps("count")}
            disabled={getFieldProps("date_until").value}
          />

          {/* By set position */}
          {showSetPos && (
            <>
              <MultiButtonSelectField
                label={
                  <FormattedMessage
                    id="RecurrenceRuleForm.field.bysetpos"
                    defaultMessage="Only every (selected frequency)"
                  />
                }
                options={recurrenceOptions.setPositionChoices}
                nullWhenEmpty
                {...getFieldProps("bysetpos")}
              />
              <em>
                Examples:
                <ul>
                  <li>
                    Last work day of the month is obtained by choosing last here with a monthly frequency and selecting
                    Monday to Friday
                  </li>
                  <li>
                    Third Tuesday of September can be selected by setting 3 here, picking Tuesday and September and
                    using a yearly frequency
                  </li>
                </ul>
              </em>
            </>
          )}
        </>
      )}
    </form>
  );
};

RecurrenceRuleForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
};
RecurrenceRuleForm.schema = {
  freq: Joi.number().integer().min(0).max(3).required(),
  date_from: Joi.moment(),
  date_until: Joi.alternatives([Joi.any().equal("", null), Joi.moment().after(Joi.ref("date_from"))]),
  interval: Joi.number().integer().min(1).invalid(null),
  count: Joi.number().integer().positive().allow("", null),
  bysetpos: Joi.array().items(Joi.number()).allow(null),
  byweekday: Joi.array().items(Joi.number().min(0).max(6)).allow(null),
  bymonthday: Joi.array().items(Joi.number().min(1).max(31)).allow(null),
  byweekno: Joi.array().items(Joi.number().min(1).max(53)).allow(null),
  bymonth: Joi.array().items(Joi.number().min(1).max(12)).allow(null),
};

export default formWrapper(RecurrenceRuleForm);
