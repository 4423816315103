import { useCallback, useEffect, useMemo } from "react";

import { signInWithRedirect, getRedirectResult, OAuthProvider, SAMLAuthProvider } from "firebase/auth";

import { useAppActions } from "app/actions";
import { useAppTenantContext } from "app/core/providers/AppTenantDetectionProvider";
import BusyFeedbackPage from "app/common/components/BusyFeedbackPage";
import useToggleState from "app/common/hooks/useToggleState";

import {
  customFirebase,
  firebaseConfig,
  firebaseForceAuthDomain,
  firebaseInitialRedirectSeconds,
  firebaseUIDivId,
} from "app/features/auth/firebase";
import FirebaseAuthFlowRedirect from "app/features/auth/components/FirebaseAuthFlowRedirect";
import FirebaseAuthFlowWrongDomain from "app/features/auth/components/FirebaseAuthFlowWrongDomain";
import FirebaseUIDiv from "app/features/auth/components/FirebaseUIDiv";
import { useFirebaseAuthContext } from "app/features/auth/components/FirebaseAuthProvider";
import useCountdown from "app/common/hooks/useCountdown";

const useFirebaseAuthFlow = () => {
  const actions = useAppActions();
  const {
    tenantIdentityProvider,

    isReadyForFirebaseAuthFlow,
    isReadyForInitialReloadUserWithToken,

    firebaseUser,
  } = useFirebaseAuthContext();

  const { value: hasFirebaseAuthFlowInitialized, on: startHasFirebaseAuthFlowInitialized } = useToggleState();
  const { value: isWaitingForInitialReloadUser, off: stopWaitingForInitialReloadUser } = useToggleState(true);
  const { value: isReadyToRedirect, on: startIsReadyToRedirect, off: stopIsReadyToRedirect } = useToggleState();
  const {
    value: hasConfirmedRedirect,
    on: startHasConfirmedRedirect,
    off: stopHasConfirmedRedirect,
  } = useToggleState();

  const {
    countdownSeconds: redirectSeconds,
    startCountdown: startRedirectCountdown,
    stopCountdown: stopRedirectCountdown,
    isFinished: hasFinishedRedirectCountdown,
    isStopped: hasCancelledRedirectCountdown,
  } = useCountdown({ seconds: firebaseInitialRedirectSeconds });

  const currentDomain = window.location.host;
  const authDomain = firebaseConfig.authDomain;
  const authDomainUrl = window.location.href.replace(window.location.host, authDomain);
  const hasWrongDomain = firebaseForceAuthDomain && currentDomain !== authDomain;
  const hasCorrectDomain = !hasWrongDomain;

  const isWaitingForRedirectConfirm = isReadyToRedirect && !hasConfirmedRedirect;
  const isWaitingForAuthFlowInitialization = !isWaitingForRedirectConfirm && !hasFirebaseAuthFlowInitialized;

  const shouldRestartAuthFlow = !isWaitingForInitialReloadUser && isReadyForFirebaseAuthFlow;
  const shouldRedirectNow = isReadyToRedirect && (hasConfirmedRedirect || hasFinishedRedirectCountdown);
  const shouldStartRedirectCountdown = isReadyToRedirect && hasCorrectDomain;

  const restartAuthFlow = useCallback(() => {
    // TODO
    //  - do we want to set multiple auth objects per tenant?
    const firebaseAuth = customFirebase.getAuth();
    if (!firebaseAuth) return; // TODO raise?

    const { gcipId, samlProviderId, oidcProviderId } = tenantIdentityProvider;

    // Correctly set tenantId
    firebaseAuth.tenantId = gcipId;

    const shouldTriggerRedirectResult = samlProviderId || oidcProviderId;
    if (shouldTriggerRedirectResult) {
      // In case of SAML or OpenID Connect we trigger a redirect OR check an obtained result.
      getRedirectResult(firebaseAuth)
        .then((result) => {
          if (result) {
            // Login success! Firebase package updates user internally, which we listen to.
          } else {
            // Redirect the user to the SAML login page.
            startIsReadyToRedirect();
          }
        })
        .catch((error) => {
          // TODO
          //  An error occurred somehow, we should display the error and present a button to try the
          //  signInWithRedirect again.
          console.error(error);
        });
    } else {
      // Otherwise trigger the firebaseAuthUI for email+password login.
      const firebaseAuthUI = customFirebase.getAuthUI();
      if (!firebaseAuthUI) return; // TODO raise?

      firebaseAuthUI.reset();
      firebaseAuthUI.start(`#${firebaseUIDivId}`, customFirebase.configAuthUI);

      startHasFirebaseAuthFlowInitialized();
    }
  }, [tenantIdentityProvider, startHasFirebaseAuthFlowInitialized, startIsReadyToRedirect]);

  useEffect(() => {
    if (shouldStartRedirectCountdown) {
      startRedirectCountdown();
    }
  }, [shouldStartRedirectCountdown, startRedirectCountdown]);

  useEffect(() => {
    if (shouldRestartAuthFlow) {
      restartAuthFlow();
    }
  }, [shouldRestartAuthFlow, restartAuthFlow]);

  useEffect(() => {
    // Effect to automatically fetch user on updated firebaseDetails. This allows us to just show a BusyFeedback
    // instead of showing the LoginPanel.
    if (isReadyForInitialReloadUserWithToken) {
      if (firebaseUser) {
        // This is a trick because clearUser resets the redux store completely, so we
        // immediately add the firebaseUser again before getting the user again.
        actions.users.clearUser();
        actions.users.setFirebaseUser(firebaseUser);
        actions.users.getUser().payload.then(stopWaitingForInitialReloadUser);
      } else {
        // Just stop waiting now, we will trigger an appropriate AuthFlow.
        stopWaitingForInitialReloadUser();
      }
    }
  }, [actions, firebaseUser, isReadyForInitialReloadUserWithToken, stopWaitingForInitialReloadUser]);

  useEffect(() => {
    if (shouldRedirectNow) {
      stopHasConfirmedRedirect();
      stopIsReadyToRedirect();

      const firebaseAuth = customFirebase.getAuth();
      if (!firebaseAuth) return; // TODO raise?

      const { gcipId, samlProviderId, oidcProviderId } = tenantIdentityProvider;

      // Correctly set tenantId
      firebaseAuth.tenantId = gcipId;

      if (samlProviderId) {
        const provider = new SAMLAuthProvider(samlProviderId);
        signInWithRedirect(firebaseAuth, provider);
      } else if (oidcProviderId) {
        const provider = new OAuthProvider(oidcProviderId);
        signInWithRedirect(firebaseAuth, provider);
      } else {
        // TODO raise?
      }
    }
  }, [shouldRedirectNow, stopHasConfirmedRedirect, stopIsReadyToRedirect, tenantIdentityProvider]);

  return {
    redirectSeconds,
    onConfirmRedirect: startHasConfirmedRedirect,
    onCancelRedirect: stopRedirectCountdown,
    hasCancelledRedirect: hasCancelledRedirectCountdown,

    isWaitingForAuthFlowInitialization,
    isWaitingForInitialReloadUser,

    hasWrongDomain,
    authDomain,
    authDomainUrl,
    currentDomain,
  };
};

const FirebaseAuthFlowBusy = ({ tenantName }) => (
  <>
    <BusyFeedbackPage label={`Initializing tenant specific login for ${tenantName}...`} />
    <FirebaseUIDiv isHidden={true} />
  </>
);

const FirebaseAuthFlow = ({ redirectUrl }) => {
  const { tenantIdentityProvider } = useAppTenantContext();
  const { tenantName } = tenantIdentityProvider;
  const {
    redirectSeconds,
    onConfirmRedirect,
    onCancelRedirect,
    hasCancelledRedirect,
    isWaitingForAuthFlowInitialization,
    isWaitingForInitialReloadUser,

    hasWrongDomain,
    authDomain,
    authDomainUrl,
    currentDomain,
  } = useFirebaseAuthFlow();

  const encodedRedirectUrl = useMemo(() => encodeURIComponent(redirectUrl), [redirectUrl]);

  const showBusyFeedback = isWaitingForAuthFlowInitialization || isWaitingForInitialReloadUser;
  if (showBusyFeedback) {
    return <FirebaseAuthFlowBusy tenantName={tenantName} />;
  }

  if (hasWrongDomain) {
    return (
      <FirebaseAuthFlowWrongDomain
        tenantName={tenantName}
        redirectUrl={encodedRedirectUrl}
        correctDomainUrl={authDomainUrl}
        correctDomain={authDomain}
        wrongDomain={currentDomain}
      />
    );
  }

  return (
    <FirebaseAuthFlowRedirect
      tenantName={tenantName}
      redirectSeconds={redirectSeconds}
      redirectUrl={encodedRedirectUrl}
      hasCancelledRedirect={hasCancelledRedirect}
      onConfirmRedirect={onConfirmRedirect}
      onCancelRedirect={onCancelRedirect}
    />
  );
};

export default FirebaseAuthFlow;
