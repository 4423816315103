import { fromJS } from "immutable";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { WorkDetailedRecord } from "app/utils/records";
import moment from "app/utils/momentLocalized";
import { actionTypes } from "../actions";
import { emptyMap, isLoadingMap, notLoadedMap } from "app/utils/constants";

const defaultWorksFilter = {
  table: {
    work_state: "all_but_completed",
    end: { to: moment() },
  },
};

const initialState = fromJS({
  filter: defaultWorksFilter,
  detailed: {
    work: notLoadedMap,
    precedence: {},
  },
});

//
// Handler for LOAD_DETAILED_WORK_ASYNC action
//
function handleLoadDetailedWorkAsync(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.setIn(["detailed", "work"], isLoadingMap);
  } else if (action.status === "done") {
    const workJS = action.payload.body;
    const workRecord = WorkDetailedRecord(fromJS(workJS));
    state = state.setIn(["detailed", "work"], workRecord);
  }
  return state;
}

//
// Handler for CLEAR_DETAILED_WORK action
//
function handleClearDetailedWork(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state.setIn(["detailed", "work"], notLoadedMap);
  }

  return state;
}

//
// Handler for SET_WORK_FILTER action
//
function handleSetWorkFilter(prevState, action) {
  let state = prevState;
  const { filter } = action.payload;
  state = state.set("filter", filter);
  return state;
}

//
// Handler for LOAD_DETAILED_PRECEDENCE_ASYNC action
//
function handleLoadDetailedPrecedenceAsync(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    const precedenceJS = action.payload.body;
    const precedenceMap = fromJS(precedenceJS);
    state = state.setIn(["detailed", "precedence"], precedenceMap);
  }
  return state;
}

//
// Handler for CLEAR_DETAILED_PRECEDENCE action
//
function handleClearDetailedPrecedence(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.setIn(["detailed", "precedence"], emptyMap);
  }
  return state;
}

//
// Works reducer
//
function worksReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_DETAILED_WORK_ASYNC]: handleLoadDetailedWorkAsync,
    [actionTypes.CLEAR_DETAILED_WORK]: handleClearDetailedWork,
    [actionTypes.SET_WORK_FILTER]: handleSetWorkFilter,
    [actionTypes.LOAD_DETAILED_PRECEDENCE_ASYNC]: handleLoadDetailedPrecedenceAsync,
    [actionTypes.CLEAR_DETAILED_PRECEDENCE]: handleClearDetailedPrecedence,
  });
}

export default worksReducer;
