import combineImmutableReducers from "app/utils/redux/combineImmutableReducers";
import calendarsReducer from "./reducers/calendarsReducer";
import eventsReducer from "./reducers/eventsReducer";

const reducer = combineImmutableReducers({
  calendars: calendarsReducer,
  events: eventsReducer,
});

export default reducer;
