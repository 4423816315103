import { fromJS } from "immutable";
import { arrayToPkMap } from "app/utils/immutableUtils";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";

const initialState = fromJS({
  formulas: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
  metrics: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
});

function handleLoadSimpleFormulas(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.setIn(["formulas", "all"], isLoadingMap);
  }
  if (action.status === "done") {
    const formulasJS = action.payload.body.results;
    const formulasMap = arrayToPkMap(formulasJS, "formula");
    state = state.setIn(["formulas", "all"], formulasMap);
  }
  return state;
}

function handleClearSimpleFormulas(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.setIn(["formulas", "all"], notLoadedMap);
  }
  return state;
}

function handleLoadDetailedFormula(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.setIn(["formulas", "detailed"], isLoadingMap);
  }
  if (action.status === "done") {
    const formulaJS = action.payload.body;
    const formulaMap = fromJS(formulaJS);
    state = state.setIn(["formulas", "detailed"], formulaMap);
  }
  return state;
}

function handleClearDetailedFormula(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.setIn(["formulas", "detailed"], notLoadedMap);
  }
  return state;
}

function handleLoadSimpleMetrics(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.setIn(["metrics", "all"], isLoadingMap);
  }
  if (action.status === "done") {
    const metricsJS = action.payload.body.results;
    const metricsMap = arrayToPkMap(metricsJS, "metric");
    state = state.setIn(["metrics", "all"], metricsMap);
  }
  return state;
}

function handleClearSimpleMetrics(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.setIn(["metrics", "all"], notLoadedMap);
  }
  return state;
}

function handleLoadDetailedMetric(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.setIn(["metrics", "detailed"], isLoadingMap);
  }
  if (action.status === "done") {
    const metricJS = action.payload.body;
    const metricMap = fromJS(metricJS);
    state = state.setIn(["metrics", "detailed"], metricMap);
  }
  return state;
}

function handleClearDetailedMetric(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.setIn(["metrics", "detailed"], notLoadedMap);
  }
  return state;
}

function reducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_SIMPLE_FORMULAS]: handleLoadSimpleFormulas,
    [actionTypes.CLEAR_SIMPLE_FORMULAS]: handleClearSimpleFormulas,
    [actionTypes.LOAD_DETAILED_FORMULA]: handleLoadDetailedFormula,
    [actionTypes.CLEAR_DETAILED_FORMULA]: handleClearDetailedFormula,

    [actionTypes.LOAD_SIMPLE_METRICS]: handleLoadSimpleMetrics,
    [actionTypes.CLEAR_SIMPLE_METRICS]: handleClearSimpleMetrics,
    [actionTypes.LOAD_DETAILED_METRIC]: handleLoadDetailedMetric,
    [actionTypes.CLEAR_DETAILED_METRIC]: handleClearDetailedMetric,
  });
}

export default reducer;
