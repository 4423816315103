// TODO test these msg types against API available msg types
export const webSocketMsgTypes = {
  BGTASK_STATUS: "backgroundtask.status",

  CALCULATION_ARCHIVE: "calculation.archive",
  CALCULATION_HISTORY: "calculation.history",
  CALCULATION_PROGRESS: "calculation.progress",

  CAPACITY_ARCHIVE: "capacity.archive",

  CHAT_MESSAGE: "chat.message",

  PUBLISHED_ARCHIVE: "published.archive",

  SNAPSHOT_ARCHIVE: "snapshot.archive",
  SNAPSHOT_DETECTED_CHANGE: "snapshot.detected_change",
};
