import InternalDal from "app/utils/InternalDal";

class ReportsDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.formulasUrl = `${this.dal.baseTenantUrl}/report-formulas`;
    this.metricsUrl = `${this.dal.baseTenantUrl}/report-metrics`;
  }

  getSimpleFormulas() {
    const url = `${this.formulasUrl}/`;

    return this.dal.getter(url, "getSimpleFormulas");
  }

  getDetailedFormula(formulaPk) {
    const url = `${this.formulasUrl}/${formulaPk}/`;

    return this.dal.getter(url, "getDetailedFormula");
  }

  createFormula(formula) {
    const url = `${this.formulasUrl}/`;

    return this.dal.poster(url, formula, "createFormula");
  }

  putFormula(formula) {
    const url = `${this.formulasUrl}/${formula.pk}/`;

    return this.dal.putter(url, formula, "putFormula");
  }

  deleteFormula(formulaPk) {
    const url = `${this.formulasUrl}/${formulaPk}/`;

    return this.dal.deleter(url, "deleteFormula");
  }

  previewFormula(formula) {
    const url = `${this.dal.baseTenantUrl}/reports/preview-report-formula/`;
    const data = { formula };
    // const url = `${this.formulasUrl}/preview/`;

    return this.dal.poster(url, data, "previewFormula");
  }

  getSimpleMetrics() {
    const url = `${this.metricsUrl}/`;

    return this.dal.getter(url, "getSimpleMetrics");
  }

  getDetailedMetric(metricPk) {
    const url = `${this.metricsUrl}/${metricPk}/`;

    return this.dal.getter(url, "getDetailedMetric");
  }

  createMetric(metric) {
    const url = `${this.metricsUrl}/`;

    return this.dal.poster(url, metric, "createMetric");
  }

  putMetric(metric) {
    const url = `${this.metricsUrl}/${metric.pk}/`;

    return this.dal.putter(url, metric, "putMetric");
  }

  deleteMetric(metricPk) {
    const url = `${this.metricsUrl}/${metricPk}/`;

    return this.dal.deleter(url, "deleteMetric");
  }
}

export default ReportsDal;
