import { getEmptyPromise } from "app/utils/dal";
import StockDal from "app/features/stock/dal";

export const actionTypes = {
  LOAD_SIMPLE_STOCK_ITEMS: "stock/LOAD_SIMPLE_STOCK_ITEMS",
  CLEAR_SIMPLE_STOCK_ITEMS: "stock/CLEAR_SIMPLE_STOCK_ITEMS",
  LOAD_DETAILED_STOCK_ITEM: "stock/LOAD_DETAILED_STOCK_ITEM",
  CLEAR_DETAILED_STOCK_ITEM: "stock/CLEAR_DETAILED_STOCK_ITEM",
  CREATE_STOCK_ITEM: "stock/CREATE_STOCK_ITEM",
  UPDATE_STOCK_ITEM: "stock/UPDATE_STOCK_ITEM",
  UPDATE_STOCK_ITEM_RESOURCES: "stock/UPDATE_STOCK_ITEM_RESOURCES",
  DELETE_STOCK_ITEM: "stock/DELETE_STOCK_ITEM",

  LOAD_SIMPLE_STOCK_BREEDS: "stock/LOAD_SIMPLE_STOCK_BREEDS",
  CLEAR_SIMPLE_STOCK_BREEDS: "stock/CLEAR_SIMPLE_STOCK_BREEDS",
  LOAD_DETAILED_STOCK_BREED: "stock/LOAD_DETAILED_STOCK_BREED",
  CLEAR_DETAILED_STOCK_BREED: "stock/CLEAR_DETAILED_STOCK_BREED",
  CREATE_STOCK_BREED: "stock/CREATE_STOCK_BREED",
  UPDATE_STOCK_BREED: "stock/UPDATE_STOCK_BREED",
  DELETE_STOCK_BREED: "stock/DELETE_STOCK_BREED",

  CREATE_STOCK_ITEM_LEVEL: "stock/CREATE_STOCK_ITEM_LEVEL",
  UPDATE_STOCK_ITEM_LEVEL: "stock/UPDATE_STOCK_ITEM_LEVEL",
  DELETE_STOCK_ITEM_LEVEL: "stock/DELETE_STOCK_ITEM_LEVEL",

  LOAD_DETAILED_STOCK_REQUIREMENT: "stock/LOAD_DETAILED_STOCK_REQUIREMENT",
  CLEAR_DETAILED_STOCK_REQUIREMENT: "stock/CLEAR_DETAILED_STOCK_REQUIREMENT",
  CREATE_STOCK_REQUIREMENT: "stock/CREATE_STOCK_REQUIREMENT",
  UPDATE_STOCK_REQUIREMENT: "stock/UPDATE_STOCK_REQUIREMENT",
  DELETE_STOCK_REQUIREMENT: "stock/DELETE_STOCK_REQUIREMENT",
};

const actionCreators = {
  /* StockItems */
  loadSimpleStockItems() {
    const dal = new StockDal();
    const actionPromise = dal.getSimpleStockItems();
    return {
      type: actionTypes.LOAD_SIMPLE_STOCK_ITEMS,
      payload: actionPromise,
    };
  },

  clearSimpleStockItems() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_SIMPLE_STOCK_ITEMS,
      payload: actionPromise,
    };
  },

  loadDetailedStockItem(stockItemPk) {
    const dal = new StockDal();
    const actionPromise = dal.getDetailedStockItem(stockItemPk);
    return {
      type: actionTypes.LOAD_DETAILED_STOCK_ITEM,
      payload: actionPromise,
    };
  },

  clearDetailedStockItem() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_STOCK_ITEM,
      payload: actionPromise,
    };
  },

  createStockItem(stockItem) {
    const dal = new StockDal();
    const actionPromise = dal.createStockItem(stockItem);
    return {
      type: actionTypes.CREATE_STOCK_ITEM,
      payload: actionPromise,
    };
  },

  updateStockItem(stockItem) {
    const dal = new StockDal();
    const actionPromise = dal.putStockItem(stockItem);
    return {
      type: actionTypes.UPDATE_STOCK_ITEM,
      payload: actionPromise,
    };
  },

  updateStockItemResources(stockItemPk, resourcePks) {
    const dal = new StockDal();
    const actionPromise = dal.patchStockItemResources(stockItemPk, resourcePks);
    return {
      type: actionTypes.UPDATE_STOCK_ITEM_RESOURCES,
      payload: actionPromise,
    };
  },

  deleteStockItem(stockItemPk) {
    const dal = new StockDal();
    const actionPromise = dal.deleteStockItem(stockItemPk);
    return {
      type: actionTypes.DELETE_STOCK_ITEM,
      payload: actionPromise,
    };
  },

  /* StockItemLevels */
  createStockItemLevel(stockItemLevel) {
    const dal = new StockDal();
    const actionPromise = dal.createStockItemLevel(stockItemLevel);
    return {
      type: actionTypes.CREATE_STOCK_ITEM_LEVEL,
      payload: actionPromise,
    };
  },

  deleteStockItemLevel(stockItemLevelPk) {
    const dal = new StockDal();
    const actionPromise = dal.deleteStockItemLevel(stockItemLevelPk);
    return {
      type: actionTypes.DELETE_STOCK_ITEM_LEVEL,
      payload: actionPromise,
    };
  },

  /* StockRequirements */
  loadDetailedStockRequirement(stockRequirementPk) {
    const dal = new StockDal();
    const actionPromise = dal.getDetailedStockRequirement(stockRequirementPk);
    return {
      type: actionTypes.LOAD_DETAILED_STOCK_REQUIREMENT,
      payload: actionPromise,
    };
  },

  clearDetailedStockRequirement() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_STOCK_REQUIREMENT,
      payload: actionPromise,
    };
  },

  createStockRequirement(stockRequirement) {
    const dal = new StockDal();
    const actionPromise = dal.createStockRequirement(stockRequirement);
    return {
      type: actionTypes.CREATE_STOCK_REQUIREMENT,
      payload: actionPromise,
    };
  },

  updateStockRequirement(stockRequirement) {
    const dal = new StockDal();
    const actionPromise = dal.putStockRequirement(stockRequirement);
    return {
      type: actionTypes.UPDATE_STOCK_REQUIREMENT,
      payload: actionPromise,
    };
  },

  deleteStockRequirement(stockRequirementPk) {
    const dal = new StockDal();
    const actionPromise = dal.deleteStockRequirement(stockRequirementPk);
    return {
      type: actionTypes.DELETE_STOCK_REQUIREMENT,
      payload: actionPromise,
    };
  },

  /* StockBreeds */
  loadSimpleStockBreeds() {
    const dal = new StockDal();
    const actionPromise = dal.getSimpleStockBreeds();
    return {
      type: actionTypes.LOAD_SIMPLE_STOCK_BREEDS,
      payload: actionPromise,
    };
  },

  clearSimpleStockBreeds() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_SIMPLE_STOCK_BREEDS,
      payload: actionPromise,
    };
  },

  loadDetailedStockBreed(stockBreedPk) {
    const dal = new StockDal();
    const actionPromise = dal.getDetailedStockBreed(stockBreedPk);
    return {
      type: actionTypes.LOAD_DETAILED_STOCK_BREED,
      payload: actionPromise,
    };
  },

  clearDetailedStockBreed() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_STOCK_BREED,
      payload: actionPromise,
    };
  },

  createStockBreed(stockBreed) {
    const dal = new StockDal();
    const actionPromise = dal.createStockBreed(stockBreed);
    return {
      type: actionTypes.CREATE_STOCK_BREED,
      payload: actionPromise,
    };
  },

  updateStockBreed(stockBreed) {
    const dal = new StockDal();
    const actionPromise = dal.putStockBreed(stockBreed);
    return {
      type: actionTypes.UPDATE_STOCK_BREED,
      payload: actionPromise,
    };
  },

  deleteStockBreed(stockBreedPk) {
    const dal = new StockDal();
    const actionPromise = dal.deleteStockBreed(stockBreedPk);
    return {
      type: actionTypes.DELETE_STOCK_BREED,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
