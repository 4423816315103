// Initialise sentry for error reporting
import * as Sentry from "@sentry/browser";
import { sentryDSN } from "app/process";

export const initialiseSentryErrorReporting = () => {
  if (sentryDSN) {
    console.log(`[Sentry] Initialising ${sentryDSN}`);
    Sentry.init({ dsn: sentryDSN });
  } else {
    console.log("[Sentry] Can't initialise without DSN, please set up the environment properly.");
  }
};

export const openSentryReportDialog = () => Sentry.showReportDialog();

export default Sentry;
