import { createContext, useContext, useEffect, useMemo, useState } from "react";
import moment from "app/utils/momentLocalized";

const AppNowContext = createContext(moment());

const useNowMomentWithMinutesPrecision = () => {
  const initialNow = useMemo(() => moment(), []);
  const [now, setNow] = useState(initialNow);

  useEffect(() => {
    // Set timeout for next update
    const secondsUntilNextMinute = 60 - now.seconds();
    const msUntilNextMinute = 1000 * secondsUntilNextMinute;
    const timerId = setTimeout(() => {
      setNow(moment());
    }, msUntilNextMinute);
    return () => clearTimeout(timerId);
  }, [now]);

  return now;
};

export const useNowMomentWithSecondsPrecision = () => {
  const initialNow = useMemo(() => moment(), []);
  const [now, setNow] = useState(initialNow);

  useEffect(() => {
    // Set timeout for next update
    const timerId = setTimeout(() => {
      setNow(moment());
    }, 1000);
    return () => clearTimeout(timerId);
  }, [now]);

  return now;
};

const AppNowProvider = ({ children }) => {
  const nowMoment = useNowMomentWithMinutesPrecision();

  return <AppNowContext.Provider value={nowMoment}>{children}</AppNowContext.Provider>;
};

export const useAppNow = () => useContext(AppNowContext);

export default AppNowProvider;
