import { useCallback, useState } from "react";
import { getEmptyPromise, InternalPromise } from "app/utils/dal";
import { isAction } from "app/utils/redux/standardActions";

const joinFlattenPromises = (...promises) => {
  if (!Array.isArray(promises)) {
    throw Error(`Given promises are not an array ${promises}`);
  }

  const flattenedPromises = promises
    .flat(Infinity)
    .map((p) => (isAction(p) ? p.payload : p))
    .filter(Boolean);

  if (flattenedPromises.length === 0 || flattenedPromises.size === 0) {
    return getEmptyPromise();
  }

  flattenedPromises.forEach((p) => {
    if (InternalPromise.resolve(p) !== p) {
      throw Error(`Given object ${p} in ${flattenedPromises} is not a Promise`);
    }
  });

  return InternalPromise.join(...flattenedPromises);
};

export default (initialValue = 0) => {
  const [count, setCount] = useState(initialValue);
  const [isRejected, setIsRejected] = useState(false);

  const incrementLoading = useCallback((step = 1) => setCount((x) => x + step), []);
  const decrementLoading = useCallback((step = 1) => setCount((x) => x - step), []);

  const loadUntilResolvedOrRejected = useCallback(
    (...promises) => {
      if (promises.length === 0) {
        return getEmptyPromise();
      }

      const joinedPromise = joinFlattenPromises(promises);

      return new InternalPromise((resolve, reject) => {
        incrementLoading();
        return joinedPromise.then(resolve).catch(reject).finally(decrementLoading);
      });
    },
    [incrementLoading, decrementLoading],
  );

  const loadUntilRejected = useCallback(
    (...promises) => {
      if (promises.length === 0) {
        return getEmptyPromise();
      }

      const joinedPromise = joinFlattenPromises(promises);

      return new InternalPromise((resolve, reject) => {
        incrementLoading();
        return joinedPromise.then(resolve).catch((e) => {
          reject(e);
          decrementLoading();
          setIsRejected(true);
        });
      });
    },
    [incrementLoading, decrementLoading],
  );

  return {
    isLoading: count > 0,
    isRejected,
    incrementLoading,
    decrementLoading,
    loadUntilResolvedOrRejected,
    loadUntilRejected,
  };
};
