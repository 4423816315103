const busyFeedbackStyle = {
  height: "100%",
  padding: "2em",
  textAlign: "center",
  color: "#58666E",
};

export const Spinner = () => <i className="fa fa-spinner fa-spin" />;

const BusyFeedback = ({ hide, label, style = {} }) => {
  if (hide) return null;

  return (
    <div className="BusyFeedback" style={{ ...busyFeedbackStyle, ...style }}>
      <Spinner />
      {label ? <>&nbsp;{label}</> : null}
    </div>
  );
};

export default BusyFeedback;
