import { fromJS } from "immutable";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";
import { notLoadedMap } from "app/utils/constants";
import {
  keyArrToClearHandler,
  keyArrToLoadDetailedHandler,
  keyArrToLoadSimpleHandler,
} from "app/utils/redux/reducerHandlers";

const initialState = fromJS({
  storages: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
  breeds: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
});

const reducer = (state = initialState, action) => {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_SIMPLE_STORAGES]: keyArrToLoadSimpleHandler(["storages", "all"], "storage"),
    [actionTypes.CLEAR_SIMPLE_STORAGES]: keyArrToClearHandler(["storages", "all"]),
    [actionTypes.LOAD_DETAILED_STORAGE]: keyArrToLoadDetailedHandler(["storages", "detailed"]),
    [actionTypes.CLEAR_DETAILED_STORAGE]: keyArrToClearHandler(["storages", "detailed"]),

    [actionTypes.LOAD_SIMPLE_STORAGE_BREEDS]: keyArrToLoadSimpleHandler(["breeds", "all"], "storageBreed"),
    [actionTypes.CLEAR_SIMPLE_STORAGE_BREEDS]: keyArrToClearHandler(["breeds", "all"]),
    [actionTypes.LOAD_DETAILED_STORAGE_BREED]: keyArrToLoadDetailedHandler(["breeds", "detailed"]),
    [actionTypes.CLEAR_DETAILED_STORAGE_BREED]: keyArrToClearHandler(["breeds", "detailed"]),
  });
};

export default reducer;
