import DashboardDal from "./dal";

export const actionTypes = {
  LOAD_WORKS_REALISED: "dashboard/LOAD_WORKS_REALISED",
  LOAD_PROJECTS_REALISED: "dashboard/LOAD_PROJECTS_REALISED",
  LOAD_ASSIGNMENTS_REALISED: "dashboard/LOAD_ASSIGNMENTS_REALISED",
  LOAD_ASSIGNMENTS_NOT_REALISED: "dashboard/LOAD_ASSIGNMENTS_NOT_REALISED",
};

/***
 * PlanBoardPanel actions
 * @type {Object}
 */
const dashboardActionCreators = {
  loadWorksRealised() {
    const dal = new DashboardDal();
    const actionPromise = dal.getWorksRealised();
    return {
      type: actionTypes.LOAD_WORKS_REALISED,
      payload: actionPromise,
    };
  },

  loadProjectsRealised() {
    const dal = new DashboardDal();
    const actionPromise = dal.getProjectsRealised();
    return {
      type: actionTypes.LOAD_PROJECTS_REALISED,
      payload: actionPromise,
    };
  },

  loadAssignmentsRealised() {
    const dal = new DashboardDal();
    const actionPromise = dal.getAssignmentsRealised();
    return {
      type: actionTypes.LOAD_ASSIGNMENTS_REALISED,
      payload: actionPromise,
    };
  },

  loadAssignmentsNotRealised(now) {
    const dal = new DashboardDal();
    const actionPromise = dal.getAssignmentsNotRealised(now);
    return {
      type: actionTypes.LOAD_ASSIGNMENTS_NOT_REALISED,
      payload: actionPromise,
    };
  },
};

export default dashboardActionCreators;
