import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAppUserContext } from "app/core/providers/AppUserProvider";
import { getUserTenantSchemaName } from "app/features/users/selectors";

export const useDebugMode = () => useAppUserContext().debugMode;

export const useIsDebugAllowedForUser = () => useAppUserContext().userIsAllowedToDebug;

export const useIsDebugTenant = () => {
  const tenantSchemaName = useSelector(getUserTenantSchemaName);
  const pathName = useLocation().pathname;
  return pathName.startsWith("/debug") || tenantSchemaName === "pytest";
};

export const useLocale = () => useAppUserContext().locale;
