import combineImmutableReducers from "app/utils/redux/combineImmutableReducers";
import projectsReducer from "./reducers/projectsReducer";
import worksReducer from "./reducers/worksReducer";
import workResourcePreferencesReducer from "app/features/workResourcePreference/reducer";
import worksSameResourcesReducer from "./reducers/worksSameResourcesReducer";
import workImportancesReducer from "./reducers/workImportancesReducer";
import assignmentsReducer from "./reducers/assignmentsReducer";
import assignmentRulesReducer from "./reducers/assignmentRulesReducer";

// Params:
//    state: ImmutableJS record
const workReducer = combineImmutableReducers({
  projects: projectsReducer,
  works: worksReducer,
  workImportances: workImportancesReducer,
  workResourcePreferences: workResourcePreferencesReducer,
  worksSameResources: worksSameResourcesReducer,
  assignments: assignmentsReducer,
  assignmentRules: assignmentRulesReducer,
});

export default workReducer;
