import { firebaseUIDivId } from "app/features/auth/firebase";

const firebaseUIDivStyle = {
  boxShadow: "none",
  marginTop: 30,
  marginBottom: 30,
};
const hiddenStyle = { display: "none" };

const FirebaseUIDiv = ({ isHidden = false }) => (
  <div id={firebaseUIDivId} style={isHidden ? hiddenStyle : firebaseUIDivStyle} />
);

export default FirebaseUIDiv;
