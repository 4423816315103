import { Link } from "react-router-dom";
import { useTenantUrl } from "app/features/tenants/hooks";

const TenantUrlLink = ({ url, children, ...linkProps }) => (
  <Link {...linkProps} to={useTenantUrl(url)}>
    {children}
  </Link>
);

export default TenantUrlLink;
