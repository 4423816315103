import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { CustomIconWithLabel, Icons } from "app/utils/constants";

import { planningUrl } from "app/features/planBoard/routing";

import { manageAssignmentsUrl, manageProjectsUrl, manageWorksUrl } from "app/features/work/routing";

import { manageBlocksUrl } from "app/features/blocks/routing";
import { manageCalendarsUrl } from "app/features/calendars/routing";
import { manageLabelsUrl } from "app/features/labels/routing";
import { manageLocationsUrl } from "app/features/locations/routing";
import { manageResourcesUrl } from "app/features/resources/routing";
import { manageSkillsUrl } from "app/features/skills/routing";

import { manageReportsUrl } from "app/features/reports/routing";
import { manageChangeoversUrl } from "app/features/resourceStates/routing";
import { manageStockUrl } from "app/features/stock/routing";
import { manageStoragesUrl } from "app/features/storages/routing";

import { manageCapacityCalcsUrl } from "app/features/capacityBoard/routing";
import { manageCalculationsUrl } from "app/features/calculations/routing";
import { manageSnapshotsUrl } from "app/features/snapshots/routing";

import { useAppUserContext } from "app/core/providers/AppUserProvider";
import { useCurrentUserHasAllRoles } from "app/utils/routing";
import { useTenantUrl, useTenantUrlBuilder } from "app/features/tenants/hooks";

import { useIsDebugTenant } from "app/core/hooks";
import { latestPublishedPlanningUrl, publishedUrl } from "app/features/publish/routing";
import { plannerRoles } from "app/core/providers/AppTenantUserLinkProvider";

const debugTenantItems = [
  {
    title: "Debug",
    children: [
      {
        icon: Icons.calculation,
        title: "Overview",
        route: "/debug",
      },
    ],
  },
];

const useSpectatorMenuTenantItems = () => [
  {
    title: <FormattedMessage id="Sidebar.title.planning" />,
    children: [
      {
        icon: Icons.publishedPlanning,
        title: <FormattedMessage id="Sidebar.item.latestPublished" />,
        route: useTenantUrl(latestPublishedPlanningUrl),
      },
    ],
  },
];

const useMainMenuTenantItems = () => {
  const tenantUrlBuilder = useTenantUrlBuilder();
  return useMemo(
    () => [
      {
        title: <FormattedMessage id="Sidebar.title.planning" defaultMessage="PLANNING" />,
        children: [
          {
            icon: Icons.actualPlanning,
            title: <FormattedMessage id="Sidebar.item.actual" />,
            route: tenantUrlBuilder(planningUrl),
          },
          {
            icon: Icons.calculation,
            title: <FormattedMessage id="Sidebar.item.calculations" />,
            route: tenantUrlBuilder(manageCalculationsUrl),
          },
          {
            icon: Icons.snapshot,
            title: <FormattedMessage id="Sidebar.item.snapshots" />,
            route: tenantUrlBuilder(manageSnapshotsUrl),
          },
          {
            icon: Icons.publishedPlanning,
            title: <FormattedMessage id="Sidebar.item.publish" />,
            route: tenantUrlBuilder(publishedUrl),
          },
        ],
      },
      {
        title: <FormattedMessage id="Sidebar.title.work" defaultMessage="WORK" />,
        children: [
          {
            icon: Icons.manage,
            title: <FormattedMessage id="Sidebar.item.projects" defaultMessage="Projects" />,
            route: tenantUrlBuilder(manageProjectsUrl),
          },
          {
            icon: Icons.manage,
            title: <FormattedMessage id="Sidebar.item.works" defaultMessage="Works" />,
            route: tenantUrlBuilder(manageWorksUrl),
          },
          {
            icon: Icons.manage,
            title: <FormattedMessage id="Sidebar.item.assignments" defaultMessage="Assignments" />,
            route: tenantUrlBuilder(manageAssignmentsUrl),
          },
        ],
      },
      {
        title: <FormattedMessage id="Sidebar.title.manage" defaultMessage="MANAGE" />,
        children: [
          {
            icon: Icons.resources,
            title: <FormattedMessage id="Sidebar.item.resources" defaultMessage="Resources" />,
            route: tenantUrlBuilder(manageResourcesUrl),
          },
          {
            icon: Icons.resourceChangeovers,
            title: <FormattedMessage id="Sidebar.item.changeovers" defaultMessage="Changeovers" />,
            route: tenantUrlBuilder(manageChangeoversUrl),
          },
          {
            icon: Icons.block,
            title: <FormattedMessage id="Sidebar.item.blocks" defaultMessage="Blocks" />,
            route: tenantUrlBuilder(manageBlocksUrl),
          },
          {
            icon: Icons.skills,
            title: <FormattedMessage id="Sidebar.item.skills" defaultMessage="Skills" />,
            route: tenantUrlBuilder(manageSkillsUrl),
          },
          {
            icon: Icons.calendars,
            title: <FormattedMessage id="Sidebar.item.calendars" defaultMessage="Calendars" />,
            route: tenantUrlBuilder(manageCalendarsUrl),
          },
          {
            icon: Icons.location,
            title: <FormattedMessage id="Sidebar.item.locations" defaultMessage="Locations" />,
            route: tenantUrlBuilder(manageLocationsUrl),
          },
          {
            icon: Icons.label,
            title: <FormattedMessage id="Sidebar.item.labels" defaultMessage="Labels" />,
            route: tenantUrlBuilder(manageLabelsUrl),
          },
          {
            icon: Icons.stock,
            title: <FormattedMessage id="Sidebar.item.stock" defaultMessage="Stock" />,
            route: tenantUrlBuilder(manageStockUrl),
          },
          {
            icon: Icons.storage,
            title: <FormattedMessage id="Sidebar.item.storages" defaultMessage="Storages" />,
            route: tenantUrlBuilder(manageStoragesUrl),
          },
        ],
      },
      {
        title: <FormattedMessage id="Sidebar.title.other" defaultMessage="OTHER" />,
        children: [
          {
            icon: Icons.capacity,
            title: <FormattedMessage id="Sidebar.item.capacity" />,
            route: tenantUrlBuilder(manageCapacityCalcsUrl),
          },
          {
            icon: Icons.report,
            title: <FormattedMessage id="Sidebar.item.reporting" defaultMessage="Reporting" />,
            route: tenantUrlBuilder(manageReportsUrl),
          },
        ],
      },
    ],
    [tenantUrlBuilder],
  );
};

const constructSidebarMenu = (menu, index) => (
  <ul key={index} className="sidebar-panel nav">
    {[
      <li key={`${index}.title`} className="sidetitle">
        {menu.title}
      </li>,
      menu.children.map((child, childIndex) => (
        <li key={`${index}.${childIndex}`}>
          <NavLink exact to={child.route} activeClassName="active">
            <CustomIconWithLabel icon={child.icon} className="icon" label={child.title} />
          </NavLink>
        </li>
      )),
    ]}
  </ul>
);

const sidebarDivStyle = {
  minWidth: "200px",
  // borderRight: '1px solid #e5e5e5',
};

const AppSidebarMenu = ({ menuItems, hideWhenEmpty = true }) => {
  const isEmpty = !menuItems || menuItems.length === 0;
  if (hideWhenEmpty && isEmpty) return null;

  const menu = menuItems.map(constructSidebarMenu);

  return <div style={sidebarDivStyle}>{menu}</div>;
};

const manageRoles = plannerRoles;

const AppSidebar = () => {
  const isDebugTenant = useIsDebugTenant();
  const regularMenuItems = useMainMenuTenantItems();
  const spectatorMenuItems = useSpectatorMenuTenantItems();
  const showSidebar = useShowSidebar();
  const userHasManageRoles = useCurrentUserHasAllRoles(manageRoles);

  let menuItems;
  if (isDebugTenant) {
    menuItems = debugTenantItems;
  } else if (userHasManageRoles) {
    menuItems = regularMenuItems;
  } else {
    menuItems = spectatorMenuItems;
  }

  return showSidebar ? <AppSidebarMenu menuItems={menuItems} /> : null;
};

const useShowSidebar = () => {
  const { userLoaded, userHasTenant } = useAppUserContext();
  return userLoaded && userHasTenant;
};

export default AppSidebar;
