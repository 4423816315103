import { useMemo } from "react";
import { replaceDatetimesWithMomentsFormatted } from "app/utils/momentLocalized";
import useSafeToJS from "app/common/hooks/useSafeToJS";

export const objWithKeysSorted = (obj) => {
  // TODO
  //  - some checks whether this is actually an object and not an array for example
  return Object.keys(obj)
    .sort()
    .reduce((sortedObj, key) => {
      sortedObj[key] = obj[key];
      return sortedObj;
    }, {});
};

const JSONDisplay = ({ data, formatDatetimes = false, sortKeys = true }) => {
  const dataJS = useSafeToJS(data);
  const formattedData = useMemo(() => {
    if (!dataJS) return null;

    let result = dataJS;
    if (typeof result === "object") {
      // Easier to read for humans
      if (sortKeys && !Array.isArray(result)) {
        result = objWithKeysSorted(result);
      }
    }

    // To string so it fits the <pre> nicely
    result = JSON.stringify(result, null, 2);

    // Easier to read for humans
    if (formatDatetimes) {
      result = replaceDatetimesWithMomentsFormatted(result);
    }

    return result;
  }, [dataJS, formatDatetimes, sortKeys]);

  return <pre>{formattedData}</pre>;
};
export default JSONDisplay;
