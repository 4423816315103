import InternalDal from "app/utils/InternalDal";

class BlocksDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.blocksUrl = `${this.dal.baseTenantUrl}/blocks`;
  }

  getSimpleBlocks() {
    const url = `${this.blocksUrl}/`;
    return this.dal.getter(url, "getSimpleBlocks");
  }

  getDetailedBlock(blockPk) {
    const url = `${this.blocksUrl}/${blockPk}/`;
    return this.dal.getter(url, "getDetailedBlock");
  }

  createBlock(block) {
    const url = `${this.blocksUrl}/`;
    return this.dal.poster(url, block, "createBlock");
  }

  putBlock(block) {
    const url = `${this.blocksUrl}/${block.pk}/`;
    return this.dal.putter(url, block, "putBlock");
  }

  patchBlock(block) {
    const url = `${this.blocksUrl}/${block.pk}/`;
    return this.dal.patcher(url, block, "putBlock");
  }

  deleteBlock(blockPk) {
    const url = `${this.blocksUrl}/${blockPk}/`;
    return this.dal.deleter(url, "deleteBlock");
  }
}

export default BlocksDal;
