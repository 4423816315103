import InternalDal from "app/utils/InternalDal";

class TenantDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
  }

  getTenants() {
    const url = this.dal.makePublicUrl("tenants");
    return this.dal.getter(url, "getTenants");
  }

  switchTenant(schemaName) {
    const url = this.dal.makePublicUrl("tenants/switch");
    const data = { schemaName };

    return this.dal.poster(url, data, "switchTenant");
  }
}

export default TenantDal;
