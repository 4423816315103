import classNames from "classnames";
import Im from "immutable";
import PropTypes from "prop-types";
import { cloneElement, Component } from "react";

import ErrorList from "./ErrorList";
import Label from "./Label";
import HelpBlock from "./HelpBlock";
import { emptyList } from "app/utils/constants";

class Field extends Component {
  // Handlers
  // Change payload is an object like {fieldName:value}
  // so that a function that takes multiple changes can also be directly used
  handleChange = (e) => this.handleChangeValue(e.target.value);

  handleChangeValue = (value) => {
    const { onChange, onFieldChange, name } = this.props;

    if (onChange) {
      onChange({ [name]: value });
    }
    if (onFieldChange) {
      onFieldChange(value);
    }
  };

  render() {
    const {
      inputElement,
      labelElement,
      errorListElement,
      name,
      label,
      help,
      value,
      disabled,
      errors,
      classNames: _classNames,
    } = this.props;
    const inputName = `${name}_input`;

    // TODO when we want to use a different inputElement:
    // i) we should create a base Field component
    // ii) rename this to TextField component
    let inputElementToRender;
    if (inputElement) {
      const extraProps = {
        inputName,
        onChange: this.handleChange,
        disabled,
        value,
      };
      inputElementToRender = cloneElement(inputElement, extraProps);
    } else {
      inputElementToRender = (
        <input
          id={inputName}
          name={inputName}
          className="form-control"
          type="text"
          value={value !== null ? value : ""}
          disabled={disabled}
          onChange={this.handleChange}
        />
      );
    }

    let labelPart = null;
    if (label !== null) {
      const LabelElement = labelElement || Label;
      labelPart = <LabelElement inputName={inputName} label={label} />;
    }

    const ErrorListElement = errorListElement || ErrorList;
    const className = classNames(_classNames, { "has-error": errors.count() });

    return (
      <div className={className}>
        {labelPart}
        {inputElementToRender}
        <HelpBlock text={help} />
        <ErrorListElement errors={errors} />
      </div>
    );
  }
}

Field.propTypes = {
  classNames: PropTypes.array,
  errors: PropTypes.instanceOf(Im.List),
  label: PropTypes.node,
  name: PropTypes.string.isRequired,

  value: PropTypes.any,

  // overriding template parts
  labelElement: PropTypes.node,
  inputElement: PropTypes.node,
  errorListElement: PropTypes.node,

  // handlers
  onChange: PropTypes.func,
};
Field.defaultProps = {
  classNames: ["form-group"],
  errors: emptyList,
  value: "",
};

export default Field;
