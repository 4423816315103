import InternalDal from "app/utils/InternalDal";
import WorkDal from "app/features/work/dal";
import { dateFormat } from "app/utils/momentLocalized";
import { addDateQueryToUrl } from "app/utils/dal";

class DashboardDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.worksDal = new WorkDal(apiUrl);
    this.dashboardUrl = `${this.dal.baseTenantUrl}/dashboard`;
  }

  getDashboardInfo() {
    const url = `${this.dashboardUrl}/`;

    return this.dal.getter(url, "getDashboardInfo");
  }

  getWorksRealised() {
    const url = `${this.worksDal.worksUrl}/list/?work_state=realised`;

    return this.dal.getter(url, "getWorksRealised");
  }

  getProjectsRealised() {
    const url = `${this.worksDal.projectsUrl}/list/?project_state=realised`;

    return this.dal.getter(url, "getProjectsRealised");
  }

  getAssignmentsRealised() {
    const url = `${this.worksDal.assignmentsUrl}/list/?assignment_state=realised`;

    return this.dal.getter(url, "getAssignmentsRealised");
  }

  getAssignmentsNotRealised(now) {
    let url = `${this.worksDal.assignmentsUrl}/list/?assignment_state=planned`;
    if (now) url += `&end__gte=${now.format(dateFormat)}`;

    return this.dal.getter(url, "getAssignmentsNotRealised");
  }
}

export default DashboardDal;
