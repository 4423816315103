import { useCallback, useMemo, useRef } from "react";
import { isMapNotReady } from "app/utils/constants";

export const useFormRef = () => {
  const formRef = useRef();
  const submitForm = useCallback(() => {
    formRef.current.handleSubmit();
  }, []);
  return [formRef, submitForm];
};

export const useFormValues = (immutableMap) =>
  useMemo(() => {
    if (isMapNotReady(immutableMap)) return null;
    return immutableMap;
  }, [immutableMap]);

// TODO if schema does not exist we should get rawData!
export const useFormCallback = (callback) => {
  return useCallback(({ cleanedData }) => callback(cleanedData), [callback]);
};
