import { fromJS } from "immutable";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "app/features/workResourcePreference/actions";
import { notLoadedMap } from "app/utils/constants";
import { keyArrToClearHandler, keyArrToLoadDetailedHandler } from "app/utils/redux/reducerHandlers";

const initialState = fromJS({ detailed: notLoadedMap });

function reducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_DETAILED_WORK_RESOURCE_PREFERENCE]: keyArrToLoadDetailedHandler(["detailed"]),
    [actionTypes.CLEAR_DETAILED_WORK_RESOURCE_PREFERENCE]: keyArrToClearHandler(["detailed"]),
  });
}

export default reducer;
