import { FormattedMessage } from "react-intl";

export const PANEL_TYPE = {
  RESOURCES: "resources",
  WORK: "work",
};
export const panelOptions = [
  {
    value: "workResources",
    label: <FormattedMessage id="PlanBoardToolbar.panelOptions.workResources" />,
  },
  {
    value: "resourcesWork",
    label: <FormattedMessage id="PlanBoardToolbar.panelOptions.resourcesWork" />,
  },
  {
    value: "resources",
    label: <FormattedMessage id="PlanBoardToolbar.panelOptions.resources" />,
  },
  {
    value: "work",
    label: <FormattedMessage id="PlanBoardToolbar.panelOptions.work" />,
  },
];
export const panelOptionValues = panelOptions.map((o) => o.value);

export const intervalTypeOptions = [
  {
    value: "day",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.day" />,
  },
  {
    value: "week",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.week" />,
  },
  {
    value: "workweek",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.workweek" />,
  },
  {
    value: "month",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.month" />,
  },
  {
    value: "custom",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.custom" />,
  },
];

export const intervalTypeScopeOption = {
  value: "scope",
  label: "Scope",
};

export const intervalTypeValues = intervalTypeOptions.map((o) => o.value);

export default {};
