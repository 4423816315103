import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import localeData from "app/locales/index";
import { getUserProfileLocale } from "app/features/users/selectors";

const getNavigatorLanguage = () =>
  (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

export const AppIntlProvider = ({ children }) => {
  const userProfileLanguage = useSelector(getUserProfileLocale);

  // Define user's language. Different browsers have the user locale defined
  // on different fields on the `navigator` object, so we make sure to account
  // for these different by checking all of them
  const language = userProfileLanguage || getNavigatorLanguage();

  // Split locales with a region code
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

  // Try full locale, try locale without region code, fallback to 'en'
  const messages = localeData[language] || localeData[languageWithoutRegionCode] || localeData.en;

  return (
    <IntlProvider key={language} locale={language} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default AppIntlProvider;
