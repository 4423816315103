import { useAppUpdateContext } from "app/core/providers/AppUpdateProvider";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from "@elastic/eui";
import ExternalLink from "app/common/components/ExternalLink";
import { CustomIcon, Icons } from "app/utils/constants";

const handleRefreshClick = (e) => {
  e.preventDefault();
  window.location.reload();
};

const changelogUrl = "about/changelog";

const AppUpdateAvailableModal = () => {
  const { isUpdateAvailable, isUpdateDismissed, onDismissUpdate } = useAppUpdateContext();

  const modal = (
    <EuiOverlayMask>
      <EuiModal onClose={onDismissUpdate} style={{ width: 500 }}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h4>Update available</h4>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <div>
            <h5>Changes</h5>
            A refresh is necessary to apply the update in this browser tab.
            <br />
            Go to the <ExternalLink href={changelogUrl} label={"changelog"} /> to see the latest changes.
            <br />
            <br />
            <i>Dismiss to continue without refreshing, errors may occur.</i>
            <br />
            <br />
          </div>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={handleRefreshClick} fill>
            <CustomIcon icon={Icons.refresh} />
            &nbsp;Refresh to update
          </EuiButton>
          <EuiButtonEmpty onClick={onDismissUpdate}>Dismiss</EuiButtonEmpty>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  );

  // TODO
  //  - put in context provider and show feedback in navbar if dismissed!
  //  - add countdown before forced refresh
  const showUpdateAvailable = isUpdateAvailable && !isUpdateDismissed;
  return showUpdateAvailable ? modal : null;
};

export default AppUpdateAvailableModal;
