import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import useToggleState from "app/common/hooks/useToggleState";
import { CustomIcon } from "app/utils/constants";

const Tabs = ({ tabs }) => {
  const { pathname } = useLocation();
  const anyLessVisible = tabs.some(({ lessVisible }) => lessVisible);
  const { value: hideLessVisible, toggle: toggleHideLessVisible } = useToggleState(anyLessVisible);

  return (
    <ul className="nav nav-pills nav-line">
      {tabs.map(({ url, label, lessVisible }) => {
        const isActive = url === pathname;
        const isNotActiveAndLessVisible = !isActive && lessVisible;
        if (hideLessVisible && isNotActiveAndLessVisible) {
          return null;
        }

        return (
          <li role="presentation" key={url} className={classNames(isActive ? "active" : null)}>
            <Link to={url} style={isNotActiveAndLessVisible ? { color: "rgba(0, 0, 0, 0.25)" } : null}>
              {label}
            </Link>
          </li>
        );
      })}
      {anyLessVisible && (
        <li>
          <a onClick={toggleHideLessVisible}>
            {hideLessVisible ? <CustomIcon icon="angle-double-right" /> : <CustomIcon icon="angle-double-left" />}
          </a>
        </li>
      )}
    </ul>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      url: PropTypes.string,
    }),
  ),
};

export default Tabs;
