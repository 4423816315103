const ExternalLinkIcon = () => <i className="fa fa-external-link" aria-hidden="true" />;

const ExternalLinkLabelWithIcon = ({ label }) => {
  const icon = <ExternalLinkIcon />;
  return label ? (
    <>
      {label}&nbsp;{icon}
    </>
  ) : (
    icon
  );
};

const ExternalLink = ({ linkRef, children, href, label, ...otherProps }) => (
  <a ref={linkRef} rel="noopener noreferrer" target="_blank" href={href} {...otherProps}>
    {children ? children : <ExternalLinkLabelWithIcon label={label} />}
  </a>
);

export default ExternalLink;
