import { getEmptyPromise } from "app/utils/dal";
import ReportsDal from "app/features/reports/dal";

export const actionTypes = {
  CREATE_FORMULA: "reports/CREATE_FORMULA",
  UPDATE_FORMULA: "reports/UPDATE_FORMULA",
  DELETE_FORMULA: "reports/DELETE_FORMULA",
  PREVIEW_FORMULA: "reports/PREVIEW_FORMULA",
  LOAD_SIMPLE_FORMULAS: "reports/LOAD_SIMPLE_FORMULAS",
  CLEAR_SIMPLE_FORMULAS: "reports/CLEAR_SIMPLE_FORMULAS",
  LOAD_DETAILED_FORMULA: "reports/LOAD_DETAILED_FORMULA",
  CLEAR_DETAILED_FORMULA: "reports/CLEAR_DETAILED_FORMULA",

  CREATE_METRIC: "reports/CREATE_METRIC",
  UPDATE_METRIC: "reports/UPDATE_METRIC",
  DELETE_METRIC: "reports/DELETE_METRIC",
  LOAD_SIMPLE_METRICS: "reports/LOAD_SIMPLE_METRICS",
  CLEAR_SIMPLE_METRICS: "reports/CLEAR_SIMPLE_METRICS",
  LOAD_DETAILED_METRIC: "reports/LOAD_DETAILED_METRIC",
  CLEAR_DETAILED_METRIC: "reports/CLEAR_DETAILED_METRIC",
};

const actionCreators = {
  loadSimpleFormulas() {
    const dal = new ReportsDal();
    const actionPromise = dal.getSimpleFormulas();
    return {
      type: actionTypes.LOAD_SIMPLE_FORMULAS,
      payload: actionPromise,
    };
  },

  clearSimpleFormulas() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_SIMPLE_FORMULAS,
      payload: actionPromise,
    };
  },

  loadDetailedFormula(formulaPk) {
    const dal = new ReportsDal();
    const actionPromise = dal.getDetailedFormula(formulaPk);
    return {
      type: actionTypes.LOAD_DETAILED_FORMULA,
      payload: actionPromise,
    };
  },

  clearDetailedFormula() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_FORMULA,
      payload: actionPromise,
    };
  },

  createFormula(formula) {
    const dal = new ReportsDal();
    const actionPromise = dal.createFormula(formula);
    return {
      type: actionTypes.CREATE_FORMULA,
      payload: actionPromise,
    };
  },

  updateFormula(formula) {
    const dal = new ReportsDal();
    const actionPromise = dal.putFormula(formula);
    return {
      type: actionTypes.UPDATE_FORMULA,
      payload: actionPromise,
    };
  },

  deleteFormula(formulaPk) {
    const dal = new ReportsDal();
    const actionPromise = dal.deleteFormula(formulaPk);
    return {
      type: actionTypes.DELETE_FORMULA,
      payload: actionPromise,
    };
  },

  previewFormula(formula) {
    const dal = new ReportsDal();
    const actionPromise = dal.previewFormula(formula);
    return {
      type: actionTypes.PREVIEW_FORMULA,
      payload: actionPromise,
    };
  },

  loadSimpleMetrics() {
    const dal = new ReportsDal();
    const actionPromise = dal.getSimpleMetrics();
    return {
      type: actionTypes.LOAD_SIMPLE_METRICS,
      payload: actionPromise,
    };
  },

  clearSimpleMetrics() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_SIMPLE_METRICS,
      payload: actionPromise,
    };
  },

  loadDetailedMetric(metricPk) {
    const dal = new ReportsDal();
    const actionPromise = dal.getDetailedMetric(metricPk);
    return {
      type: actionTypes.LOAD_DETAILED_METRIC,
      payload: actionPromise,
    };
  },

  clearDetailedMetric() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_METRIC,
      payload: actionPromise,
    };
  },

  createMetric(metric) {
    const dal = new ReportsDal();
    const actionPromise = dal.createMetric(metric);
    return {
      type: actionTypes.CREATE_METRIC,
      payload: actionPromise,
    };
  },

  updateMetric(metric) {
    const dal = new ReportsDal();
    const actionPromise = dal.putMetric(metric);
    return {
      type: actionTypes.UPDATE_METRIC,
      payload: actionPromise,
    };
  },

  deleteMetric(metricPk) {
    const dal = new ReportsDal();
    const actionPromise = dal.deleteMetric(metricPk);
    return {
      type: actionTypes.DELETE_METRIC,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
