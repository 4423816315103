import log from "loglevel";

/***
 * Picks a handler for the given action and returns its result
 * @param state Previous state
 * @param action Dispatched action
 * @param handlers {{ string: function }} Mapping object, action type to handler function
 * @returns {*}
 */
function reduceWithHandlers(state, action, handlers) {
  const { type } = action;

  if (!type) {
    log.warn("Action does not have type.", action);
  }

  const handler = handlers[type];
  const newState = handler ? handler(state, action) : state;
  return newState;
}

export default reduceWithHandlers;
