import { fromJS } from "immutable";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";

import { actionTypes } from "../actions";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";

const initialState = fromJS({
  detailed: notLoadedMap,
  filter: {},
});

function handleLoadDetailedProjectAsync(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    const projectJS = action.payload.body;
    const projectMap = fromJS(projectJS);
    state = state.set("detailed", projectMap);
  }
  return state;
}

function handleClearDetailedProject(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("detailed", notLoadedMap);
  }
  return state;
}

//
// Project collection reducer
//
function projectsReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_DETAILED_PROJECT_ASYNC]: handleLoadDetailedProjectAsync,
    [actionTypes.CLEAR_DETAILED_PROJECT]: handleClearDetailedProject,
  });
}

export default projectsReducer;
