import InternalDal from "app/utils/InternalDal";

class LabelsDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.labelsUrl = `${this.dal.baseTenantUrl}/labels`;
  }

  getSimpleLabels() {
    const url = `${this.labelsUrl}/`;

    return this.dal.getter(url, "getSimpleLabels");
  }

  getDetailedLabel(labelPk) {
    const url = `${this.labelsUrl}/${labelPk}/`;

    return this.dal.getter(url, "getDetailedLabel");
  }

  createLabel(label) {
    const url = `${this.labelsUrl}/`;

    return this.dal.poster(url, label, "createLabel");
  }

  putLabel(label) {
    const url = `${this.labelsUrl}/${label.pk}/`;

    return this.dal.putter(url, label, "putLabel");
  }

  deleteLabel(labelPk) {
    const url = `${this.labelsUrl}/${labelPk}/`;

    return this.dal.deleter(url, "deleteLabel");
  }
}

export default LabelsDal;
