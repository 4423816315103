import UserDal from "./dal";

export const actionTypes = {
  LOAD_USER_PROFILE: "LOAD_USER_PROFILE",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",

  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  GET_USER: "GET_USER",
  CLEAR_USER: "CLEAR_USER",
  CLEAR_USER_NOT_AUTHORISED: "CLEAR_USER_NOT_AUTHORISED",

  SET_FIREBASE_USER: "SET_FIREBASE_USER",
  CLEAR_FIREBASE_USER: "CLEAR_FIREBASE_USER",
};

//
// User Profile Actions
//
const actionCreators = {
  loadUserProfile(userProfilePk) {
    const dal = new UserDal();
    const actionPromise = dal.getUserProfile(userProfilePk);
    return {
      type: actionTypes.LOAD_USER_PROFILE,
      payload: actionPromise,
    };
  },

  updateUserProfile(userProfile) {
    const dal = new UserDal();
    const actionPromise = dal.patchUserProfile(userProfile);
    return {
      type: actionTypes.UPDATE_USER_PROFILE,
      payload: actionPromise,
    };
  },

  clearUser() {
    return {
      type: actionTypes.CLEAR_USER,
      payload: null,
    };
  },

  clearUserNotAuthorised() {
    return {
      type: actionTypes.CLEAR_USER_NOT_AUTHORISED,
      payload: false,
    };
  },

  getUser(schemaName) {
    const dal = new UserDal();
    const actionPromise = dal.getUser(schemaName);

    return {
      type: actionTypes.GET_USER,
      payload: actionPromise,
    };
  },

  logIn(username, password) {
    const dal = new UserDal();
    const actionPromise = dal.logIn(username, password);
    return {
      type: actionTypes.LOGIN,
      payload: actionPromise,
    };
  },

  logOut() {
    const dal = new UserDal();
    const actionPromise = dal.logOut();

    return {
      type: actionTypes.LOGOUT,
      payload: actionPromise,
    };
  },

  setFirebaseUser(firebaseUser) {
    return {
      type: actionTypes.SET_FIREBASE_USER,
      payload: firebaseUser,
    };
  },

  clearFirebaseUser() {
    return {
      type: actionTypes.CLEAR_FIREBASE_USER,
      payload: null,
    };
  },
};

export default actionCreators;
