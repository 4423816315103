const defaultMessage = "Are you sure you want to delete this?";

export function confirm(callback, message = defaultMessage) {
  // eslint-disable-next-line no-alert
  const result = window.confirm(message);
  if (result) callback();
}

export function confirmHandler(callback, message = defaultMessage) {
  return (...callbackArgs) => {
    confirm(() => {
      callback(...callbackArgs);
    }, message);
  };
}

export default confirm;
