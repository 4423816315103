import { CustomIcon, Icons } from "app/utils/constants";

const ErrorCount = ({ nrErrors, countStyle = {} }) => (
  <>
    <span style={{ color: "red" }}>
      <CustomIcon icon={Icons.warning} />
    </span>
    &nbsp;
    <span style={countStyle}>{nrErrors}</span>
  </>
);

const WarningCount = ({ nrWarnings, countStyle = {} }) => (
  <>
    <span style={{ color: "orange" }}>
      <CustomIcon icon={Icons.warning} />
    </span>
    &nbsp;
    <span style={countStyle}>{nrWarnings}</span>
  </>
);

const ScopeErrorsWarningsCount = ({ errors, warnings, countStyle = {} }) => {
  const nrErrors = errors.size;
  const nrWarnings = warnings.size;

  const errorsPart = nrErrors > 0 ? <ErrorCount style={countStyle} nrErrors={nrErrors} /> : null;
  const warningsPart = nrWarnings > 0 ? <WarningCount style={countStyle} nrWarnings={nrWarnings} /> : null;
  const optionalSpacer = errorsPart && warningsPart ? <>,&nbsp;&nbsp;</> : null;

  return (
    <>
      {errorsPart}
      {optionalSpacer}
      {warningsPart}
    </>
  );
};

export default ScopeErrorsWarningsCount;
