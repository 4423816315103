import { getEmptyPromise } from "app/utils/dal";
import BlocksDal from "app/features/blocks/dal";

export const actionTypes = {
  LOAD_SIMPLE_BLOCKS: "blocks/LOAD_SIMPLE_BLOCKS",
  CLEAR_SIMPLE_BLOCKS: "blocks/CLEAR_SIMPLE_BLOCKS",
  LOAD_DETAILED_BLOCK: "blocks/LOAD_DETAILED_BLOCK",
  CLEAR_DETAILED_BLOCK: "blocks/CLEAR_DETAILED_BLOCK",
  CREATE_BLOCK: "blocks/CREATE_BLOCK",
  UPDATE_BLOCK: "blocks/UPDATE_BLOCK",
  DELETE_BLOCK: "blocks/DELETE_BLOCK",
};

const actionCreators = {
  /* Blocks */
  loadSimpleBlocks() {
    const dal = new BlocksDal();
    const actionPromise = dal.getSimpleBlocks();
    return {
      type: actionTypes.LOAD_SIMPLE_BLOCKS,
      payload: actionPromise,
    };
  },

  clearSimpleBlocks() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_SIMPLE_BLOCKS,
      payload: actionPromise,
    };
  },

  loadDetailedBlock(blockPk) {
    const dal = new BlocksDal();
    const actionPromise = dal.getDetailedBlock(blockPk);
    return {
      type: actionTypes.LOAD_DETAILED_BLOCK,
      payload: actionPromise,
    };
  },

  clearDetailedBlock() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_BLOCK,
      payload: actionPromise,
    };
  },

  createBlock(block) {
    const dal = new BlocksDal();
    const actionPromise = dal.createBlock(block);
    return {
      type: actionTypes.CREATE_BLOCK,
      payload: actionPromise,
    };
  },

  updateBlock(block) {
    const dal = new BlocksDal();
    const actionPromise = dal.putBlock(block);
    return {
      type: actionTypes.UPDATE_BLOCK,
      payload: actionPromise,
    };
  },

  deleteBlock(blockPk) {
    const dal = new BlocksDal();
    const actionPromise = dal.deleteBlock(blockPk);
    return {
      type: actionTypes.DELETE_BLOCK,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
