import { useMemo } from "react";
import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import ElasticUIPanel from "app/common/components/ElasticUIPanel";
import { confirmHandler } from "app/utils/confirm";
import { getIconClassName } from "app/utils/constants";
import history from "app/history";

export const euiColorMap = {
  success: "success",
  null: "primary",
};

const buttonFlexItemStyle = {
  marginTop: 30,
};

const headerFlexGroupStyle = {
  marginTop: -10,
  marginLeft: 20,
};

const panelSpacingStyle = {
  marginLeft: 20,
};
const footerSpacingStyle = {
  marginLeft: 20,
  marginTop: 10,
};

const useListSelectionActionItems = (actionItems, anySelected) =>
  useMemo(
    () =>
      actionItems
        ? actionItems.map((item) => {
            return {
              ...item,
              disabled: !anySelected,
            };
          })
        : [],
    [actionItems, anySelected],
  );

export const useListDefaultActionItems = (actionItems) =>
  useMemo(
    () =>
      actionItems
        ? [...actionItems]
            .reverse()
            .map((item) => {
              if (item.isSeparator) {
                return null;
              }

              let handler;
              if (item.href) {
                handler = (e) => {
                  e.preventDefault();
                  if (item.external) {
                    window.location.href = item.href;
                  } else {
                    history.push(item.href);
                  }
                };
              } else {
                handler = item.confirm ? confirmHandler(item.handler, item.confirm) : item.handler;
              }

              return {
                ...item,
                handler,
              };
            })
            .filter(Boolean)
        : [],
    [actionItems],
  );

export const ElasticUIActionItemButton = ({ actionItem }) => {
  const { euiIcon, icon, iconClassName, label, bsStyle, isActionBtn, href, handler, disabled } = actionItem;

  const iconOrIconClassName = icon ? getIconClassName(icon) : iconClassName;
  const itemContent = (
    <>
      <i className={iconOrIconClassName} />
      {euiIcon}
      {label ? <>&nbsp;{label}</> : null}
    </>
  );
  const isActionButton = bsStyle === "success" || bsStyle === "danger" || isActionBtn;
  const ButtonElement = !isActionButton ? EuiButtonEmpty : EuiButton;

  return (
    <ButtonElement
      size="s"
      href={href}
      onClick={handler}
      isDisabled={disabled}
      color={euiColorMap[bsStyle] || bsStyle}
      fill={isActionButton}
    >
      {itemContent}
    </ButtonElement>
  );
};

const ElasticUIListPage = ({
  style,
  defaultActionItems,
  selectedActionItems,
  anySelected,
  title,
  content,
  onEdit,
  onDelete,
  onSave,
  onBack,
  table,
  footer,
  formRef,
}) => {
  const mappedSelectionItems = useListSelectionActionItems(selectedActionItems, anySelected);
  const mappedDefaultActionItems = useListDefaultActionItems(defaultActionItems);

  // TODO this is dirty!
  const defaultActionItemButtons = mappedDefaultActionItems
    .map((actionItem, idx) => actionItem.component ?? <ElasticUIActionItemButton key={idx} actionItem={actionItem} />)
    .filter(Boolean);

  return (
    <div className="ElasticUIListPage">
      <header>
        <EuiFlexGroup gutterSize="s" style={headerFlexGroupStyle}>
          <EuiFlexItem grow={6}>
            <h1>{title}</h1>
          </EuiFlexItem>
          {defaultActionItemButtons.map((btn, idx) => (
            <EuiFlexItem key={idx} grow={1} style={buttonFlexItemStyle}>
              {btn}
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </header>
      <div style={panelSpacingStyle}>
        <ElasticUIPanel
          actionItems={mappedSelectionItems}
          formRef={formRef}
          onEdit={onEdit}
          onSave={onSave}
          onBack={onBack}
          onDelete={onDelete}
          style={style}
          table={table}
        >
          {content}
        </ElasticUIPanel>
      </div>
      <div style={footerSpacingStyle}>{footer}</div>
    </div>
  );
};

export default ElasticUIListPage;
