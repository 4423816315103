import ReactDOM from "react-dom";

import App from "app/core/components/App";
import store from "app/store";
import { initialiseBlueBirdPromise } from "app/core/bluebird";
import { initialiseSentryErrorReporting } from "app/core/sentry";
import { initialiseFirebaseAuth } from "app/features/auth/firebase";

//
// Renders app to given DOM element
//
function initialiseApp(elementId) {
  initialiseSentryErrorReporting();
  initialiseFirebaseAuth();
  initialiseBlueBirdPromise();

  // Get document element to render App in
  const element = document.getElementById(elementId);

  // Main app component
  const app = <App store={store} />;

  // Use ReactDOM to render the app to the given element
  const renderAppToElement = () => {
    ReactDOM.render(app, element);
  };

  // Actually render the App
  renderAppToElement();

  // Hot Module Replacement API
  if (module.hot) {
    module.hot.accept("app/core/components/App", renderAppToElement);
  }
}

export default initialiseApp;
