import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import appMessagesActionCreators from "./features/appMessages/actions";
import blocksActionCreators from "./features/blocks/actions";
import calendarsActionCreators from "./features/calendars/actions";
import dashboardActionCreators from "./features/dashboard/actions";
import labelsActionCreators from "./features/labels/actions";
import locationsActionCreators from "./features/locations/actions";
import planBoardActionCreators from "./features/planBoard/actions";
import planToolsActionCreators from "./features/planTools/actions";
import recurrenceActionCreators from "./features/recurrences/actions";
import reportsActionCreators from "./features/reports/actions";
import requirementsActionsCreators from "./features/requirements/actions";
import resourcesActionCreators from "./features/resources/actions";
import resourceStatesActionCreators from "./features/resourceStates/actions";
import snapshotsActionCreators from "./features/snapshots/actions";
import skillsActionCreators from "./features/skills/actions";
import stockActionCreators from "./features/stock/actions";
import storagesActionCreators from "./features/storages/actions";
import usersActionCreators from "./features/users/actions";
import workActionCreators from "./features/work/actions";
import { connect } from "react-redux";

export const appActions = {
  api: {
    request(promise, type = "UNSPECIFIED REQUEST") {
      return {
        payload: promise,
        type,
      };
    },
  },
  appMessages: appMessagesActionCreators,
  blocks: blocksActionCreators,
  calendars: calendarsActionCreators,
  dashboard: dashboardActionCreators,
  labels: labelsActionCreators,
  locations: locationsActionCreators,
  planBoard: planBoardActionCreators,
  planTools: planToolsActionCreators,
  recurrences: recurrenceActionCreators,
  reports: reportsActionCreators,
  requirements: requirementsActionsCreators,
  resources: resourcesActionCreators,
  resourceStates: resourceStatesActionCreators,
  snapshots: snapshotsActionCreators,
  skills: skillsActionCreators,
  stock: stockActionCreators,
  storages: storagesActionCreators,
  users: usersActionCreators,
  work: workActionCreators,
};

//
// Binds action creators to dispatch
//
// redux's bindActionCreators() only binds a single object's functions to dispatch.
// This function takes multiple collections of action creators and
// calls bindActionCreators() on each one.
//
const bindManyActionCreators = (actions, dispatch) => {
  const boundActionEntries = Object.entries(actions).map(([k, v]) => [k, bindActionCreators(v, dispatch)]);
  const boundActions = Object.fromEntries(boundActionEntries);
  return boundActions;
};

// Bind the multiple appActions at once
const bindAppActionCreators = (dispatch) => bindManyActionCreators(appActions, dispatch);

const appMapDispatchToProps = (dispatch) => ({
  actions: bindAppActionCreators(dispatch),
  // Still injecting a dispatch prop, to be able to dispatch thunks manually, without adding them
  // to the action creators modules.
  dispatch,
});

export const appConnect = (stateToProps) => connect(stateToProps, appMapDispatchToProps);

export const useAppActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindAppActionCreators(dispatch), [dispatch]);
};
