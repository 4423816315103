import Loadable from "react-loadable";
import { LogIn, LogOut } from "app/features/auth";
import { CustomSwitch } from "app/utils/routing";
import BusyFeedbackPage from "app/common/components/BusyFeedbackPage";
import TenantAuth from "app/features/tenants/components/TenantAuth";

// TODO does this lazy loading make sense here?
const LazyLoadingLoggedInUserSwitch = Loadable({
  loader: () => import("app/core/components/LoggedInUserSwitch"),
  loading: BusyFeedbackPage,
});

//
// Application content wrapper
//
const contentWrapperStyle = {
  height: "100%",
  width: "100%",
  borderLeft: "1px solid rgba(0, 0, 0, 0.05)",
  background: "#e4e4e4",
  overflowY: "auto",
};

export const AppContentWrapper = ({ children }) => (
  <div style={contentWrapperStyle}>
    <div className="content content-full">{children}</div>
  </div>
);

const appContentRoutes = [
  {
    path: "login",
    component: LogIn,
  },
  {
    path: "logout",
    component: LogOut,
  },
  {
    path: ":tenantId/login",
    component: TenantAuth,
  },
];
const appContentIndexRoute = { component: LazyLoadingLoggedInUserSwitch };

const AppContentSwitch = () => <CustomSwitch routes={appContentRoutes} indexRoute={appContentIndexRoute} />;

const AppContent = () => (
  <AppContentWrapper>
    <AppContentSwitch />
  </AppContentWrapper>
);

export default AppContent;
