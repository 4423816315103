import classNames from "classnames";
import { List } from "immutable";
import PropTypes from "prop-types";
import { Component } from "react";
import ReactSelect from "react-select";

import { emptyFunc, emptyList } from "app/utils/constants";

import ErrorList from "./ErrorList";
import Label from "./Label";
import HelpBlock from "./HelpBlock";
import { FormattedMessage } from "react-intl";

class SelectField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overrideChecked: props.overrideCheckbox !== null && props.value === null,
      value: props.value,
    };
  }

  handleChange = (value) => {
    const { onChange, onFieldChange, name } = this.props;

    this.setState({ value }, () => {
      if (onChange) onChange({ [name]: value });
      if (onFieldChange) onFieldChange(value);
    });
  };

  handleChangeSelect = (selection) => {
    const value = selection ? selection.value : null;
    this.handleChange(value);
  };

  handleChangeOverrideChecked = (e) => {
    const overrideChecked = e.target.checked;
    const value = overrideChecked ? null : this.state.value;
    this.setState({ overrideChecked }, () => this.handleChange(value));
  };

  // Render
  render() {
    const {
      name,
      label,
      help,
      options,
      errors,
      searchable,
      clearable,
      disabled,
      style,
      placeholder,
      overrideCheckbox,
      labelElement,
      errorListElement,
    } = this.props;
    const { value, overrideChecked } = this.state;

    const inputName = `${name}_input`;
    const reactSelectElement = overrideChecked ? null : (
      <ReactSelect
        isSearchable={searchable}
        isClearable={clearable}
        isDisabled={disabled || overrideChecked}
        key={inputName}
        name={inputName}
        value={options?.find((o) => o.value === value)}
        options={options}
        placeholder={placeholder || <FormattedMessage id="ReactSelect.placeholder" />}
        onChange={this.handleChangeSelect}
      />
    );

    const LabelElement = labelElement || Label;
    const ErrorListElement = errorListElement || ErrorList;

    let checkboxPart = null;
    if (overrideCheckbox !== null) {
      const overrideCheckboxName = `${inputName}_override`;
      checkboxPart = (
        <div className="checkbox">
          <input
            type="checkbox"
            id={overrideCheckboxName}
            name={overrideCheckboxName}
            checked={overrideChecked}
            onChange={this.handleChangeOverrideChecked}
          />
          <LabelElement inputName={overrideCheckboxName} label={overrideCheckbox} />
        </div>
      );
    }

    const labelPart = label !== null ? <LabelElement inputName={inputName} label={label} /> : null;

    return (
      <div className={classNames("form-group", { "has-error": errors.count() })} style={style}>
        {labelPart}
        {checkboxPart}
        {reactSelectElement}
        <HelpBlock text={help} />
        <ErrorListElement errors={errors} />
      </div>
    );
  }
}

SelectField.propTypes = {
  errors: PropTypes.instanceOf(List),
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.any,
  disabled: PropTypes.bool,

  onChange: PropTypes.func,
  onFieldChange: PropTypes.func,

  overrideCheckbox: PropTypes.node,
};

SelectField.defaultProps = {
  errors: emptyList,
  value: null,
  disabled: false,
  onFieldChange: emptyFunc,
  label: null,

  overrideCheckbox: null,
};

export default SelectField;
