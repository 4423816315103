import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useAppActions } from "app/actions";
import {
  getIsLoadingLastCompletedSnapshot,
  getLastCompletedSnapshotPk,
  getLastCompletedSnapshotScopePk,
  getLastCompletedSnapshotScopeStart,
  getNoSnapshotFound,
} from "app/features/snapshots/selectors";
import { useNowMomentWithSecondsPrecision } from "app/core/providers/AppNowProvider";

import useToggleState from "app/common/hooks/useToggleState";
import { useHandleLastReceivedMessage } from "app/features/webSockets/WebSocketProvider";
import { useSnapshotScopeContext } from "app/features/snapshots/components/SnapshotScopeProvider";
import { webSocketMsgTypes } from "app/features/webSockets/constants";

const shouldConfirmLoad = false;

const SnapshotContext = createContext({});

const usePollLastCompletedSnapshotForScope = (scopePk) => {
  const actions = useAppActions();
  const [lastSnapshotPk, setLastSnapshotPk] = useState(null);

  const pollLastCompletedSnapshot = useCallback(() => {
    actions.snapshots.pollLastCompletedSnapshot(scopePk).payload.then((response) => {
      const newLastSnapshotPk = response.body.pk;
      setLastSnapshotPk(newLastSnapshotPk);
    });
  }, [actions, scopePk]);

  return [lastSnapshotPk, pollLastCompletedSnapshot];
};

// TODO include scope here too!
const pollSnapshotMessageFilter = ({ content: msgContent, type: msgType }) =>
  (msgType === webSocketMsgTypes.BGTASK_STATUS &&
    msgContent?.type === "snapshot" &&
    msgContent?.status === "completed") ||
  msgType === webSocketMsgTypes.SNAPSHOT_DETECTED_CHANGE;

const useSnapshotPollingWithSocketMessage = ({
  now,
  activeScopePk,
  activeScopeInitialized,
  noSnapshotFoundForScope,
}) => {
  const [lastSnapshotPk, pollLastSnapshot] = usePollLastCompletedSnapshotForScope(activeScopePk);

  const lastScopePk = useSelector(getLastCompletedSnapshotScopePk);
  const lastScopeStart = useSelector(getLastCompletedSnapshotScopeStart);

  const shouldPoll =
    activeScopeInitialized &&
    !noSnapshotFoundForScope &&
    (lastScopePk !== activeScopePk || now.isSameOrAfter(lastScopeStart));

  // NOTE we don't poll on connect because the useEffect for "shouldPoll" already handles that case.
  useHandleLastReceivedMessage({
    onReceivedMessage: pollLastSnapshot,
    onReconnect: pollLastSnapshot,
    messageFilter: pollSnapshotMessageFilter,
    isDisabled: !activeScopeInitialized,
  });

  useEffect(() => {
    if (shouldPoll) {
      pollLastSnapshot();
    }
  }, [pollLastSnapshot, shouldPoll]);

  return lastSnapshotPk;
};

const SnapshotProvider = ({ children }) => {
  const actions = useAppActions();

  const snapshotPk = useSelector(getLastCompletedSnapshotPk);
  const { activeScopePk, activeScopeInitialized } = useSnapshotScopeContext();

  const isSnapshotLoading = useSelector(getIsLoadingLastCompletedSnapshot);
  const now = useNowMomentWithSecondsPrecision();

  const lastLoadedScopeRef = useRef(-1);
  const noSnapshotFound = useSelector(getNoSnapshotFound);
  const noSnapshotFoundForScope = lastLoadedScopeRef.current === activeScopePk && noSnapshotFound;

  const lastSnapshotPk = useSnapshotPollingWithSocketMessage({
    now,
    activeScopePk,
    activeScopeInitialized,
    noSnapshotFoundForScope,
  });

  const {
    value: userConfirmedLoadLastSnapshot,
    on: confirmLoadLastSnapshot,
    off: resetConfirmedLoadLastSnapshot,
  } = useToggleState(false);

  const isNotLast = !isSnapshotLoading && snapshotPk && lastSnapshotPk && lastSnapshotPk !== snapshotPk;
  const confirmed = !shouldConfirmLoad || userConfirmedLoadLastSnapshot;

  const shouldLoadLast =
    (!snapshotPk || (isNotLast && confirmed)) &&
    activeScopeInitialized &&
    !isSnapshotLoading &&
    !noSnapshotFoundForScope;

  const loadLastCompletedSnapshot = useCallback(() => {
    // TODO
    //  - this also loads the whole planningDto, do we need that at this point?
    //  - handle errors and missing snapshots properly!
    lastLoadedScopeRef.current = activeScopePk;
    actions.snapshots
      .loadLastCompletedSnapshot(activeScopePk)
      .payload.then(resetConfirmedLoadLastSnapshot)
      .catch(() => {});
  }, [actions, resetConfirmedLoadLastSnapshot, activeScopePk]);

  useEffect(() => {
    if (shouldLoadLast) {
      loadLastCompletedSnapshot();
    }
  }, [loadLastCompletedSnapshot, shouldLoadLast]);

  const contextValue = {
    confirmLoadLastSnapshot,
    loadLastCompletedSnapshot,
    isSnapshotNotLast: isNotLast,
  };

  return <SnapshotContext.Provider value={contextValue}>{children}</SnapshotContext.Provider>;
};

export const useSnapshotContext = () => useContext(SnapshotContext);

export default SnapshotProvider;
