import InternalDal from "app/utils/InternalDal";

class RequirementsDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.requirementsUrl = `${this.dal.baseTenantUrl}/skills-requirements`;
  }

  getSimpleRequirements(workPks) {
    let workPksJS = workPks.toJS ? workPks.toJS() : workPks;
    workPksJS = workPksJS.filter(Boolean);
    if (workPksJS.length === 0) {
      throw Error("Provide at least 1 work to fetch skills requirements for.");
    }

    const queryString = "?" + workPks.map((workPk) => `work=${workPk}`).join("&");
    const url = `${this.requirementsUrl}/${queryString}`;

    return this.dal.getter(url, "getRequirements");
  }

  getDetailedRequirement(requirementPk) {
    // TODO add detailed here
    const url = `${this.requirementsUrl}/${requirementPk}/`;

    return this.dal.getter(url, "getDetailedRequirement");
  }

  createRequirement(requirement) {
    const url = `${this.requirementsUrl}/`;

    return this.dal.poster(url, requirement, "createRequirement");
  }

  putRequirement(requirement) {
    const url = `${this.requirementsUrl}/${requirement.pk}/`;

    return this.dal.putter(url, requirement, "putRequirement");
  }

  deleteRequirement(requirementPk) {
    const url = `${this.requirementsUrl}/${requirementPk}/`;

    return this.dal.deleter(url, "deleteRequirement");
  }
}

export default RequirementsDal;
