import { Link } from "react-router-dom";
import { EuiButton, EuiButtonEmpty } from "@elastic/eui";

import FirebaseAuthFlowDebug from "app/features/auth/components/FirebaseAuthFlowDebug";
import { LoginPanel } from "app/features/auth/components/Login";
import FirebaseUIDiv from "app/features/auth/components/FirebaseUIDiv";

const debugFirebaseAuthFlow = false;

const FirebaseAuthFlowRedirect = ({
  tenantName,
  redirectSeconds,
  redirectUrl,
  hasCancelledRedirect,
  onConfirmRedirect,
  onCancelRedirect,
}) => {
  // TODO this debug should get a better place probably

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {debugFirebaseAuthFlow && (
        <div style={{ width: 250 }}>
          <FirebaseAuthFlowDebug />
        </div>
      )}
      <div
        style={{
          paddingRight: debugFirebaseAuthFlow ? 250 : 0,
          margin: "auto",
        }}
      >
        <LoginPanel
          content={
            <div style={{ width: "100%" }}>
              <FirebaseUIDiv />
              <div
                style={{
                  paddingTop: 40,
                  textAlign: "center",
                }}
              >
                {hasCancelledRedirect ? (
                  <>Cancelled automatic redirect tenant specific login.</>
                ) : (
                  <>
                    Redirecting to tenant specific login
                    <br />
                    <h5>{redirectSeconds}</h5>
                  </>
                )}
              </div>

              <div
                style={{
                  paddingTop: 40,
                  textAlign: "center",
                }}
              >
                <EuiButton fill size="m" onClick={onConfirmRedirect}>
                  Redirect now
                </EuiButton>
                {!hasCancelledRedirect && (
                  <>
                    &nbsp;&nbsp;
                    <EuiButtonEmpty size="m" onClick={onCancelRedirect}>
                      Cancel
                    </EuiButtonEmpty>
                  </>
                )}
              </div>
            </div>
          }
          footer={
            <div>
              This is the specific login flow for tenant <b>{tenantName}</b>.<br />
              Log in with regular YouPlan &nbsp;
              <Link to={`/login?redirectUrl=${redirectUrl}`}>username and password</Link>&nbsp; instead.
            </div>
          }
        />
      </div>
    </div>
  );
};

export default FirebaseAuthFlowRedirect;
