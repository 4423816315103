import LocationsDal from "./dal";
import { getEmptyPromise } from "app/utils/dal";

export const actionTypes = {
  LOAD_SIMPLE_LOCATIONS: "locations/LOAD_SIMPLE_LOCATIONS",
  LOAD_DETAILED_LOCATION: "locations/LOAD_DETAILED_LOCATION",
  CLEAR_SIMPLE_LOCATIONS: "locations/CLEAR_SIMPLE_LOCATIONS",
  CLEAR_DETAILED_LOCATION: "locations/CLEAR_DETAILED_LOCATION",
  CREATE_LOCATION: "locations/CREATE_LOCATION",
  UPDATE_LOCATION: "locations/UPDATE_LOCATION",
  DELETE_LOCATION: "locations/DELETE_LOCATION",

  LOAD_TRAVEL_TIMES: "locations/LOAD_TRAVEL_TIMES",
  LOAD_MISSING_TRAVEL_TIMES: "locations/LOAD_MISSING_TRAVEL_TIMES",
  CLEAR_TRAVEL_TIMES: "locations/CLEAR_LOCATIONS_WITH_TRAVEL_TIMES",
  CLEAR_MISSING_TRAVEL_TIMES: "locations/CLEAR_MISSING_TRAVEL_TIMES",
  CALCULATE_MISSING_TRAVEL_TIMES: "locations/CALCULATE_MISSING_TRAVEL_TIMES",
};

/***
 * PlanBoardPanel actions
 * @type {Object}
 */
const actionCreators = {
  loadSimpleLocations() {
    const dal = new LocationsDal();
    const actionPromise = dal.loadSimpleLocations();
    return {
      type: actionTypes.LOAD_SIMPLE_LOCATIONS,
      payload: actionPromise,
    };
  },

  clearSimpleLocations() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_SIMPLE_LOCATIONS,
      payload: actionPromise,
    };
  },

  loadDetailedLocation(locationPk) {
    const dal = new LocationsDal();
    const actionPromise = dal.loadDetailedLocation(locationPk);
    return {
      type: actionTypes.LOAD_DETAILED_LOCATION,
      payload: actionPromise,
    };
  },

  clearDetailedLocation() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_LOCATION,
      payload: actionPromise,
    };
  },

  createLocation(location) {
    const dal = new LocationsDal();
    const actionPromise = dal.createLocation(location);
    return {
      type: actionTypes.CREATE_LOCATION,
      payload: actionPromise,
    };
  },

  updateLocation(location) {
    const dal = new LocationsDal();
    const actionPromise = dal.updateLocation(location);
    return {
      type: actionTypes.UPDATE_LOCATION,
      payload: actionPromise,
    };
  },

  deleteLocation(locationPk) {
    const dal = new LocationsDal();
    const actionPromise = dal.deleteLocation(locationPk);
    return {
      type: actionTypes.DELETE_LOCATION,
      payload: actionPromise,
    };
  },

  loadTravelTimes(locationFromPks, locationToPks) {
    const dal = new LocationsDal();
    const actionPromise = dal.loadTravelTimes(locationFromPks, locationToPks);

    return {
      type: actionTypes.LOAD_TRAVEL_TIMES,
      payload: actionPromise,
    };
  },

  loadMissingTravelTimes() {
    const dal = new LocationsDal();
    const actionPromise = dal.loadMissingTravelTimes();

    return {
      type: actionTypes.LOAD_MISSING_TRAVEL_TIMES,
      payload: actionPromise,
    };
  },

  clearMissingTravelTimes() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_MISSING_TRAVEL_TIMES,
      payload: actionPromise,
    };
  },

  clearTravelTimes() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_TRAVEL_TIMES,
      payload: actionPromise,
    };
  },

  calculateMissingTravelTimes() {
    const dal = new LocationsDal();
    const actionPromise = dal.calculateMissingTravelTimes();

    return {
      type: actionTypes.CALCULATE_MISSING_TRAVEL_TIMES,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
