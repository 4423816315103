import BlueBirdPromise from "bluebird";
import { isProduction } from "app/process";

export const initialiseBlueBirdPromise = () => {
  if (isProduction) {
    BlueBirdPromise.config({
      longStackTraces: false,
      warnings: false,
    });
  }
};
